import {
  View,
  Text,
  Pressable,
  Image,
  Modal,
  TouchableWithoutFeedback,
  ScrollView,
} from "react-native";
import React from "react";
import moment from "moment";
import { Font } from "../font";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import CreateGroupPostModal from "../../screens/customer/create-post-modal";
import CommentSection from "../commentSection";
import SVGLoader from "../../../utils/SVGLoader";
import horizontalMenu from "../../../assets/horizontal-menu.svg";
import ConfirmModal from "../ConfirmModal";
import { deleteAPost } from "../../services/api";
import * as Linking from "expo-linking";

const GroupPost = ({
  navigation,
  postTitle,
  groupName,
  postIconURL,
  postDescription,
  postTimestamp,
  postCoverImageURL,
  externalLinkUrl,
  postLikes,
  postComments,
  postShares,
  isPostLiked,
  postId,
  groupId,
  attachedFileURL,
  disableGroupPostComment = false,
  disableGroupPostShare = false,
  allowPostEdit = false,
  onSharePost = () => null,
  onCloseEditPostModal = () => null,
  onLikeGroupPost = () => null,
  onEditPost = () => null,
  onDeletePost = () => null,
} = {}) => {
  const [isCommentSectionVisible, setIsCommentSectionVisible] =
    React.useState();
  const [isEditPostModal, setIsEditPostModal] = React.useState();
  const [isMenuVisible, setIsMenuVisible] = React.useState();
  const [isDeleteModal, setIsDeleteModal] = React.useState();

  const openMenu = () => {
    // if (buttonRef.current) {
    //   buttonRef.current.measure((x, y, width, height, pageX, pageY) => {
    //     setButtonPosition({ left: pageX - 100, top: pageY + height - 30 });
    //   });
    // }
    setIsMenuVisible(true);
  };
  const closeMenu = () => {
    setIsMenuVisible(false);
  };
  const closeDeleteModal = () => {
    setIsDeleteModal(false);
  };

  return (
    <View className="p-2 w-full flex justify-center items-center bg-app-a border-y border-y-gray-300">
      <View className="w-full flex-row justify-between items-center">
        <View className="flex-row justify-start items-center py-1">
          <View className="mr-2">
            <Image
              source={
                postIconURL
                  ? {
                      uri:
                        "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                        postIconURL,
                    }
                  : require("../../../assets/user.jpg")
              }
              className="w-10 h-10 rounded-full"
            />
          </View>
          <View className="mt-2 flex justify-start item-center">
            <Font className="text-md text-app-d font-bold">
              {postTitle || groupName}
            </Font>
            <View className="flex-row justify-start items-center">
              {/* <Font className="text-xs text-gray-500">
                              Owner
                            </Font> */}
              {/* <Font className="mr-2 ml-2 flex items-center text-xs font-extrabold self-center">
                              .
                            </Font> */}
              <Font className="text-xs text-gray-500">
                {moment(postTimestamp).fromNow()}
              </Font>
            </View>
          </View>
        </View>
        {allowPostEdit ? (
          <>
            <Pressable
              onPress={() => {
                openMenu();
              }}
              className="mr-2 w-4 h-4 lg:w-10 lg:-h-10"
            >
              <SVGLoader
                src={horizontalMenu}
                height={25}
                width={20}
                webHeight={"20em"}
                webWidth={"auto"}
              />
            </Pressable>
            {isMenuVisible ? (
              <Modal
                visible={isMenuVisible}
                transparent={true}
                onRequestClose={closeMenu}
              >
                <TouchableWithoutFeedback onPress={closeMenu}>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="w-full h-full justify-center items-center bg-black/50"
                  >
                    <TouchableWithoutFeedback>
                      <View
                        style={{
                          // position: "absolute",
                          // left: buttonPosition.left,
                          // top: buttonPosition.top,
                          backgroundColor: "white",
                          padding: 10,
                          borderRadius: 10,
                          width: 120,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.25,
                          shadowRadius: 3.84,
                          elevation: 5,
                        }}
                      >
                        <ScrollView>
                          <Pressable
                            onPress={() => {
                              setIsEditPostModal(true);
                              closeMenu();
                            }}
                            className=" pr-0 flex-1 item center bg-app-a rounded-lg shadow-sm"
                          >
                            <Font className="">Edit Post</Font>
                          </Pressable>
                          <View
                            style={{
                              borderWidth: 0.4,
                              marginVertical: 4,
                            }}
                          ></View>
                          <Pressable
                            onPress={() => {
                              setIsDeleteModal(true);
                              closeMenu();
                            }}
                            className=" pr-0 flex-1 item center bg-app-a rounded-lg shadow-sm"
                          >
                            <Font className="text-app-e">Delete Post</Font>
                          </Pressable>
                        </ScrollView>
                      </View>
                    </TouchableWithoutFeedback>
                  </View>
                </TouchableWithoutFeedback>
              </Modal>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </View>
      {postDescription ? (
        <View className="py-2 w-full text-sm">
          <Font>{postDescription}</Font>
        </View>
      ) : (
        <></>
      )}
      {postCoverImageURL ? (
        <View className="w-full h-56 py-1">
          <Image
            source={{
              uri:
                "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                postCoverImageURL,
            }}
            className="w-full h-full rounded-lg"
            // style={{ resizeMode: "contain" }}
          />
        </View>
      ) : (
        <></>
      )}

      {externalLinkUrl ? (
        <Pressable
          className="p-2 mb-0 w-full flex-row item-center justify-between"
          onPress={() =>
            externalLinkUrl
              ? Linking.openURL(externalLinkUrl).catch((e) =>
                  console.log("attachedFileURL error:", e)
                )
              : null
          }
        >
          <View className="flex justify-center items-center">
            <View className="flex item-center justify-between">
              <Font className="text-lg text-app-d">{externalLinkUrl}</Font>
              <Font className="text-sm text-app-d">
                {/* Headline copy here */}Click on this link to visit
              </Font>
            </View>
          </View>
          <View className="flex justify-center items-center">
            <View className="p-2 py-1 flex justify-center items-center bg-app-c border-2 border-gray-300 rounded-lg">
              <Font className="text-xs">Learn More</Font>
            </View>
          </View>
        </Pressable>
      ) : (
        <></>
      )}
      {attachedFileURL ? (
        <Pressable
          className="p-2 mb-5 w-full flex-row item-center justify-between"
          onPress={() =>
            attachedFileURL
              ? Linking.openURL(
                  "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                    attachedFileURL
                )
              : null
          }
        >
          <View className="flex justify-center items-center">
            <View className="flex item-center justify-between">
              <Font className="text-lg text-app-d">
                {/* {item?.external_link_url} */}
                Attached File
              </Font>
              <Font className="text-sm text-app-d">
                {/* Headline copy here */}Click to Download file
              </Font>
            </View>
          </View>
          <View className="flex justify-center items-center">
            <View className="p-2 py-1 flex justify-center items-center bg-app-c border-2 border-gray-300 rounded-lg">
              <Font className="text-xs">Download</Font>
            </View>
          </View>
        </Pressable>
      ) : (
        <></>
      )}
      <View className="p-4 w-full flex-row justify-between item-center border-b border-t border-y-gray-300 mt-1">
        <View className="flex-row justify-center item-center">
          <View className="z-10 h-8 w-8 bg-indigo-600 border-4 border-gray-300 rounded-full items-center justify-center">
            <RemixIcon name="ri-thumb-up-fill" color={colors.app.a} size="18" />
          </View>
          <View className="-ml-2 h-8 w-8 bg-red-400 border-4 border-gray-300 rounded-full items-center justify-center">
            <RemixIcon name="ri-heart-fill" color={colors.app.a} size="18" />
          </View>
          <View className="flex justify-center items-center">
            <Font className="text-base text-gray-500">{postLikes}</Font>
          </View>
        </View>
        <View className="w-full flex-row justify-center item-center">
          <Font className="text-base text-gray-500 mr-2">
            {postComments} Comments
          </Font>
          <Font className="text-base text-gray-500">{postShares} Shares</Font>
        </View>
      </View>
      <View className="py-2 w-full flex-row justify-around item-center">
        <View>
          <View className="w-full flex-row justify-around item-center">
            <Pressable
              onPress={() =>
                onLikeGroupPost({
                  postId: postId,
                  isLiked: isPostLiked,
                })
              }
              className="flex-row item-center"
            >
              <View className="h-8 w-8 mt-1">
                <RemixIcon
                  name="ri-thumb-up-line"
                  color={isPostLiked ? colors.app.f : ""}
                  size="22"
                />
              </View>
              <Font className="text-lg text-gray-600">Like</Font>
            </Pressable>
            {disableGroupPostComment ? (
              <></>
            ) : (
              <Pressable
                onPress={() => {
                  setIsCommentSectionVisible((prev) => !prev);
                }}
                // onPress={() =>
                //   navigation.navigate("vendor-feed-comments", {
                //     slug: id,
                //   })
                // navigation.goBack()
                // }
                className="flex-row item-center"
              >
                <View className="h-8 w-8 mt-1">
                  <RemixIcon
                    name="ri-chat-4-line"
                    color={colors.app.f4}
                    size="22"
                  />
                </View>
                <Font className="text-lg text-gray-600">Comment</Font>
              </Pressable>
            )}
            {disableGroupPostShare ? (
              <></>
            ) : (
              <Pressable
                onPress={() => onSharePost(postId)}
                className="flex-row item-center"
              >
                <View className="h-8 w-8 mt-1">
                  <RemixIcon
                    name="ri-share-forward-line"
                    color={colors.app.f4}
                    size="22"
                  />
                </View>
                <Font className="text-lg text-gray-600">Share</Font>
              </Pressable>
            )}
            {isEditPostModal ? (
              <CreateGroupPostModal
                attachedFileURL={attachedFileURL}
                visible={isEditPostModal}
                onRequestClose={() => {
                  setIsEditPostModal(false);
                  onCloseEditPostModal();
                }}
                postImage={
                  postCoverImageURL
                    ? "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                      postCoverImageURL
                    : null
                }
                Description={postDescription}
                externalLinkURL={externalLinkUrl}
                id={postId}
                groupId={groupId}
                onEditSuccess={({
                  cover_image_url,
                  external_link_url,
                  description,
                  attached_file_url,
                }) =>
                  onEditPost({
                    id: postId,
                    cover_image_url,
                    external_link_url,
                    description,
                    attached_file_url,
                  })
                }
                // getGroupPosts={getGroupPosts}
                // selectedImgObj={selectedImageObj}
              />
            ) : (
              <></>
            )}
          </View>
          {isCommentSectionVisible ? (
            <CommentSection
              // route={{ params: { slug: id } }}
              id={postId}
              groupId={groupId}
              useId={true}
              navigation={navigation}
            />
          ) : (
            <></>
          )}
        </View>
      </View>
      {isDeleteModal && (
        <ConfirmModal
          visible={isDeleteModal}
          onCancel={closeDeleteModal}
          onConfirm={() => {
            closeDeleteModal();
            onDeletePost(postId);
          }}
          confirmText={"Delete"}
          bodyText={"Are you sure you want to delete Post ?"}
          BodyComponent={false}
        ></ConfirmModal>
      )}
    </View>
  );
};

export default GroupPost;

import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Image,
  Pressable,
  View,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { Font } from "../../components/font";
import { Card } from "../../components/card";
import { Button, Input } from "../../components/input";
import colors from "../../../colors";
import { Footer } from "../../components/footer";
import { Gallery } from "../../components/gallery";
import { ProductsContext } from "../../context/productsContext";
import { AuthContext } from "../../context/authContext";
import { UserContext } from "../../context/userContext";
import {
  deleteProductBookmark,
  getProductsBookmark,
  getProductById,
  postProductBookmark,
  getProductCartItems,
  postProductCartItemsIncrement,
  postProductCartItems,
  DEV_API,
  axiosGet,
} from "../../services/api";
import { ActivityIndicator } from "react-native";
import Toast from "react-native-toast-message";
import { MessagesContext } from "../../context/messagesContext";
import { data } from "autoprefixer";

export function ProductDetails({ navigation, route }) {
  const { user, userToken } = useContext(AuthContext);
  const { setIsUserLoading, isUserLoading } = useContext(UserContext);
  const {
    cart,
    productDetails,
    isProductsLoading,
    setCart,
    setIsProductsLoading,
    setProductsApiError,
    setProductDetails,
    productOutOfStock,
    setProductOutOfStock,
    initialStock,
    setInitialStock,
  } = useContext(ProductsContext);
  const {
    channels,
    currentChannelMessages,
    currentChatData,
    setChannels,
    setCurrentChannelMessages,
    setUpdateChannelUnreadCount,
    resetCurrentChatData,
    setCurrentChatData,
    dispatchMessageEvents,
  } = React.useContext(MessagesContext);
  const [unitCount, setUnitCount] = useState(1);
  const [bookmark, setBookmark] = useState(null);
  const [cartItem, setCartItem] = useState([]);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const stockQuantityRef = useRef();
  // const isProductInCart = cart.some(
  //   (item) => item.productId === productDetails.id
  // );

  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const getCartItems = async (id) => {
    try {
      setIsProductsLoading(true);
      setProductsApiError(null);
      const res = await getProductCartItems(userToken);
      if (res.status === 200) {
        setCart(res.data.data);
        // const filterArray = res.data.data.filter(
        //   (e) => e.item.product_details.id == id
        // );
        // setCartItem(filterArray);
      } else {
        console.log("product-details, getCartItems res error : ", res.data);
      }
    } catch (error) {
      console.log("product-details, getCartItems error : ", error);
    } finally {
      setIsProductsLoading(false);
    }
  };
  useEffect(() => {
    const getData = async () => {
      try {
        setIsProductsLoading(true);
        setProductsApiError(null);
        const res = await getProductById({ id: route.params.id });
        if (res.status === 200) {
          console.log(
            "product-details, getproductbyid res------ : ",
            res.data?.data
          );
          const googleReviewsArray = res.data.data?.google_reviews
            ? [
                ...res.data.data?.google_reviews?.map((e, i) => ({
                  ...e,
                  isGoogleReview: true,
                })),
              ]
            : [];
          const reviewsArray = res.data.data?.reviews
            ? [
                ...res.data.data?.reviews?.map((e, i) => ({
                  ...e,
                  isGoogleReview: false,
                })),
              ]
            : [];
          setProductDetails({
            ...res.data.data,
            reviews: [...googleReviewsArray, ...reviewsArray],
            total_reviews:
              Number(res.data.data?.total_reviews) + (res.data.data?.google_reviews
                ? Number(res.data.data?.google_reviews?.length)
                : 0),
          });
        } else {
          console.log("product-details, getproductbyid res error : ", res);
          setProductsApiError(res.data);
        }
      } catch (error) {
        console.log("product-details, getproductbyid error : ", error);
      } finally {
        setIsProductsLoading(false);
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const filterArray = cart.filter(
      (e) => e?.item?.product_details?.id == productDetails?.id
    );
    setCartItem(filterArray);
  }, [cart]);

  useEffect(() => {
    outOfStockHandler();
  }, [productDetails]);
  useEffect(() => {
    const getInitialStock = async () => {
      try {
        setProductsApiError(null);
        const res = await getProductById({ id: productDetails?.id });
        if (res.status === 200) {
          const stockQuantity = res.data?.data?.variants[0]?.stock;
          stockQuantityRef.current = stockQuantity;
          setInitialStock(stockQuantity);
        }
      } catch (error) {
        console.log("error : ", error);
      }
    };
    if (productDetails) {
      getInitialStock();
    }
  }, [productDetails]);

  useEffect(() => {
    const getWishlist = async (token) => {
      setIsUserLoading(true);
      try {
        const res = await getProductsBookmark(token);
        if (res.status === 200) {
          const filterArray = res.data.data.filter(
            (e) => e.product.id === productDetails?.id
          );
          if (filterArray.length === 1) {
            const wishlistArray = filterArray.map((e) => {
              return { id: e.id, productId: e.product.id };
            });
            setBookmark(wishlistArray[0]);
          } else {
            setBookmark(null);
          }
        } else {
          console.log("product-details,  getWishlist res error : ", res.data);
        }
      } catch (error) {
        console.log("product-details, getWishlist error : ", error);
      } finally {
        setIsUserLoading(false);
      }
    };
    if (user && userToken !== null && productDetails !== null) {
      getWishlist(userToken);
      getCartItems(productDetails?.id);
    }
  }, [user, userToken, productDetails]);

  const outOfStockHandler = async () => {
    try {
      setProductsApiError(null);
      const res = await getProductById({ id: productDetails?.id }); // Pass the product ID here
      const stockQuantity = res.data?.data?.variants[0]?.stock;
      if (res.status === 200 && stockQuantity > 0) {
        setProductOutOfStock(false); // Product is in stock
      } else {
        setProductOutOfStock(true); // Product is out of stock
      }
    } catch (error) {
      console.log("error : ", error);
    }
  };

  const onAddToCart = async () => {
    try {
      // setIsProductsLoading(true);
      setProductsApiError(null);
      setIsAddingToCart(true);
      await outOfStockHandler();

      let res;
      if (cartItem.length > 0) {
        res = await postProductCartItemsIncrement({
          item_id: cartItem[0].id,
          token: userToken,
          quantity: unitCount,
        });
      } else {
        res = await postProductCartItems({
          productId: productDetails?.id,
          varientId: productDetails?.variants[0].id,
          token: userToken,
          quantity: unitCount,
        });
      }
      if (res.status === 200) {
        showToast("success", "Success", "Added to cart");
        // setCartItem([res.data.data]);
        getCartItems(productDetails?.id);
        setUnitCount(1);
        // stockQuantityRef.current = stockQuantityRef.current - unitCount;
        // setInitialStock(stockQuantityRef.current);
      } else {
        showToast("error", "Error", res.data.message);
        console.log("product-details, onAddToCart res error : ", res.data);
      }
    } catch (error) {
      console.log("product-details, onAddToCart error : ", error);
    } finally {
      setIsAddingToCart(false);
    }
  };

  const goToReview = (slug) => {
    navigation.navigate("reviews");
  };

  const getRatingStars = ({ rating }) => {
    const rating1 = [...Array(Math.floor(rating)).keys()];
    const ishalfStar = rating1 % 1 === 0.5;
    return (
      <>
        {rating1.map((e, i) => (
          <RemixIcon
            name="star-fill"
            size={16}
            color={colors.app.i}
            className="flex-shrink-0"
            key={`icon_${i}`}
          ></RemixIcon>
        ))}
        {ishalfStar && (
          <RemixIcon
            name="star-half-fill"
            size={16}
            color={colors.app.i}
            className="flex-shrink-0"
          ></RemixIcon>
        )}
      </>
    );
  };
  const onBookmark = async () => {
    try {
      if (bookmark) {
        const res = await deleteProductBookmark({
          bookmark_id: bookmark.id,
          token: userToken,
        });
        if (res.status === 204) {
          showToast("success", "Success", "Bookmark removed.");
          setBookmark(null);
        } else {
          console.log("product-details, onBookmarkdelete res err : ", res.data);
        }
      } else {
        const res = await postProductBookmark({
          id: productDetails?.id,
          token: userToken,
        });
        if (res.status === 201) {
          showToast("success", "Success", res.data.message);
          setBookmark({ id: res.data.data.id, productId: productDetails?.id });
        } else {
          console.log("product-details, onBookmarkadd res err : ", res.data);
        }
      }
    } catch (error) {
      console.log("product-details, onBookmark error : ", error);
    }
  };

  const getMessages = async (channel_id) => {
    await axiosGet({
      Url: `/chat/channels/${channel_id}/messages`,
      token: userToken,
      param: {
        // from: 0,
        offset: 0,
        limit: 20,
      },
    })
      .then((data) => {
        setCurrentChannelMessages({ data: [...data.data.data].reverse() });
        setUpdateChannelUnreadCount({ id: channel_id });
        navigation.navigate("messages", { contact_vendor: true });
      })
      .catch((err) => {
        console.log("messages, getChatList error: ", err);
      })
      .finally(() => {
        setIsUserLoading(false);
      });
  };
  const getChatList = async ({ vendor_id }) => {
    await axiosGet({
      Url: "/chat/channels",
      token: userToken,
    })
      .then((data) => {
        const updatedList = data.data.data.map((e, i) => {
          return {
            ...e,
            unread: 0,
          };
        });
        let channelIndex;
        if (updatedList?.length > 0) {
          channelIndex = updatedList.findIndex(
            (e) => e.vendor.id === vendor_id
          );
          if (channelIndex > -1) {
            if (channelIndex === 0) {
              setCurrentChatData({
                data: updatedList[channelIndex],
                currIndex: channelIndex,
              });
              setChannels({ data: updatedList });
              getMessages(updatedList[channelIndex].id);
            } else {
              let updateCurrChannelList = [...updatedList];
              updateCurrChannelList.unshift(
                ...updatedList.splice(channelIndex, 1)
              );
              setChannels({ data: updateCurrChannelList });
              setCurrentChatData({
                data: updatedList[channelIndex],
                currIndex: 0,
              });
              getMessages(updatedList[channelIndex].id);
            }
          }
        }
      })
      .catch((err) => {
        console.log("messages, getChatList error: ", err);
      })
      .finally(() => {
        // setIsUserLoading(false);
      });
  };
  const onContactVendor = async ({ vendor_id }) => {
    let channelExists = false;
    let channelIndex;
    if (channels && channels?.length > 0) {
      channelIndex = await channels.findIndex((e) => e.vendor_id === vendor_id);
      if (channelIndex > -1) {
        channelExists = true;
      }
    }
    if (channels?.length > 0 && !!channelExists) {
      setCurrentChatData({
        data: channels[channelIndex],
        currIndex: channelIndex,
      });
      getMessages(channels[channelIndex]?.id);
    } else if (channels?.length > 0 && !channelExists) {
      dispatchMessageEvents.joinChannel({
        vendor_id: vendor_id,
        cb: (res) => {
          let updateCurrChannelList = [...channels];
          updateCurrChannelList.unshift(res.data.channel);
          setChannels({ data: updateCurrChannelList });
          setCurrentChatData({ data: res.data.channel, currIndex: 0 });
          getMessages(res.data.channel.id);
        },
      });
    } else if (channels.length === 0 && !channelExists) {
      dispatchMessageEvents.joinChannel({
        vendor_id: vendor_id,
        cb: (res) => {
          getChatList({ vendor_id: vendor_id });
        },
      });
    }
  };
  const itemsLeft =
    initialStock -
    (cart.find((cartItem) => cartItem?.item?.id === productDetails?.id)
      ?.quantity ?? 0);
  const disableIncrement = itemsLeft - unitCount <= 0;

  return (
    <View className="flex-1 bg-app-e">
      <View className="flex-1">
        <View className="flex-1 rounded-t-2xl lg:rounded-none overflow-hidden bg-app-a">
          <ScrollView
            className="flex-1 h-4"
            contentContainerStyle={{
              flexGrow: 1,
              alignItems: "center",
            }}
            overScrollMode="never"
          >
            <View className="flex-1 w-full p-4 items-center">
              {productDetails && !isProductsLoading ? (
                <View className="flex-col lg:flex-row max-w-screen-lg w-full">
                  <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
                    <View className="h-96">
                      <Gallery
                        className="h-full"
                        product={true}
                        outOfStockHandler={outOfStockHandler}
                      ></Gallery>
                    </View>
                    <View className="h-8"></View>
                    {/* {productOutOfStock && (
                      <View className="h-8 bg-app-e rounded-lg flex items-center justify-center mb-2">
                        <Font className="font-bold text-white" weight={700}>
                          Out of Stock
                        </Font>
                      </View>
                    )} */}
                    <Card title="Details">
                      <View className="flex-1 w-full p-4 pt-0">
                        <Font
                          className="text-xl lg:text-2xl text-app-d  leading-5 mb-2"
                          numberOfLines={2}
                        >
                          {productDetails?.variants[0].title}
                        </Font>
                        <View
                          className="flex-row items-center"
                          style={{
                            marginVertical: 2,
                          }}
                        >
                          <Font
                            className="text-[15px] text-app-d"
                            numberOfLines={1}
                            weight={700}
                          >
                            {" "}
                            $
                            {productDetails?.variants[0].sale_price / 100 ||
                              productDetails?.variants[0].price / 100}{" "}
                          </Font>
                          <Font
                            className="text-md text-app-e1 line-through ml-2"
                            weight={600}
                            numberOfLines={1}
                          >
                            {" "}
                            $
                            {productDetails?.variants[0].price / 100 ||
                              productDetails?.variants[0].sale_price / 100}
                          </Font>
                        </View>
                        <View
                          className="flex-row justify-between pr-3"
                          style={{ marginVertical: 4 }}
                        >
                          <View className="flex-row items-center">
                            <RemixIcon
                              name="star-fill"
                              size={16}
                              color={colors.app.i}
                              className="mr-1 flex-shrink-0"
                            ></RemixIcon>
                            <Font
                              className="text-md text-app-d1"
                              numberOfLines={1}
                            >
                              {" "}
                              {productDetails?.average_rating} |
                              {productDetails?.total_reviews} reviews
                            </Font>
                          </View>
                          <View className="flex-col items-end relative">
                            <TouchableOpacity
                              onPress={user ? () => onBookmark() : () => {}}
                            >
                              <Ionicons
                                name={
                                  bookmark
                                    ? "ios-bookmark"
                                    : "ios-bookmark-outline"
                                }
                                size={22}
                                color={user ? colors.app.e : colors.app.d1}
                              />
                            </TouchableOpacity>
                          </View>
                        </View>

                        {productOutOfStock && (
                          <View className="flex-row justify-between pr-3 mt-2">
                            <View className=" bg-app-e3 px-2 py-1 rounded-md">
                              <Font className="font-bold text-app-e">
                                Out of stock
                              </Font>
                            </View>
                          </View>
                        )}
                        <View className="mt-4">
                          <Font
                            className="text-app-d leading-5"
                            numberOfLines={6}
                          >
                            {productDetails?.variants[0].description}
                          </Font>
                        </View>
                        <View className="flex-row lg:hidden justify-between items-center my-2">
                          {/* <Font className="w-1/2 text-app-g text-center">
                            50 in stock
                          </Font> */}
                          <View className="w-full">
                            {user && !productOutOfStock && (
                              <Input
                                type="counter"
                                // label="Number of units"
                                setValue={(e) => {
                                  setUnitCount(e);
                                }}
                                value={unitCount}
                                isIncrementDisabled={disableIncrement}
                                isDecrementDisabled={unitCount === 1}
                              ></Input>
                            )}
                          </View>
                        </View>
                        <View className="flex-row justify-between items-center my-2 lg:hidden">
                          {isAddingToCart ? (
                            <View className="w-full flex-1 lg:w-1/2 bg-app-e h-10 rounded-lg justify-center">
                              <ActivityIndicator
                                size={"small"}
                                color={colors.app.a}
                              ></ActivityIndicator>
                            </View>
                          ) : (
                            <Button
                              type={`${
                                user && !productOutOfStock && itemsLeft > 0
                                  ? "primary"
                                  : "disabled"
                              }`}
                              label={`${
                                user ? "Add to cart" : "Sign in to Place Order"
                              }`}
                              onPress={
                                user && !productOutOfStock && itemsLeft > 0
                                  ? () => onAddToCart()
                                  : () => {}
                              }
                            ></Button>
                          )}
                        </View>
                        {itemsLeft > 0 && itemsLeft <= 10 && (
                          <View className="h flex items-center justify-center mb-2">
                            <Font className="font-bold text-app-e">
                              {`Items left : ${itemsLeft}`}
                            </Font>
                          </View>
                        )}
                        {itemsLeft === 0 && (
                          <View className="h flex items-center justify-center mb-2">
                            <Font className="font-bold text-app-e">
                              Maximum Limit Reached
                            </Font>
                          </View>
                        )}
                        {/* <View className="flex-row justify-between items-center mt-2 lg:hidden">
                          {user ? (
                            <Button
                              type={`${user ? "primary" : "disabled"}`}
                              label="Buy now"
                              className="w-full"
                            ></Button>
                          ) : null}
                        </View> */}
                        {!user && (
                          <View className="flex-row justify-between items-center mt-2">
                            <Font className="text-app-d1">
                              Please log in to buy a product.
                            </Font>
                          </View>
                        )}
                      </View>
                    </Card>
                    {productDetails?.additional_info && (
                      <Card title="Additional Info">
                        <View className="w-full p-4 pt-0">
                          {[
                            ...Object.keys(productDetails?.additional_info || {}),
                          ].map((e, i) => (
                            <Font key={`additional_info_${i}`}>
                              {e} : {productDetails?.additional_info[e]}
                            </Font>
                          ))}
                        </View>
                      </Card>
                    )}
                    {/* ---------- reviews lg: hidden ----------- */}
                    <Card
                      title={`Reviews (${productDetails?.total_reviews})`}
                      className="lg:hidden"
                      Widget={() => (
                        <TouchableOpacity
                          className="flex-row px-4 py-1 bg-app-c rounded-md"
                          onPress={() =>
                            navigation.navigate({
                              name: "review",
                              params: {
                                slug: productDetails?.id,
                                type: "product",
                              },
                            })
                          }
                        >
                          <Font className="text-xs ">Go to reviews</Font>
                        </TouchableOpacity>
                      )}
                    >
                      {productDetails?.reviews.length > 0 ? (
                        <View className="flex-row w-full px-3">
                          <ScrollView horizontal>
                            <View className="gap-4 flex-row">
                              {productDetails?.reviews.map((e, i) => (
                                <View
                                  className="w-72 p-3 pl-0 flex-col border border-gray-200 rounded-lg "
                                  key={`review_${i}`}
                                >
                                  <View className="flex-1 mb-4">
                                    <View className="flex-row items-center">
                                      <View className="bg-app-e w-1 h-5 rounded-sm"></View>
                                      <View className="items-center flex-row w-full pt-1 pb-2 ml-3">
                                        {getRatingStars({ rating: e.rating })}
                                      </View>
                                      {e.isGoogleReview && (
                                        <View className="flex-row">
                                          <Font
                                            className="text-app-d bg-app-c1 px-2 py-1 text-xs"
                                            numberOfLines={1}
                                          >
                                            Google review
                                          </Font>
                                        </View>
                                      )}
                                    </View>
                                    <View className="ml-4">
                                      <Font
                                        className="w-full text-app-d"
                                        numberOfLines={3}
                                      >
                                        {e.message}
                                      </Font>
                                    </View>
                                  </View>
                                  <View className="w-full lg:w-44 mb-2 mr-4 items-center justify-center flex-row ">
                                    {/* <View className="bg-app-e w-1 h-8 rounded-sm"></View> */}
                                    <View className="w-10 h-10 rounded-full overflow-hidden ml-3">
                                      <Image
                                        source={
                                          e.user_profile_image_url
                                            ? {
                                                uri: `${DEV_API}/media/${e.user_profile_image_url}`,
                                              }
                                            : require("../../../assets/user1.jpg")
                                        }
                                        className="w-full h-full object-contain"
                                      ></Image>
                                    </View>
                                    <View className="flex-1 ml-2">
                                      <Font
                                        className="text-app-d"
                                        numberOfLines={1}
                                      >
                                        {e.user_name}
                                      </Font>
                                      <Font className="text-xs text-app-d1">
                                        {new Date(e.created_at).toDateString()}
                                      </Font>
                                    </View>
                                  </View>

                                  {e.replies.length > 0 &&
                                    e.replies.map((ee, i) => (
                                      <View className="p-4 bg-gray-100 mt-4 rounded-lg">
                                        <View className="items-center justify-center flex-row mb-2">
                                          <View className="w-8 h-8 rounded-full overflow-hidden">
                                            <Image
                                              source={require("../../../assets/user1.jpg")}
                                              className="flex-1"
                                            ></Image>
                                          </View>
                                          <View className="flex-1 ml-2">
                                            <View className="flex-row items-center">
                                              <Font
                                                numberOfLines={1}
                                                className="text-app-d"
                                              >
                                                {ee.user_name}
                                              </Font>
                                              <View className="px-1 bg-app-g rounded ml-2">
                                                <Font className="text-app-a text-xs">
                                                  {ee.user_role}
                                                </Font>
                                              </View>
                                            </View>
                                            <Font className="text-xs text-app-d1">
                                              {new Date(
                                                ee.created_at
                                              ).toDateString()}
                                            </Font>
                                          </View>
                                        </View>
                                        <Font className="w-full text-app-d">
                                          {ee.message}
                                        </Font>
                                      </View>
                                    ))}
                                </View>
                              ))}
                            </View>
                            {productDetails?.total_reviews > 5 && (
                              <View className="flex-1 items-center justify-center pl-2">
                                <TouchableOpacity
                                  className="flex-row bg-app-c rounded-full p-2"
                                  onPress={() =>
                                    navigation.navigate({
                                      name: "review",
                                      params: {
                                        slug: productDetails?.id,
                                        type: "product",
                                      },
                                    })
                                  }
                                >
                                  <MaterialIcons
                                    name="keyboard-arrow-right"
                                    size={24}
                                    color={colors.app.d}
                                  />
                                </TouchableOpacity>
                              </View>
                            )}
                          </ScrollView>
                        </View>
                      ) : (
                        <View className="h-20 flex justify-center items-center">
                          <Font className="text-center text-app-d1">
                            No reviews
                          </Font>
                        </View>
                      )}
                    </Card>

                    {/* ---------- reviews lg: flex ----------- */}
                    <Card
                      title={`Reviews (${productDetails?.total_reviews})`}
                      className="hidden lg:flex"
                      Widget={() => (
                        <TouchableOpacity
                          className="px-4 py-1 bg-app-c rounded-md"
                          onPress={() =>
                            navigation.navigate({
                              name: "review",
                              params: {
                                slug: productDetails?.id,
                                type: "product",
                              },
                            })
                          }
                        >
                          <Font className="text-xs ">See all</Font>
                        </TouchableOpacity>
                      )}
                    >
                      <ScrollView className="flex-1 w-full p-4 pt-0">
                        {productDetails?.reviews.map((e, i) => (
                          <View
                            className="w-full py-4 flex-col lg:flex-row border-b border-b-gray-200"
                            key={`reviews_${i}`}
                          >
                            <View className="w-full lg:w-44 mb-4 mr-4">
                              <View className="items-center justify-center flex-row ">
                                <View className="w-10 h-10 rounded-full overflow-hidden">
                                  <Image
                                    source={
                                      e.user_profile_image_url
                                        ? {
                                            uri: `${DEV_API}/media/${e.user_profile_image_url}`,
                                          }
                                        : require("../../../assets/user1.jpg")
                                    }
                                    className="flex-1"
                                  ></Image>
                                </View>
                                <View className="flex-1 ml-2">
                                  <Font
                                    className="text-app-d"
                                    numberOfLines={1}
                                  >
                                    {e.user_name}
                                  </Font>
                                  <Font className="text-xs text-app-d1">
                                    {new Date(e.created_at).toDateString()}
                                  </Font>
                                </View>
                              </View>
                            </View>
                            <View className="flex-1">
                              <View className="items-center flex-row w-full pt-1 pb-2 ">
                                {getRatingStars({ rating: e.rating })}
                              </View>
                              <View>
                                <Font className="w-full text-app-d">
                                  {e.message}
                                </Font>
                              </View>
                              {/* {e.replies.length > 0 &&
                                e.replies.map((ee, i) => (
                                  <View className="p-4 bg-gray-100 mt-4 rounded-lg">
                                    <View className="items-center justify-center flex-row mb-2">
                                      <View className="w-8 h-8 rounded-full overflow-hidden">
                                        <Image
                                          source={require("../../../assets/bg.jpg")}
                                          className="flex-1 object-contain"
                                        ></Image>
                                      </View>
                                      <View className="flex-1 ml-2">
                                        <View className="flex-row items-center">
                                          <Font
                                            numberOfLines={1}
                                            className="text-app-d"
                                          >
                                            {ee.user_name}
                                          </Font>
                                          <View className="px-1 bg-app-g rounded ml-2">
                                            <Font className="text-app-a text-xs">
                                              {ee.user_role}
                                            </Font>
                                          </View>
                                        </View>
                                        <Font className="text-xs text-app-d1">
                                          {new Date(
                                            ee.created_at
                                          ).toDateString()}
                                        </Font>
                                      </View>
                                    </View>
                                    <Font className="w-full text-app-d">
                                      {ee.message}
                                    </Font>
                                  </View>
                                ))} */}
                            </View>
                          </View>
                        ))}
                        {/* {productDetails?.total_reviews > 5 && (
                          <View className="flex-1 items-center justify-center pl-2">
                            <TouchableOpacity
                              className="flex-row bg-app-c rounded-full p-2"
                              onPress={() =>
                                navigation.navigate({
                                  name: "review",
                                  params: {
                                    slug: productDetails?.id,
                                    type: "product",
                                  },
                                })
                              }
                            >
                              <MaterialIcons
                                name="keyboard-arrow-right"
                                size={24}
                                color={colors.app.d}
                              />
                            </TouchableOpacity>
                          </View>
                        )} */}
                      </ScrollView>
                      {productDetails?.reviews.length > 5 && (
                        <View className="w-full p-4 pt-0 flex-row justify-end">
                          <View className="ml-auto flex-row">
                            <Pressable className="py-1 px-2 mx-2 ">
                              <Font className="text-app-d">&lt;</Font>
                            </Pressable>
                            <Pressable className="py-1 px-2 mx-2  border-b-2 border-b-app-g">
                              <Font className="text-app-g">1</Font>
                            </Pressable>
                            <Pressable className="py-1 px-2 mx-2 ">
                              <Font className="text-app-d">2</Font>
                            </Pressable>
                            <Pressable className="py-1 px-2 mx-2 ">
                              <Font className="text-app-d">3</Font>
                            </Pressable>
                            <Pressable className="py-1 px-2 mx-2 ">
                              <Font className="text-app-d">&gt;</Font>
                            </Pressable>
                          </View>
                        </View>
                      )}
                    </Card>
                  </View>
                  <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
                    {/* <Pressable className="w-full p-4 bg-app-a border border-gray-200 flex-row rounded-lg items-center mb-8 hidden lg:flex">
                      <RemixIcon
                        name="bookmark-line"
                        color={colors.app.d}
                        size={24}
                        className="mr-2"
                      ></RemixIcon>
                      <Font className="text-app-d text-md">
                        Add to wishlist
                      </Font>
                    </Pressable> */}
                    <Card title="Add to cart" className="hidden lg:flex">
                      <View className="flex-1 w-full p-4 pt-0">
                        {user && !productOutOfStock && (
                          <Input
                            type="counter"
                            // label="Number of units"
                            setValue={(e) => {
                              setUnitCount(e);
                            }}
                            value={unitCount}
                            isIncrementDisabled={disableIncrement}
                            isDecrementDisabled={unitCount === 1}
                          ></Input>
                        )}
                      </View>
                      <View className="flex-1 w-full p-4 pt-0">
                        {/* <Input type="text" label={"Note"}></Input> */}
                      </View>
                      {/* <View className="flex-row w-full px-4">
                          <Font className="flex-1">Subtotal</Font>
                          <Font>$ 240.00</Font>
                        </View> */}
                      <View className="p-4 rounded-lg flex-row w-full items-center">
                        {isAddingToCart ? (
                          <View className="w-full flex-1 lg:w-1/2 bg-app-e h-10 rounded-lg justify-center">
                            <ActivityIndicator
                              size={"small"}
                              color={colors.app.a}
                            ></ActivityIndicator>
                          </View>
                        ) : (
                          <Button
                            type={`${
                              user && !productOutOfStock && itemsLeft > 0
                                ? // user && !productOutOfStock
                                  "primary"
                                : "disabled"
                            }`}
                            label={`${
                              user ? "Add to cart" : "Sign in to Place Order"
                            }`}
                            onPress={
                              user && !productOutOfStock && itemsLeft > 0
                                ? () => onAddToCart()
                                : () => {}
                            }
                          ></Button>
                        )}
                      </View>
                      {itemsLeft > 0 && itemsLeft <= 10 && (
                        <View className="h flex items-center justify-center mb-2">
                          <Font className="font-bold text-app-e">
                            {`Items left : ${itemsLeft}`}
                          </Font>
                        </View>
                      )}
                      {itemsLeft === 0 && (
                        <View className="h flex items-center justify-center mb-2">
                          <Font className="font-bold text-app-e">
                            Maximum Limit Reached
                          </Font>
                        </View>
                      )}
                      {/* <View className="px-4 py-0 rounded-lg flex-row w-full items-center">
                        {user ? (
                          <Button
                            type={`${user ? "primary" : "disabled"}`}
                            label="Buy now"
                            className="w-full"
                          ></Button>
                        ) : null}
                      </View> */}
                    </Card>
                    {/* ------------------- vendor details ------------------- */}
                    <Card
                      title="Vendor"
                      Widget={() => (
                        <TouchableOpacity
                          className="flex-row px-4 py-1 bg-app-c rounded-md"
                          onPress={() =>
                            navigation.navigate("vendor", {
                              id: productDetails?.vendor_profile?.id,
                            })
                          }
                        >
                          <Font className="text-xs">View Details</Font>
                        </TouchableOpacity>
                      )}
                    >
                      <View className="flex-col w-full pt-0">
                        <Pressable
                          className="h-12 items-center justify-center flex-row px-4"
                          onPress={() =>
                            navigation.navigate("vendor", {
                              id: productDetails?.vendor_id,
                            })
                          }
                        >
                          <View className="w-10 h-10 rounded-full overflow-hidden">
                            <Image
                              source={require("../../../assets/user1.jpg")}
                              className="w-full h-full object-contain"
                            ></Image>
                          </View>
                          <View className="flex-1 ml-2">
                            <Font>{productDetails?.vendor_profile?.email}</Font>
                          </View>
                        </Pressable>
                        <View className="items-center w-full p-4">
                          {productDetails?.vendor_profile?.about !== null && (
                            <View className="w-full flex-row">
                              <Font className="pr-2 text-app-d1 mb-4">
                                about :
                              </Font>
                              <Font className=" text-app-d1 mb-4">
                                {productDetails?.vendor_profile?.about}
                              </Font>
                            </View>
                          )}
                          <Button
                            leftIcon={{
                              name: "message-2-fill",
                              color: colors.app.d,
                            }}
                            label="Contact vendor"
                            onPress={() =>
                              onContactVendor({
                                vendor_id: productDetails?.vendor_id,
                              })
                            }
                          ></Button>
                        </View>
                      </View>
                    </Card>
                    {/* ------------------- our links ------------------- */}
                    <Card title="Our links" className="">
                      <View className="flex-row w-full pt-0 pb-3 justify-between items-center px-10">
                        <View>
                          <RemixIcon
                            name="instagram-line"
                            size={22}
                            color={colors.app.e}
                            className="flex-shrink-0"
                          ></RemixIcon>
                        </View>
                        <View>
                          <RemixIcon
                            name="youtube-line"
                            size={22}
                            color={colors.app.e}
                            className="flex-shrink-0"
                          ></RemixIcon>
                        </View>
                        <View>
                          <RemixIcon
                            name="facebook-line"
                            size={22}
                            color={colors.app.f}
                            className="flex-shrink-0"
                          ></RemixIcon>
                        </View>
                        <View>
                          <RemixIcon
                            name="twitter-line"
                            size={22}
                            color={colors.app.f}
                            className="flex-shrink-0"
                          ></RemixIcon>
                        </View>
                        <View>
                          <RemixIcon
                            name="whatsapp-line"
                            size={22}
                            color={colors.app.g}
                            className="flex-shrink-0"
                          ></RemixIcon>
                        </View>
                      </View>
                    </Card>
                  </View>
                </View>
              ) : productDetails === null && !isProductsLoading ? (
                <View>
                  <Font>No Details found</Font>
                </View>
              ) : (
                <View className="flex-1 w-full justify-center h-40">
                  <ActivityIndicator
                    size={"small"}
                    color={colors.app.e}
                  ></ActivityIndicator>
                </View>
              )}
            </View>
            <Footer></Footer>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}

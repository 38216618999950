import { View } from "react-native"
import RemixIcon from "react-native-remix-icon"
// import { View } from "react-native-web"
import colors from "../../../colors"
import { A, Font } from "../../components/font"

export const Unautorized = () => {
    return (
        <View className="flex-1 items-center justify-center">
            <View className="items-center">
            <RemixIcon name="lock-fill" size={60} color={colors.app.c}></RemixIcon>
            <Font className="my-4">Please sign in first</Font>
            <A href="signin" className="text-app-e font-bold" label="Sign in"></A>
            </View>
        </View>
    )
}
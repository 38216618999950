import React from "react";
import { Dimensions } from "react-native";

const INITIAL_STATE = {
  searchParams: null,
  setSearchParams: () => null,
  route: null,
  setRoute: () => null,
  window: Dimensions.get("window"),
  screen: Dimensions.get("screen"),
  reset: () => null,
  temporaryData: null,
  setTemporaryData: () => null,
};

const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_SEARCH_PARAMS":
      return { ...state, searchParams: action.payload };
    case "SET_ROUTE":
      return { ...state, route: action.payload };
    case "SET_DIMENTIONS":
      return {
        ...state,
        window: action.payload.window,
        screen: action.payload.screen,
      };
    case "SET_TEMPORARY_DATA":
      return { ...state, temporaryData: action.payload };
    case "RESET_SHARED_STATES":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const SharedContext = React.createContext(INITIAL_STATE);

export const SharedContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(Reducer, INITIAL_STATE);

  React.useEffect(() => {
    const onDimensionsChange = (data) => {
      dispatch({ type: "SET_DIMENTIONS", payload: data });
    };
    Dimensions.addEventListener("change", onDimensionsChange);

    // return unsubscribe;
  }, []);

  return (
    <SharedContext.Provider
      value={{
        searchParams: state.searchParams,
        setSearchParams: (data) => {
          dispatch({ type: "SET_SEARCH_PARAMS", payload: data });
        },
        route: state.route,
        setRoute: (data) => {
          dispatch({ type: "SET_ROUTE", payload: data });
        },
        setTemporaryData: (data) => {
          dispatch({ type: "SET_TEMPORARY_DATA", payload: data });
        },
        window: state.window,
        screen: state.screen,
        temporaryData: state.temporaryData,
      }}
    >
      {children}
    </SharedContext.Provider>
  );
};

export const useSharedContext = () => React.useContext(SharedContext);

import React, { useContext, useState } from "react";
import { Image, View, Pressable, Platform } from "react-native";
import { Font, A } from "../../components/font";
import { Button, Input, Switcher } from "../../components/input";
import colors from "../../../colors";
import { ScrollView } from "react-native-gesture-handler";
import { postUserLogin } from "../../services/api";

import * as WebBrowser from "expo-web-browser";
import * as AuthSession from "expo-auth-session";
import { AuthContext } from "../../context/authContext";
import { ResponseModal } from "../../components/response-modal";
import { ActivityIndicator } from "react-native";
import Toast from "react-native-toast-message";
import GoogleSigninFunctionWeb from "./googleSignin";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { UserContext } from "../../context/userContext";

WebBrowser.maybeCompleteAuthSession();
const redirectUri = AuthSession.makeRedirectUri();

export const Signin = ({ navigation }) => {
  const {
    isAuthLoading,
    setIsAuthLoading,
    setUser,
    setAuthApiError,
    setUserToken,
  } = useContext(AuthContext);
  const { setUserData } = useContext(UserContext);
  const [modal, setModal] = useState({
    isVisible: false,
    message: "Test Modal",
  });
  const [signUpData, setSignInData] = useState({
    isNonProfit: false,
    email: "",
    password: "",
    remember: false,
    // role: "customer",
  });
  const [isValid, setIsValid] = useState({
    email: false,
    password: false,
    formIsValid: false,
  });
  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  let GoogleSigninFunction;
  if (Platform.OS !== "web") {
    GoogleSigninFunction = require("./googleSignin.native").default;
  }

  const signIn = async () => {
    try {
      if (
        isValid.password !== "" &&
        isValid.email !== "" &&
        isValid.formIsValid
      ) {
        setIsAuthLoading(true);
        const res = await postUserLogin({ ...signUpData, role: "customer" });
        if (res.status === 200) {
          showToast("success", "Success", res.data.message);
          setUser(true);
          setUserData(res.data.data);
          setUserToken(res.data.token);
          setAuthApiError(null);
          navigation.navigate("app", { screen: "home" });
        } else {
          showToast("error", "Error", res.data.message);
          setUser(false);
          setAuthApiError(res.data.message);
          // setModal({ isVisible: true, message: res.data.message });
        }
      }
    } catch (err) {
      console.log("signin, log in err : ", err);
    } finally {
      setIsAuthLoading(false);
    }
  };
  const setIsInputValid = ({ e, type }) => {
    switch (type) {
      case "email":
        if (isValid.password === true && e === true) {
          setIsValid({ ...isValid, email: e, formIsValid: true });
        } else {
          setIsValid({ ...isValid, email: e, formIsValid: false });
        }
        break;
      case "password":
        if (isValid.email === true && e === true) {
          setIsValid({ ...isValid, password: e, formIsValid: true });
        } else {
          setIsValid({ ...isValid, password: e, formIsValid: false });
        }
        break;

      default:
        break;
    }
  };
  return (
    <View className="w-full h-full bg-app-b items-center justify-center">
      <View className="h-full w-full md:w-1/2 items-center justify-center">
        <View className="flex-1 w-full items-cente justify-center">
          <ScrollView keyboardShouldPersistTaps="always">
            <View className="flex-1 w-full items-center justify-center mt-16">
              <View className="flex-1 w-4/5 lg:w-full items-center justify-center">
                <Pressable onPress={() => navigation.replace("app")}>
                  <Image
                    source={require("../../../assets/smbex-lo.png")}
                    className="w-36 h-12 mb-8"
                  ></Image>
                </Pressable>
                <View className="w-full my-2">
                  <Font className="text-xl text-app-d" weight={500}>
                    Sign in
                  </Font>
                </View>
                <View className="w-full my-2">
                  <Switcher
                    value={signUpData.isNonProfit}
                    setValue={(e) => {
                      setSignInData({ ...signUpData, isNonProfit: e });
                    }}
                    options={[
                      { label: "Regular", value: false },
                      { label: "Non-Profit", value: true },
                    ]}
                  ></Switcher>
                </View>
                <View className="w-full h-[1px] bg-gray-200 my-2"></View>
                {Platform.OS == "web" ? (
              <GoogleOAuthProvider 
              clientId="1049029176568-8vkue4crnqh3qqontpnqs8jso1majv0u.apps.googleusercontent.com"
              >
                <GoogleSigninFunctionWeb  navigation={navigation}/>
                  </GoogleOAuthProvider>
                ) : (
                  <GoogleSigninFunction
                    showToast={showToast}
                    setUser={setUser}
                    setUserToken={setUserToken}
                    setAuthApiError={setAuthApiError}
                    navigation={navigation}
                    setIsAuthLoading={setIsAuthLoading}
                  />
                )}
                <View className="w-full h-4 items-center justify-center mb-2">
                  <View className="w-full h-[1px] bg-gray-200 my-2"></View>
                  <View className="absolute bg-gray-50 py-1 px-2">
                    <Font>or</Font>
                  </View>
                </View>
                <View className="w-full mb-2">
                  <Input
                    type="email"
                    value={signUpData.email}
                    setValue={(e) => {
                      setSignInData({ ...signUpData, email: e });
                    }}
                    label="Email"
                    placeholder="user@example.com"
                    validation={true}
                    isValid={(e) => setIsInputValid({ e, type: "email" })}
                  ></Input>
                </View>
                <View className="w-full mb-2">
                  <Input
                    type="password"
                    value={signUpData.password}
                    setValue={(e) => {
                      setSignInData({ ...signUpData, password: e });
                    }}
                    label="Password"
                    placeholder="************"
                    validation={true}
                    isValid={(e) => setIsInputValid({ e, type: "password" })}
                  ></Input>
                </View>

                <View className="w-full mb-2">
                  <Input
                    type="checkbox"
                    value={signUpData.remember}
                    setValue={(e) => {
                      setSignInData({ ...signUpData, remember: e });
                    }}
                    label="Remember me"
                  ></Input>
                </View>
                {isAuthLoading ? (
                  <View className="flex-1 w-full items-center justify-center mt-4 h-10 bg-app-e rounded-lg py-2">
                    <ActivityIndicator
                      size={"small"}
                      color={colors.app.a}
                    ></ActivityIndicator>
                  </View>
                ) : (
                  <View className="flex-1 w-full mt-4">
                    <Button
                      type={`${
                        signUpData.email !== "" &&
                        signUpData.password !== "" &&
                        isValid.formIsValid
                          ? "primary"
                          : "disabled"
                      }`}
                      label="Sign in"
                      onPress={signIn}
                    ></Button>
                  </View>
                )}
                <View className="w-full my-4">
                  <A
                    href="forgot-password"
                    label="Forgot password?"
                    className="font-semibold text-app-e text-sm text-center"
                  />
                </View>
                <View className="w-full h-[1px] bg-gray-200 my-2"></View>
                <View className="w-full my-2">
                  <Font className="text-center mb-2">
                    Don't have an account?
                  </Font>
                  <A
                    href="signup"
                    label="Sign up"
                    className="font-semibold text-app-e text-sm text-center"
                  />
                </View>
              </View>
            </View>
          </ScrollView>
        </View>

        {modal.isVisible && (
          <View>
            <ResponseModal
              modalContent={modal.message}
              modalVisible={modal.isVisible}
              setVisible={(e) => setModal({ ...modal, isVisible: e })}
              primaryButtonLabel="Dismiss"
              onPrimaryButtonPress={() => {
                setModal({ isVisible: false, message: null });
              }}
            ></ResponseModal>
          </View>
        )}
      </View>
      {/* <View className="h-full w-1/2 hidden lg:flex bg-app-d"></View> */}
    </View>
  );
};

import React, { createContext, useContext, useEffect, useState } from "react";

export const ProductsContext = createContext();

export const ProductsProvider = ({ children }) => {
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [productDetails, setProductDetails] = useState(null);
  const [productsCategories, setProductsCategories] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [productsApiError, setProductsApiError] = useState(null);
  const [productOutOfStock, setProductOutOfStock] = useState(false);
  const [initialStock, setInitialStock] = useState(0);

  return (
    <ProductsContext.Provider
      value={{
        isProductsLoading,
        products,
        productsCategories,
        featuredProducts,
        reviews,
        productsApiError,
        productDetails,
        cart,
        setCart,
        setIsProductsLoading,
        setProducts,
        setProductsCategories,
        setFeaturedProducts,
        setReviews,
        setProductsApiError,
        setProductDetails,
        productOutOfStock,
        setProductOutOfStock,
        initialStock,
        setInitialStock,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

import React, { useContext, useState } from "react";
import { TextInput, View, Modal } from "react-native";

import { Font } from "../../font";
import { Card } from "../../card";
import CustomTooltip from "../../floatingToolTip";
import { EditGroup } from "../../../services/api";
import { ResponseModal } from "../../response-modal";
import { Button } from "../../input";
import { AuthContext } from "../../../context/authContext";

const EditModal = ({
  navigation,
  userId,
  groupId,
  name,
  description,
  activetab,
  visible,
  onRequestClose,
  animationType = "fade",
  bodyText,
  dismissText,
  checkRefreshList,
}) => {
  React.useEffect(() => {
    setGroup(() => ({
      name: name,
      description: description,
    }));
  }, [name, description]);
  const { userToken } = useContext(AuthContext);
  const [isActiveTab, setIsActiveTab] = useState(activetab);
  const [isLoading, setIsLoading] = useState(false);
  const [isResponseModal, setIsResponseModal] = useState({
    visible: false,
    message: "",
  });
  const [group, setGroup] = useState({
    name: name,
    description: description,
  });
  const changeCredentials = (field, value) => {
    setGroup((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const editGroup = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", group.name);
      formData.append("description", group.description);
      formData.append("is_private", isActiveTab == "Public" ? false : true);

      const res = await EditGroup({
        group_id: groupId,
        formData,
        token: userToken,
      });
      console.log("res.data:", res.data, "res.status:", res.status);
      if (res.status === 200) {
        setIsResponseModal((prev) => ({
          ...prev,
          visible: true,
          message: "Edited Successfully !!!",
        }));
        setTimeout(() => {
          checkRefreshList();
        }, 2000);
        setTimeout(() => {
          navigation.goBack();
        }, 2000);
      } else {
        setIsResponseModal((prev) => ({
          ...prev,
          visible: true,
          message: "Something went wrong !!!",
        }));
        setTimeout(() => {
          navigation.goBack();
        }, 2000);
        checkRefreshList();
      }
    } catch (error) {
      console.log(
        "error:",
        error,
        error?.response?.status,
        error?.response?.data
      );
    } finally {
      setIsLoading(false);
    }
  };
  const closeResponseModal = () => {
    setIsResponseModal((prev) => ({ ...prev, visible: false }));
  };

  return (
    <Modal
      animationType={animationType}
      transparent={true}
      visible={!!visible}
      onRequestClose={onRequestClose}
    >
      <ResponseModal
        visible={isResponseModal.visible}
        onRequestClose={closeResponseModal}
        bodyText={isResponseModal.message}
      ></ResponseModal>
      <View className="w-full h-full flex-col justify-center items-center bg-black/50">
        <View className="w-[90%] lg:w-96 flex-col p-0 pt-0 justify-center items-center bg-white rounded-xl overflow-hidden">
          {/* <Card className="flex-col mb-5"> */}
          <View className="flex-col items-center w-full m-4 mb-0 mt-0 p-4">
            <View className="flex-col w-full">
              <View className="my-3 hidden lg:flex">
                <View className="flex-row  lg:mb-2">
                  <Font className="text-sm font-semibold text-app-d ">
                    Title
                  </Font>
                  <Font className="text-sm text-gray-200 ml-2">(Optional)</Font>
                </View>
                <View className="border-t-2 border-t-gray-200 w-full" />
              </View>
              <View className="w-full flex">
                <TextInput
                  className="w-full border-b border-b-gray-200 lg:border lg:border-gray-200 lg:bg-gray-50 p-2 mb-4 lg:mb-0"
                  placeholder="Group name"
                  placeholderTextColor="#94a3b8"
                  type="text"
                  value={group.name}
                  onChangeText={(value) => changeCredentials("name", value)}
                  style={{ outline: "none" }}
                />
              </View>
            </View>
            <View className="flex-col w-full">
              <View className="my-3 hidden lg:flex">
                <View className="flex-row  lg:mb-2">
                  <Font className="text-sm font-semibold text-app-d ">
                    Description
                  </Font>
                  <Font className="text-sm text-gray-200 ml-2">(Optional)</Font>
                </View>
                <View className="border-t-2 border-t-gray-200 w-full" />
              </View>
              <View className="w-full flex">
                <TextInput
                  className="w-full border-b border-b-gray-200 lg:border lg:border-gray-200 lg:bg-gray-50 p-2 mb-4 lg:mb-0"
                  placeholder="Group Description"
                  placeholderTextColor="#94a3b8"
                  type="text"
                  value={group.description}
                  onChangeText={(value) =>
                    changeCredentials("description", value)
                  }
                  style={{ outline: "none" }}
                />
              </View>
            </View>
          </View>
          {/* </Card> */}

          <View className="mb-8 flex-col-reverse w-full justify-center lg:justify-end px-4">
            <Button
              label="cancel"
              type="regular"
              className="w-full"
              // style={{ flex: 1 }}
              onPress={() => onRequestClose()}
            />

            <Button
              label="Save"
              waitState={isLoading}
              type="primary"
              className="w-full mb-4"
              // style={{ flex: 1 }}
              onPress={() => editGroup()}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default EditModal;

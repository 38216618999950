import { View, Text, Platform } from "react-native";
import React, { useContext } from "react";
import RemixIcon from "react-native-remix-icon";
import { Pressable } from "react-native";
import { TextInput } from "react-native";
import { Image } from "react-native";
import { RefreshControl } from "react-native";
import { ScrollView } from "react-native";
import { cloneDeep } from "lodash";
import moment from "moment";
import { ActivityIndicator } from "react-native";
import { catchAPIErrorText } from "../../utils";
import { ResponseModal } from "./response-modal";
import { Font } from "./font";
import { Button } from "./input";
import { DEV_API } from "../services/api";
import axios from "axios";
import { useSharedContext } from "../context/sharedContext";
import { AuthContext } from "../context/authContext";
import { DefaultImages, UserRoles } from "./predefined-constants";
import { Card } from "./card";
import colors from "../../colors";
import { getFullDateTime } from "../../utils/date-time-util";
import ConfirmModal from "./ConfirmModal";
// import { Font } from "../../components/font";
// import ConfirmModal from "../../components/general/modals/ConfirmModal";
// import colors from "../../../colors";
// import { Card } from "../../components/general/cards/card";
// import { DEV_API, mediaURI } from "../../constants";
// import { fromNow, getFullDateTime } from "../../utils/date-time-util";
// import ResponseModal from "../../components/general/modals/ResponseModal";
// import { Unauthorized } from "../../screens/vendor/unauthorized";
// import { Button } from "../../components/input";
// import { useAuthContext } from "../../contexts/auth-context";
// import { useVendorFeedContext } from "../../contexts/vendor-feed-context";
// import axios from "../../constants/axios-config";
// import { isSignedIn } from "../../actions/auth-actions";
// import { useSharedContext } from "../../contexts/shared-context";
// import { DefaultImages, UserRoles } from "../../constants/predefined-constants";
// import { catchAPIErrorText } from "../../utils/error-handler";

const CommentSection = ({ route, navigation, id, groupId, useId = false }) => {
  //   React.useEffect(() => {
  //     setTabStatus({ curr: "My Feed", prev: tabStatus.curr });
  //   }, []);
  //   console.log(route, "route");
  //   console.log(id, "id vend");

  const pid = useId == true ? id : +route?.params?.slug;

  const { user, userToken } = useContext(AuthContext);
  //   const { user } = useAuthContext();
  const { screen } = useSharedContext();
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const [showConfirmModal, setShowConfirmModal] = React.useState({
    visible: false,
    bodyText: "",
    BodyComponent: "",
    cancelText: "Cancel",
    confirmText: "Confirm",
    onCancel: () => null,
    onConfirm: () => null,
  });
  const [operationQueue, setOperationQueue] = React.useState([]);
  const [inputFor, setInputFor] = React.useState("comment");
  const [replyForCid, setReplyForCid] = React.useState();
  const [commentIndex, setCommentIndex] = React.useState();
  const [replyIndex, setReplyIndex] = React.useState();
  const [isInOperation, setIsInOperation] = React.useState();
  const [vendorFeedCommentsData, setVendorFeedCommentsData] = React.useState();
  const [isFeedCommentsDataFailed, setIsFeedCommentsDataFailed] =
    React.useState();
  const [isEditing, setIsEditing] = React.useState();
  const [text, setText] = React.useState();
  const [commentIsLoading, setcommentIsLoading] = React.useState();
  const [scrollRef, setScrollRef] = React.useState();
  //   const { tabStatus, setTabStatus, vendorFeedComments, setVendorFeedComments } =
  //     useVendorFeedContext();

  const [vendorFeedComments, setVendorFeedComments] = React.useState();
  const handleError = (e) => {
    setShowResponseModal({
      visible: true,
      onRequestClose: () => {
        setShowResponseModal({
          visible: false,
          onRequestClose: () => null,
          bodyText: "",
        });
      },
      bodyText: catchAPIErrorText(e, "Unknown Error while In Vendor Posts"),
    });
  };

  const getVendorFeedComments = async () => {
    setcommentIsLoading(true);
    let data;
    try {
      const token = userToken;
      const res = await axios.get(
        DEV_API + `/groups/${groupId}/posts/${pid}/comments`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        data = res.data.comments;
      }
    } catch (e) {
      console.log("e.res for getting post comment error", e?.response, e);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Getting Feed Comments"
        ),
      });
    } finally {
      setVendorFeedCommentsData(data || []);
      console.log(data, "comment is liked checking");

      setVendorFeedComments({ data, id: pid });
      setcommentIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!isInOperation && operationQueue?.length) {
      let operation;
      setOperationQueue((data) => {
        data = cloneDeep(data);
        [operation] = data.splice(0, 1);
        operation();
        return data;
      });
    }
    getVendorFeedComments();
  }, [isInOperation, operationQueue]);

  //   if (!user?.id || !pid) {
  //     return navigation.replace("vendor-feed");
  //   }

  if (
    // true
    !vendorFeedCommentsData &&
    !vendorFeedComments?.[pid] &&
    !commentIsLoading
  ) {
  } else if (!vendorFeedCommentsData && vendorFeedComments?.[pid]) {
    setVendorFeedCommentsData(vendorFeedComments[pid] || {});
  }

  if (!user) {
    return <Unauthorized />;
  } else if (isFeedCommentsDataFailed) {
    return (
      <View className="flex-1 justify-center items-center bg-gray-50">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <View className="p-4">
          <Font className="text-app-d font-semibold text-base" weight={700}>
            Failed to Load the Data
          </Font>
          <Font className="text-app-d1 py-2">
            Unable to Get Data from the Server
          </Font>
          <Button
            label="Retry"
            type="primary"
            onPress={() => {
              if (!commentIsLoading) {
                getVendorFeedComments();
                setIsFeedCommentsDataFailed(false);
              }
            }}
          />
        </View>
      </View>
    );
  } else if (!vendorFeedComments || !vendorFeedCommentsData) {
    return (
      <View className="flex-1 justify-center items-center bg-gray-50">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <ActivityIndicator
          animating={!vendorFeedComments && !vendorFeedCommentsData}
          size={Platform.OS === "web" ? "small" : "large"}
          color={colors.app.e}
          className="mb-4 shadow-md rounded-full p-2"
        />
      </View>
    );
  }

  console.log(vendorFeedCommentsData, "vendorFeedCommentsData");
  console.log(
    vendorFeedCommentsData[0]?.replies,
    "vendorFeedCommentsData.replies"
  );

  const deleteFeedComment = async (cid, commentIndex) => {
    try {
      setIsInOperation(true);
      const token = userToken;
      const res = await axios.delete(
        DEV_API + `/groups/${groupId}/posts/${pid}/comments/${cid}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("res.status: ", res.status, res.data);
      if (res.status === 204) {
        setVendorFeedCommentsData((data) => {
          if (data?.length) {
            data.splice(commentIndex, 1);
          }
          return cloneDeep(data);
        });
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: "Successfully deleted comment",
        });
        let commentsData = vendorFeedComments[pid];
        commentsData = commentsData.filter((c) => c.id !== cid);
        setVendorFeedComments({ data: commentsData, id: pid });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while Deleting Comment"),
      });
    } finally {
      setIsInOperation(false);
    }
  };

  const deleteFeedCommentReply = async (cid, rid, commentIndex, replyIndex) => {
    try {
      setIsInOperation(true);
      const token = userToken;
      const res = await axios.delete(
        DEV_API +
          `/groups/${groupId}/posts/${pid}/comments/${cid}/replies/${rid}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("res.status: ", res.status, res.data);
      if (res.status === 204) {
        setVendorFeedCommentsData((data) => {
          if (data?.length && data?.replies?.length) {
            data[commentIndex].replies.splice(replyIndex, 1);
          }
          return cloneDeep(data);
        });
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: "Successfully deleted reply",
        });
        let commentsData = vendorFeedComments[pid];
        commentsData[commentIndex].replies = commentsData[
          commentIndex
        ].replies.filter((r) => r.id !== rid);
        setVendorFeedComments({ data: commentsData, id: pid });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Deleting Comment Reply"
        ),
      });
    } finally {
      setIsInOperation(false);
    }
  };

  const updateFeedComment = async (dataObj, commentIndex) => {
    let data;
    try {
      setIsInOperation(true);
      const token = userToken;
      const res = await axios.patch(
        DEV_API + `/groups/${groupId}/posts/${pid}/comments/${dataObj.id}`,

        { message: dataObj.message },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("res.status: ", res.status, res.data);
      if (res.status === 200) {
        data = res.data.post;
        delete dataObj.processText;
        let commentsData = vendorFeedComments[pid];
        const index = commentsData.findIndex((c) => c.id == dataObj.id);
        if (index > -1) commentsData[index] = data;
        setVendorFeedComments({ data: commentsData, id: pid });
        setVendorFeedCommentsData((data1) => {
          data1 = cloneDeep(data1);
          if (data1?.length) {
            // const i = data.findIndex((v) => v.id === dataObj.id);
            data1[commentIndex] = data;
          } else {
            data1 = [data];
          }
          return data1;
        });
        if (res.data?.message) {
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText: res.data.message,
          });
        }
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Updating Review Reply"
        ),
      });
      return e?.response;
    } finally {
      setIsInOperation(false);
    }
  };

  const updateFeedCommentReplies = async (
    dataObj,
    cid,
    commentIndex,
    replyIndex
  ) => {
    let data;
    try {
      console.log(dataObj.id, "dataObj.id for updating replies");

      setIsInOperation(true);
      const token = userToken;
      const res = await axios.patch(
        DEV_API +
          `/groups/${groupId}/posts/${pid}/comments/${cid}/replies/${dataObj.id}`,

        { message: dataObj.message },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("res.status for comment: ", res.status, res.data);
      if (res.status === 200) {
        data = res.data.post;
        delete dataObj.processText;
        let commentsData = vendorFeedComments[pid];
        const index = commentsData[commentIndex].replies.findIndex(
          (c) => c.id == data.id
        );
        if (index > -1) commentsData[commentIndex].replies[index] = data;
        setVendorFeedComments({ data: commentsData, id: pid });
        setVendorFeedCommentsData((data1) => {
          data1 = cloneDeep(data1);
          if (data1[commentIndex].replies?.length) {
            // const i = data[commentIndex].replies.findIndex((v) => v.id === dataObj.id);
            data1[commentIndex].replies[replyIndex] = data;
          } else {
            data1[commentIndex].replies = [data];
          }
          return data1;
        });
        if (res.data?.message) {
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText: res.data.message,
          });
        }
      }
      console.log("e.res for updating replies", res, res.data);
    } catch (e) {
      console.log("e.res for updating replies", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Updating Review Reply"
        ),
      });
      return e?.response;
    } finally {
      setIsInOperation(false);
    }
  };

  const addFeedComment = async (dataObj) => {
    let data;
    try {
      console.log("add feed obj comment check", dataObj);

      setIsInOperation(true);
      const token = userToken;
      const res = await axios.post(
        DEV_API + `/groups/${groupId}/posts/${pid}/comments`,
        { message: dataObj.message },
        {
          headers: {
            Authorization: "Bearer " + token,
            // "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("res.status for comment: ", res.status, res.data);
      if (res.status === 201) {
        data = res.data.comment;
        delete dataObj.processText;
        let commentsData = vendorFeedComments?.[pid];
        commentsData = [...(commentsData || []), data];
        setVendorFeedComments({ data: commentsData, id: pid });
        setVendorFeedCommentsData((values) => {
          values = cloneDeep(values);
          if (!values) {
            values = [];
          }
          values.push(data);
          return values;
        });
        // setShowResponseModal({
        //   visible: true,
        //   onRequestClose: () => {
        //     setShowResponseModal({
        //       visible: false,
        //       onRequestClose: () => null,
        //       bodyText: "",
        //     });
        //   },
        //   bodyText: res.data.message,
        // });
      }
      //   console.log(vendorFeedCommentsData, "vendorFeedCommentsData added comment");
    } catch (e) {
      console.log("e.res for adding comment", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Adding Review Reply"
        ),
      });
      return e?.response;
    } finally {
      setIsInOperation(false);
    }
  };

  const addFeedCommentReply = async (dataObj, cid, commentIndex) => {
    let data;
    try {
      console.log(dataObj, "addFeedCommentReply");

      setIsInOperation(true);
      const token = userToken;
      const res = await axios.post(
        DEV_API + `/groups/${groupId}/posts/${pid}/comments/${cid}/replies`,
        { message: dataObj.message },
        {
          headers: {
            Authorization: "Bearer " + token,
            // "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("res.status: ", res.status, res.data);
      if (res.status === 201) {
        data = res.data.reply;
        delete dataObj.processText;
        let commentsData = vendorFeedComments[pid];
        commentsData[commentIndex].replies = [
          ...(commentsData[commentIndex]?.replies || []),
          data,
        ];
        setVendorFeedComments({ data: commentsData, id: pid });
        setVendorFeedCommentsData((values) => {
          values = cloneDeep(values);
          values[commentIndex].replies = [
            ...(values[commentIndex]?.replies || []),
            data,
          ];
          return values;
        });
        // setShowResponseModal({
        //   visible: true,
        //   onRequestClose: () => {
        //     setShowResponseModal({
        //       visible: false,
        //       onRequestClose: () => null,
        //       bodyText: "",
        //     });
        //   },
        //   bodyText: res.data.message,
        // });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Adding Comment Reply"
        ),
      });
      return e?.response;
    } finally {
      setIsInOperation(false);
    }
  };

  const onSendTextHandler = (replyInput, isEditing, inputFor) => {
    setText("");
    setVendorFeedCommentsData((data) => {
      data = cloneDeep(data);
      if (isEditing) {
        isEditing = {
          ...isEditing,
          message: replyInput,
          is_edited: true,
          processText: "updating...",
        };
        const i = data.replies?.findIndex((v) => v.id === isEditing.id);
        if (i > -1) {
          data.replies[i] = isEditing;
        }
        if (inputFor === "comment") {
          setOperationQueue((q) => {
            q = cloneDeep(q);
            q.push(() => updateFeedComment(isEditing, commentIndex));
            return q;
          });
        } else if (inputFor === "reply") {
          setOperationQueue((q) => {
            q = cloneDeep(q);
            q.push(() =>
              updateFeedCommentReplies(
                isEditing,
                isEditing.comment_id,
                commentIndex,
                replyIndex
              )
            );
            return q;
          });
          setInputFor("comment");
        }
        setIsEditing(undefined);
      } else {
        const dateNow = moment.now();
        let replyObj = {
          id: "reply-local" + dateNow,
          user_id: user.id,
          user_role: user.role,
          user_name: user.first_name + " " + user.last_name,
          user_profile_image_url: user.profile_image_url,
          rating: null,
          message: replyInput,
          is_reply:
            inputFor === "comment" ? false : inputFor === "reply" ? true : null,
          is_edited: false,
          created_at: dateNow,
          updated_at: dateNow,
          processText: "sending...",
        };
        if (!data?.replies) {
          data.replies = [replyObj];
        } else {
          data.replies.push(replyObj);
        }
        if (inputFor === "comment") {
          console.log("comment");

          setOperationQueue((q) => {
            q = cloneDeep(q);
            q.push(() => addFeedComment(replyObj));
            return q;
          });
        } else if (inputFor === "reply") {
          setOperationQueue((q) => {
            q = cloneDeep(q);
            q.push(() =>
              addFeedCommentReply(replyObj, replyForCid, commentIndex)
            );
            return q;
          });
          setInputFor("comment");
        }
      }
      setCommentIndex(undefined);
      setReplyIndex(undefined);
      return data;
    });
  };

  const onDeleteReplyHandler = (
    commentID,
    commentIndex,
    replyID,
    replyIndex
  ) => {
    if (replyIndex > -1) {
      setVendorFeedCommentsData((data) => {
        data = cloneDeep(data);
        data[commentIndex].replies.splice(replyIndex, 1);
        return data;
      });
      setOperationQueue((q) => {
        q = cloneDeep(q);
        q.push(() =>
          deleteFeedCommentReply(commentID, replyID, commentIndex, replyIndex)
        );
        return q;
      });
    }
  };

  const onDeleteCommentHandler = (commentID, commentIndex) => {
    if (commentIndex > -1) {
      setVendorFeedCommentsData((data) => {
        data = cloneDeep(data);
        data.splice(commentIndex, 1);
        return data;
      });
      setOperationQueue((q) => {
        q = cloneDeep(q);
        q.push(() => deleteFeedComment(commentID, commentIndex));
        return q;
      });
    }
  };

  //   console.log(vendorFeedCommentsData, "vendorFeedCommentsData");

  return (
    <>
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      )}
      <ScrollView
        ref={(ref) => setScrollRef(ref)}
        className="px-4 pb-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        showsVerticalScrollIndicator={false}
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={commentIsLoading}
              onRefresh={() => {
                getVendorFeedComments();
              }}
            />
          )
        }
      >
        <View className=" max-w-screen-lg w-full mx-auto h-full">
          <View className="p-0 lg:px-4 flex-1   ">
            <Card title="Comments">
              {vendorFeedCommentsData ? (
                vendorFeedCommentsData?.length ? (
                  vendorFeedCommentsData?.map((comment, index) => (
                    <View
                      className={`w-full pt-4 ${
                        comment?.replies ? "mx-4 border-b border-gray-200" : ""
                      } flex-col`}
                      key={`vfcd_${comment.id}_${index}`}
                    >
                      <View className="flex-row mx-4 pb-4  " key={comment.id}>
                        <View className="justify-start pt-1 items-center px-4 ">
                          <Image
                            source={
                              comment.user_profile_image_url
                                ? {
                                    uri:
                                      mediaURI + comment.user_profile_image_url,
                                  }
                                : DefaultImages.DP_PLACEHOLDER
                            }
                            className="w-12 h-12 lg:w-18 lg:h-18 rounded-full"
                          ></Image>
                        </View>
                        <View className="justify-start ml-2 flex-1 ">
                          <View className="w-full flex-row flex-wrap justify-between items-center pb-0.5">
                            <View className="flex-1 flex-row items-center  flex-wrap ">
                              <Font
                                className="text-app-d text-base mr-2 "
                                weight={600}
                              >
                                {comment?.user_name || "Anonymous User"}
                              </Font>
                              <Font
                                className={`px-1.5 pt-[2px] pb-[2px] text-app-b capitalize rounded-full leading-4 ${
                                  comment?.user_role === UserRoles.CUSTOMER
                                    ? "bg-app-d3 text-app-d"
                                    : "bg-app-e3 text-app-e"
                                }`}
                              >
                                {comment?.user_role || "Unknown"}
                              </Font>
                            </View>
                            <View className="flex-row items-center flex-wrap h-full justify-end self-stretch ">
                              <Pressable
                                className="p-0.5 mr-2"
                                onPress={() => {
                                  setIsEditing(comment);
                                  setCommentIndex(index);
                                  setInputFor("comment");
                                  setText(comment.message);
                                  scrollRef.scrollToEnd();
                                }}
                              >
                                <RemixIcon
                                  name="ri-pencil-fill"
                                  className="self-start"
                                  size={16}
                                  color={colors.app.d2}
                                  // color="white"
                                />
                              </Pressable>
                              <Pressable
                                className="p-0.5"
                                onPress={() => {
                                  setShowConfirmModal((d) => ({
                                    ...d,
                                    visible: true,
                                    BodyComponent: () => (
                                      <Font>
                                        Do you want to delete this comment?
                                      </Font>
                                    ),
                                    onConfirm: () => {
                                      setShowConfirmModal((d1) => {
                                        d1.visible = false;
                                        d1.onConfirm = () => null;
                                        d1.onCancel = () => null;
                                        d1.cancelText = "Cancel";
                                        d1.confirmText = "Confirm";
                                        d1.bodyText = "";
                                        return { ...d1 };
                                      });
                                      onDeleteCommentHandler(comment.id, index);
                                    },
                                    onCancel: () =>
                                      setShowConfirmModal((d1) => {
                                        d1.visible = false;
                                        d1.onConfirm = () => null;
                                        d1.onCancel = () => null;
                                        d1.cancelText = "Cancel";
                                        d1.confirmText = "Confirm";
                                        d1.bodyText = "";
                                        return { ...d1 };
                                      }),
                                  }));
                                }}
                              >
                                <RemixIcon
                                  name="ri-delete-bin-5-fill"
                                  className="self-start"
                                  size={16}
                                  color={colors.app.d2}
                                  // color={"white"}
                                />
                              </Pressable>
                            </View>
                          </View>
                          <View className="flex-row items-center justify-start pb-2">
                            {/* <RemixIcon
                              className="mr-2 self-start h-full"
                              name="ri-discuss-fill"
                              size={16}
                              color={colors.app.d}
                            /> */}
                            <Font
                              numberOfLines={4}
                              className="text-app-d flex-row items-center leading-4 mt-1"
                            >
                              {comment.is_edited && (
                                <>
                                  <RemixIcon
                                    className="pr-2 -mb-px self-start"
                                    name="ri-pencil-fill"
                                    size={12}
                                    color={colors.app.d}
                                  />
                                  &nbsp;
                                </>
                              )}
                              {comment.message}
                            </Font>
                          </View>
                          <View className="flex-row items-center flex-wrap">
                            <Font className="text-app-d1 leading-4 ml-1 mr-2 text-sm">
                              {comment.likes}
                            </Font>
                            <Pressable
                              className="flex-row items-center"
                              onPress={() =>
                                (async () => {
                                  try {
                                    console.log(comment, "comment.is_liked");

                                    const token = userToken;
                                    const res = await axios.post(
                                      DEV_API +
                                        `/groups/${groupId}/posts/${pid}/comments/${comment.id}` +
                                        (comment.is_liked
                                          ? "/unlike"
                                          : "/like"),
                                      {},
                                      {
                                        headers: {
                                          Authorization: "Bearer " + token,
                                        },
                                      }
                                    );
                                    console.log(
                                      "res.status: ",
                                      res.status,
                                      res.data,
                                      res
                                    );
                                    if (res.status === 200) {
                                      setVendorFeedCommentsData((data) => {
                                        const is_liked = comment.is_liked;
                                        data[index].is_liked = is_liked
                                          ? false
                                          : true;
                                        if (is_liked) {
                                          data[index].likes--;
                                        } else {
                                          data[index].likes++;
                                        }
                                        return cloneDeep(data);
                                      });
                                    }
                                  } catch (e) {
                                    console.log(
                                      "e.res",
                                      e?.response,
                                      e,
                                      JSON.stringify(e, null, 1)
                                    );
                                    handleError(e);
                                  }
                                })()
                              }
                            >
                              <RemixIcon
                                name="ri-thumb-up-fill"
                                // className=""
                                size={14}
                                color={
                                  comment.is_liked
                                    ? colors.app.f
                                    : colors.app.d1
                                }
                              />
                              <Font className="text-app-d1 leading-4 ml-1 mr-2 text-sm">
                                Like
                              </Font>
                            </Pressable>
                            <Font className="text-app-d1 leading-4 text-xs">
                              •
                            </Font>
                            {/* <Font className="text-app-d1 leading-4 ml-1 mr-2 text-sm">
                              {comment.replies}
                            </Font> */}
                            <Pressable
                              className="flex-row items-center"
                              onPress={() => {
                                setInputFor("reply");
                                setCommentIndex(index);
                                setReplyForCid(comment.id);
                                scrollRef.scrollToEnd();
                              }}
                            >
                              <RemixIcon
                                name="ri-reply-fill"
                                size={14}
                                color={colors.app.d1}
                                className="ml-2"
                              />
                              <Font className="text-app-d1 leading-4 ml-1 mr-2 text-sm">
                                Reply
                              </Font>
                            </Pressable>
                            <Font className="text-app-d1 leading-4 text-xs">
                              •
                            </Font>
                            <Font className="text-app-d1 leading-4 ml-2 text-sm">
                              {getFullDateTime(comment.updated_at, null, {
                                fromNow: true,
                              })}
                            </Font>
                          </View>
                        </View>
                      </View>
                      {!!comment?.replies?.length && (
                        <View
                          style={{
                            flex: 1,
                            justifyContent: "flex-start",
                            flexDirection: "row",
                            alignItems: "flex-start",
                          }}
                          // className="flex-row mx-4  flex-1 items-start justify-start overflow-auto bg-red-400 "
                        >
                          <View className="pt-1 px-1 lg:px-4 w-max  ">
                            <Font className="text-app-a bg-app-d w-max py-1 px-2 border border-app-d2 rounded ">
                              {comment?.replies?.length || "-"}{" "}
                              {comment?.replies?.length === 1
                                ? "Reply"
                                : "Replies"}
                            </Font>
                          </View>
                          <View className="flex-col items-center justify-start flex-1">
                            {comment.replies.map((reply, i) => (
                              <View
                                className={`py-4 pr-4 w-full ${
                                  i === 0 ? "" : "border-t border-gray-200"
                                } flex-row`}
                                key={`comment${reply.id}_${comment.id}_${index}`}
                              >
                                <View className="justify-start pt-1 items-center px-2 md:px-4">
                                  <Image
                                    source={
                                      reply.user_profile_image_url
                                        ? {
                                            uri:
                                              mediaURI +
                                              reply.user_profile_image_url,
                                          }
                                        : DefaultImages.DP_PLACEHOLDER
                                    }
                                    className="w-12 h-12 lg:w-18 lg:h-18 rounded-full"
                                  ></Image>
                                </View>
                                <View className="justify-start ml-2 flex-1  ">
                                  <View className="flex-row flex-wrap justify-between items-center pb-0.5">
                                    <View className="flex-1 flex-row flex-wrap items-center">
                                      <Font
                                        className="text-app-d text-base mr-2"
                                        weight={600}
                                      >
                                        {reply?.user_name || "Anonymous User"}
                                      </Font>
                                      <Font
                                        className={`px-1.5 pt-[2px] pb-[2px] text-app-b capitalize rounded-full leading-4 ${
                                          reply?.user_role ===
                                          UserRoles.CUSTOMER
                                            ? "bg-app-d3 text-app-d"
                                            : "bg-app-e3 text-app-e"
                                        }`}
                                      >
                                        {reply.user_role || "Unknown"}
                                      </Font>
                                    </View>
                                    <View className="flex-row items-center flex-wrap h-full justify-end self-stretch">
                                      <Pressable
                                        className="p-0.5 mr-2"
                                        onPress={() => {
                                          reply.comment_id = comment.id;
                                          setIsEditing(reply);
                                          setReplyForCid(comment.id);
                                          setCommentIndex(index);
                                          setReplyIndex(i);
                                          setInputFor("reply");
                                          setText(reply.message);
                                          scrollRef.scrollToEnd();
                                        }}
                                      >
                                        <RemixIcon
                                          name="ri-pencil-fill"
                                          className="self-start"
                                          size={16}
                                          color={colors.app.d2}
                                        />
                                      </Pressable>
                                      <Pressable
                                        className="p-0.5"
                                        onPress={() => {
                                          setShowConfirmModal((d) => ({
                                            ...d,
                                            visible: true,
                                            BodyComponent: () => (
                                              <Font>
                                                Do you want to delete this
                                                reply?
                                              </Font>
                                            ),
                                            onConfirm: () => {
                                              setShowConfirmModal((d1) => {
                                                d1.visible = false;
                                                d1.onConfirm = () => null;
                                                d1.onCancel = () => null;
                                                d1.cancelText = "Cancel";
                                                d1.confirmText = "Confirm";
                                                d1.bodyText = "";
                                                return { ...d1 };
                                              });
                                              onDeleteReplyHandler(
                                                comment.id,
                                                index,
                                                reply.id,
                                                i
                                              );
                                            },
                                            onCancel: () =>
                                              setShowConfirmModal((d1) => {
                                                d1.visible = false;
                                                d1.onConfirm = () => null;
                                                d1.onCancel = () => null;
                                                d1.cancelText = "Cancel";
                                                d1.confirmText = "Confirm";
                                                d1.bodyText = "";
                                                return { ...d1 };
                                              }),
                                          }));
                                        }}
                                      >
                                        <RemixIcon
                                          name="ri-delete-bin-5-fill"
                                          className="self-start"
                                          size={16}
                                          color={colors.app.d2}
                                        />
                                      </Pressable>
                                    </View>
                                  </View>
                                  {/* <View className="flex-row items-center pb-2">
                                    <Font className="text-app-d1 leading-4">
                                      {reply.processText
                                        ? reply.processText
                                        : fromNow({
                                            date: reply.updated_at,
                                            fromNow,
                                          })}
                                    </Font>
                                  </View> */}
                                  <View className="flex-row items-center pb-2">
                                    <RemixIcon
                                      name="ri-reply-fill"
                                      size={14}
                                      color={colors.app.d}
                                      className="mr-2"
                                    />
                                    <Font
                                      numberOfLines={4}
                                      className="text-app-d flex flex-row items-center flex-1 leading-4 mt-1"
                                    >
                                      {reply.is_edited && (
                                        <>
                                          <RemixIcon
                                            className="self-center"
                                            name="ri-pencil-fill"
                                            size={12}
                                            color={colors.app.d}
                                          />
                                          &nbsp;
                                        </>
                                      )}
                                      {reply.message}
                                    </Font>
                                  </View>
                                  <View className="flex-row items-center flex-wrap">
                                    <Font className="text-app-d1 leading-4 ml-1 mr-2 text-sm">
                                      {reply.likes}
                                    </Font>
                                    <Pressable
                                      className="flex-row items-center"
                                      onPress={() =>
                                        (async () => {
                                          try {
                                            console.log(
                                              reply,
                                              "reply.is_liked"
                                            );
                                            console.log(
                                              reply.is_liked,
                                              "reply.is_liked"
                                            );

                                            const token = userToken;
                                            const res = await axios.post(
                                              DEV_API +
                                                `/groups/${groupId}/posts/${pid}/comments/${comment.id}/replies/${reply.id}` +
                                                (reply.is_liked
                                                  ? "/unlike"
                                                  : "/like"),

                                              {},
                                              {
                                                headers: {
                                                  Authorization:
                                                    "Bearer " + token,
                                                },
                                              }
                                            );
                                            console.log(
                                              "res.status like unlike replies: ",
                                              res.status,
                                              res.data
                                            );
                                            if (res.status === 200) {
                                              setVendorFeedCommentsData(
                                                (data) => {
                                                  const is_liked =
                                                    reply?.is_liked;
                                                  data[index].replies[
                                                    i
                                                  ].is_liked = is_liked
                                                    ? false
                                                    : true;
                                                  if (is_liked) {
                                                    data[index].replies[i]
                                                      .likes--;
                                                  } else {
                                                    data[index].replies[i]
                                                      .likes++;
                                                  }
                                                  return cloneDeep(data);
                                                }
                                              );
                                            }
                                          } catch (e) {
                                            console.log(
                                              "e.res for like unlike replies",
                                              e?.response,
                                              e,
                                              JSON.stringify(e, null, 1)
                                            );
                                            handleError(e);
                                          }
                                        })()
                                      }
                                    >
                                      <RemixIcon
                                        name="ri-thumb-up-fill"
                                        // className=""
                                        size={14}
                                        color={
                                          reply.is_liked
                                            ? colors.app.f
                                            : colors.app.d1
                                        }
                                      />
                                      <Font className="text-app-d1 leading-4 ml-1 mr-2 text-sm">
                                        Like
                                      </Font>
                                    </Pressable>
                                    {/* <Font className="text-app-d1 leading-4 text-xs">
                                      •
                                    </Font>
                                    <Font className="text-app-d1 leading-4 mx-2 text-sm">
                                      Reply
                                    </Font> */}
                                    <Font className="text-app-d1 leading-4 text-xs">
                                      •
                                    </Font>
                                    <Font className="text-app-d1 leading-4 ml-2 text-sm">
                                      {getFullDateTime(reply.updated_at, null, {
                                        fromNow: true,
                                      })}
                                    </Font>
                                  </View>
                                </View>
                              </View>
                            ))}
                          </View>
                        </View>
                      )}
                    </View>
                  ))
                ) : (
                  <View className="flex-1 justify-center items-center pb-8">
                    <Font className="text-app-d1">
                      Reviews on this Post Not Found
                    </Font>
                  </View>
                )
              ) : (
                <View className="flex-1 justify-center items-center pb-8">
                  <Font className="text-app-d">Loading...</Font>
                </View>
              )}

              <View className="h-18 w-full py-4 mt-16 pl-4 pr-1 flex-row items-center border-t rounded-b-lg lg:bg-app-d/95 border-gray-200">
                <View className="flex-1 mr-1 ">
                  {/* <Input type="text" placeholder="Type your message"></Input> */}
                  <View
                    className={`rounded-lg border border-gray-200 overflow-hidden flex-row  ${
                      text?.length > 100 ? "h-20" : "h-10"
                    } bg-app-d lg:bg-app-a`}
                  >
                    {!!isEditing && (
                      <RemixIcon
                        className="self-center"
                        name="ri-pencil-fill"
                        size={12}
                        color={
                          screen?.width > 1023 ? colors.app.d : colors.app.a
                        }
                      />
                    )}
                    <TextInput
                      placeholder={`Write a ${inputFor}...`}
                      className={`w-full ${
                        Platform.OS === "web" ? "flex-1 py-2.5" : "h-full"
                      } px-3 min-w-0 bg-app-a/95 lg:bg-app-d/95 lg:text-app-a`}
                      style={
                        Platform.OS === "web" && {
                          outlineColor: "transparent",
                        }
                      }
                      multiline={true}
                      textAlignVertical="center"
                      value={text}
                      onChangeText={(e) => setText(e)}
                      onKeyPress={(e) => {
                        if (!e.shiftKey && ["Enter"].includes(e.code)) {
                          e.preventDefault();
                          onSendTextHandler(text, isEditing, inputFor);
                        }
                      }}
                    ></TextInput>
                  </View>
                </View>
                {(!!isEditing || inputFor === "reply") && (
                  <Pressable
                    className={`px-4 py-2 mr-1 self-end items-center justify-center rounded-lg ${
                      Platform.OS === "web"
                        ? "active:bg-app-c2"
                        : "active:bg-app-d3"
                    }`}
                    onPress={() => {
                      if (isEditing) {
                        setText("");
                        setIsEditing();
                      }
                      if (inputFor === "reply") {
                        setInputFor("comment");
                        setReplyForCid(undefined);
                      }
                    }}
                  >
                    <RemixIcon
                      name="ri-close-circle-fill"
                      color={screen?.width > 1023 ? colors.app.a : colors.app.d}
                    ></RemixIcon>
                  </Pressable>
                )}
                <Pressable
                  className={`pl-4 pr-4 py-2 self-end items-center justify-center rounded-lg ${
                    Platform.OS === "web"
                      ? "active:bg-app-c2"
                      : "active:bg-app-d3"
                  }`}
                  onPress={() => {
                    if (text) onSendTextHandler(text, isEditing, inputFor);
                  }}
                >
                  <RemixIcon
                    name="send-plane-2-line"
                    color={screen?.width > 1023 ? colors.app.a : colors.app.d}
                  ></RemixIcon>
                </Pressable>
              </View>
            </Card>
          </View>
        </View>
      </ScrollView>
      {showConfirmModal.visible && <ConfirmModal {...showConfirmModal} />}
    </>
  );
};

export default CommentSection;

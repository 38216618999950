import React, { useContext, useEffect, useState } from "react";
import { Image, Pressable, Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { BusinessesContext } from "../context/businessesContext";
import { ProductsContext } from "../context/productsContext";
import { DEV_API } from "../services/api";
import { Font } from "./font";

export const Gallery = (props) => {
  const { businessDetails } = useContext(BusinessesContext);
  const { productDetails, productOutOfStock } = useContext(ProductsContext);
  const [image, setImage] = useState(0);
  let images;
  if (
    props.product &&
    productDetails !== null &&
    productDetails?.variants[0].gallery_images.length > 0
  ) {
    images = [...productDetails.variants[0].gallery_images];
  } else if (
    !props.product &&
    businessDetails !== null &&
    businessDetails.gallery_images.length > 0
  ) {
    images = [...businessDetails.gallery_images];
  } else {
    if (props.product) {
      images = [
        require("../../assets/water.jpg"),
        require("../../assets/water.jpg"),
      ];
    } else {
      images = [require("../../assets/bg.jpg"), require("../../assets/bg.jpg")];
    }
  }
  useEffect(() => {
    let ci = 0;
    setInterval(() => {
      ci = ci < images.length - 1 ? ci + 1 : 0;
      setImage(ci);
    }, 5000);
  }, []);
  return (
    <View className="flex-col lg:flex-row" style={props.style}>
      <View className="flex-1 h-auto lg:h-full rounded-lg overflow-hidden bg-app-c1">
        <Image
          source={
            props.product &&
            productDetails !== null &&
            productDetails?.variants?.[0].gallery_images.length > 0
              ? { uri: `${DEV_API}/media/${images[image].image_url}` }
              : businessDetails !== null &&
                businessDetails.gallery_images.length > 0
              ? { uri: `${DEV_API}/media/${images[image].image_url}` }
              : images[image]
          }
          className="w-full h-full object-cover"
        ></Image>
        {image !== null && productOutOfStock ? (
          <View className="absolute inset-0 flex justify-center">
            <View className="bg-app-e px-4 py-2 flex items-center">
              <Font className="text-white lg:text-2xl font-bold">
                Out of Stock
              </Font>
            </View>
          </View>
        ) : null}
      </View>
      <View className="w-full lg:w-28 h-20 lg:h-full">
        <ScrollView
          className="w-full h-full hidden lg:flex"
          showsHorizontalScrollIndicator={false}
        >
          {images.map((e, i) => (
            <Pressable
              className="pb-1 pl-1 w-full h-24"
              onPress={() => setImage(i)}
              key={i}
            >
              <View className="flex-1 overflow-hidden rounded-lg bg-app-c1">
                <Image
                  source={
                    props.product &&
                    productDetails !== null &&
                    productDetails?.variants[0].gallery_images.length > 0
                      ? { uri: `${DEV_API}/media/${e.image_url}` }
                      : businessDetails !== null &&
                        businessDetails.gallery_images.length > 0
                      ? { uri: `${DEV_API}/media/${e.image_url}` }
                      : e
                  }
                  className="w-full h-full object-cover"
                ></Image>
                {image === i ? (
                  <View className="absolute w-full h-full left-0 right-0 bg-app-d1"></View>
                ) : null}
              </View>
            </Pressable>
          ))}
        </ScrollView>
        <ScrollView
          className="w-full h-full flex lg:hidden"
          horizontal
          showsHorizontalScrollIndicator={false}
        >
          {images.map((e, i) => (
            <Pressable
              className="pr-1 pt-1 w-20 h-full "
              onPress={() => setImage(i)}
              key={i}
            >
              <View className="flex-1 overflow-hidden rounded-lg bg-app-c1">
                <Image
                  source={
                    props.product &&
                    productDetails !== null &&
                    productDetails?.variants[0].gallery_images.length > 0
                      ? { uri: `${DEV_API}/media/${e.image_url}` }
                      : businessDetails !== null &&
                        businessDetails.gallery_images.length > 0
                      ? { uri: `${DEV_API}/media/${e.image_url}` }
                      : e
                  }
                  className="w-full h-full object-cover"
                ></Image>

                {image === i ? (
                  <View className="absolute w-full h-full left-0 right-0 bg-app-d1"></View>
                ) : null}
              </View>
            </Pressable>
          ))}
        </ScrollView>
      </View>
    </View>
  );
};

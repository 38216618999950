import React, { useContext } from "react";
import { View } from "react-native";
import { ProductsContext } from "../context/productsContext";
import { Font } from "./font";

const CartItemBadge = () => {
  const { cart } = useContext(ProductsContext);
  const totalCartQuantity = cart.reduce(
    (total, cartItem, i) => total + cartItem.quantity,
    0
  );

  if (totalCartQuantity > 0) {
    return (
      <>
        <View className="bg-app-a border border-app-e rounded-full h-[18px] w-[18px] justify-center items-center absolute top-1 right-0 flex lg:hidden">
          <Font className="text-app-e text-xs tracking-wider" weight={600}>
            {totalCartQuantity}
          </Font>
        </View>
        <View className="bg-app-e border border-app-a rounded-full h-[18px] w-[18px] justify-center items-center absolute top-1 right-0 hidden lg:flex">
          <Font className="text-app-a text-xs tracking-wider" weight={600}>
            {totalCartQuantity}
          </Font>
        </View>
      </>
    );
  }
  return null;
};

export default CartItemBadge;

import { View, Image, Pressable, ScrollView } from "react-native";
import { Font } from "../../components/font";
import { Input } from "../../components/input";
import { useContext, useState } from "react";
import { Card } from "../../components/card";
import RemixIcon from "react-native-remix-icon";
import { DEV_API, axiosDelete, axiosGet, axiosPost } from "../../services/api";
import { AuthContext } from "../../context/authContext";
import { getFullDate, getFullDateTime } from "../../../utils/date-time-util";
import colors from "../../../colors";
import Toast from "react-native-toast-message";
import { useEffect } from "react";
import { Unautorized } from "./unautorized";
import { Widget } from "../../components/widget";
import { Footer } from "../../components/footer";
import { ActivityIndicator } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native";

export function Comments({ navigation, route }) {
  const { user, userToken, isAuthLoading } = useContext(AuthContext);
  const [comment, setComment] = useState(null);
  const [replyToAComment, setReplyToAComment] = useState("");
  const [comments, setComments] = useState([]);
  const [commentsPagination, setCommentsPagination] = useState({
    to: null,
    total: null,
  });

  const [userComments, setUserComments] = useState([]);
  const [toggleUserComments, setToggleUserComments] = useState(false);
  const [isCommentsLoading, setIsCommentsLoading] = useState(false);
  const [isDeleteCommentLoading, setIsDeleteCommentLoading] = useState({
    id: null,
    isLoading: false,
  });
  const [showCommentId, setShowCommentId] = useState({
    id: null,
    showReplies: false,
    showAddComment: false,
  });
  const [showRepliesToACommentId, setShowRepliesToACommentId] = useState(null);
  const commentId = 101;

  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const commentsPerPage = 10;

  const getCommentsForAPost = async () => {
    setIsCommentsLoading(true);
    await axiosGet({
      Url: `/vendors/${route?.params?.vendor_id}/posts/${route?.params?.post_id}/comments`,
      token: userToken,
      params: {
        offset: commentsPagination.to === null ? 0 : commentsPagination.to + 1,
        limit:
          commentsPagination.total === null
            ? commentsPerPage
            : commentsPagination.to + commentsPerPage <=
              commentsPagination.total
            ? commentsPerPage
            : commentsPagination.total,
      },
    })
      .then((data) => {
        setCommentsPagination({
          to: comments?.length + data.data.comments.length,
          total: data.data.total_count,
        });
        setComments((e) => [...e, ...data.data.comments].reverse());
        setUserComments([...data.data.user_comments].reverse());
        setToggleUserComments(data.data.user_comments?.length > 5 ? true : false)
      })
      .catch((err) => {
        console.log("vendor, getCommentsForAPost error: ", err);
      })
      .finally(() => {
        setIsCommentsLoading(false);
      });
  };
  const onDeleteComment = async (comment_id) => {
    setIsDeleteCommentLoading({ id: comment_id, isLoading: true });
    await axiosDelete({
      Url: `/vendors/${route?.params?.vendor_id}/posts/${route?.params?.post_id}/comments/${comment_id}`,
      token: userToken,
    })
      .then((data) => {
        const array = userComments.filter((e, i) => e.id !== comment_id);
        setUserComments(array);
        showToast("success", "Success", "Successful.");
      })
      .catch((err) => {
        showToast("error", "Error", "Something went wrong.");
        console.log("comments, onDeleteComment error: ", err);
      })
      .finally(() => {
        setIsDeleteCommentLoading({ id: null, isLoading: false });
      });
  };
  useEffect(() => {
    getCommentsForAPost();
  }, []);

  const onSendComment = async () => {
    if (comment && comment !== "") {
      await axiosPost({
        Url: `/vendors/${route?.params?.vendor_id}/posts/${route?.params?.post_id}/comments`,
        token: userToken,
        data: { message: comment },
      })
        .then((data) => {
          const array = [...comments];
          const commentData = { ...data.data.comment };
          delete commentData.post;
          array.unshift(commentData);
          setComments(array);
          setComment(null);
          showToast("success", "Success", "Successful.");
        })
        .catch((err) => {
          showToast("error", "Error", "Something went wrong.");
          console.log("comments, onSendCommentHandler error: ", err);
        })
        .finally(() => {});
    }
  };
  const onSendReplyToAComment = async (commentId) => {
    if (replyToAComment && replyToAComment !== "") {
      await axiosPost({
        Url: `/vendors/${route?.params?.vendor_id}/posts/${route?.params?.post_id}/comments/${commentId}/replies`,
        token: userToken,
        data: { message: replyToAComment },
      })
        .then((data) => {
          const array = [...comments];
          const index = array.findIndex((e) => e.id === commentId);
          if (index > -1) {
            array[index].replies.unshift(data.data.reply);
          }
          setComments(array);
          showToast("success", "Success", "Successful.");
        })
        .catch((err) => {
          showToast("error", "Error", "Something went wrong.");
          console.log("comments, onSendCommentHandler error: ", err);
        })
        .finally(() => {});
    }
  };

  const onLikeAComment = async ({ id, type }) => {
    await axiosPost({
      Url: `/vendors/${route?.params?.vendor_id}/posts/${route?.params?.post_id}/comments/${id}/${type}`,
      token: userToken,
      data: {},
    })
      .then((data) => {
        const index = comments?.findIndex((e) => e.id === id);
        if (index > -1) {
          let post = [...comments];
          post[index].is_liked = type === "like" ? true : false;
          post[index].likes =
            type === "like" ? post[index].likes + 1 : post[index].likes - 1;
          setComments(post);
          showToast("success", "Success", "Success.");
        }
      })
      .catch((err) => {
        showToast("error", "Error", "Something went wrong.");
        console.log("comments, onLikeAComment error: ", err);
      })
      .finally(() => {});
  };

  const onLoadMore = () => {
    getCommentsForAPost();
  };

  return user ? (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="rounded-t-2xl lg:rounded-none overflow-hidden flex-1 bg-gray-50">
        <ScrollView
          className="p-4 rounded-t-2xl"
          // refreshControl={
          //   <RefreshControl
          //     refreshing={refreshing}
          //     onRefresh={() => {
          //       getWishlist();
          //     }}
          //   />
          // }
        >
          <View className="flex-1 w-full justify-center items-center">
            <View className="flex-col w-full lg:w-11/12 lg:px-4">
              <View className="my-5 hidden lg:flex">
                <Font className="text-xl font-semibold text-app-d">
                  Comments
                </Font>
              </View>
              <View className="w-full flex-col lg:flex-row">
                <View className="w-full flex-1">
                  {userComments && userComments.length > 0 ? (
                    <Card title={`Your comments`} className="flex-1 w-full">
                      <ScrollView className="flex-1 w-full px-3 py-0">
                        {(toggleUserComments
                          ? [...userComments.slice(0, 5)]
                          : userComments
                        ).map((e, i) => (
                          <View
                            className="w-full flex-row justify-between items-center bg-app-b rounded-lg py-2"
                            key={`user_comments${i}`}
                          >
                            <View className="flex-row self-stretch justify-start items-start rounded-full mr-3">
                              <Image
                                // resizeMode="contain"
                                source={
                                  e.user_profile_image_url &&
                                  e.user_profile_image_url !== ""
                                    ? {
                                        uri: `${DEV_API}/media/${e.user_profile_image_url}`,
                                      }
                                    : require("../../../assets/user1.jpg")
                                }
                                className="w-10 h-10 rounded-full"
                              ></Image>
                            </View>
                            <View className="flex-1 flex-col rounded-md bg-app-c2">
                              <View className="w-full flex-row justify-between px-3 pt-1">
                                <Font className="py-1 font-bold">
                                  {e.user_name}
                                </Font>
                                <TouchableOpacity
                                  className="px-2 py-1 bg-app-e3 rounded-md"
                                  onPress={() => {
                                    onDeleteComment(e.id);
                                  }}
                                >
                                  {/* <Font className="text-xs text-app-e">
                                    Delete comment
                                  </Font> */}
                                  {isDeleteCommentLoading.id === e.id &&
                                  isDeleteCommentLoading.isLoading ? (
                                    <ActivityIndicator
                                      size={"small"}
                                      color={colors.app.e}
                                    ></ActivityIndicator>
                                  ) : (
                                    <AntDesign
                                      name="delete"
                                      size={20}
                                      color={colors.app.e}
                                    />
                                  )}
                                </TouchableOpacity>
                              </View>
                              <View className="w-full pt-1 px-3">
                                <Font className="text-xs text-app-d1">
                                  {getFullDateTime(e.created_at)}
                                </Font>
                              </View>
                              <View className="w-full p-3">
                                <Font>{e.message}</Font>
                              </View>
                              <View className="w-full flex-row items-center px-3 py-1 border-b border-app-c">
                                <View className="w-1/2 flex-row">
                                  <Font className="text-xs pr-2 text-app-d1">
                                    {e.likes}
                                  </Font>
                                  <Pressable
                                    onPress={() =>
                                      onLikeAComment({
                                        id: e.id,
                                        type: e.is_liked ? "unlike" : "like",
                                      })
                                    }
                                    className="flex-row items-center"
                                  >
                                    <RemixIcon
                                      name={
                                        e.is_liked
                                          ? "ri-thumb-up-fill"
                                          : "ri-thumb-up-line"
                                      }
                                      size={11}
                                      color={
                                        e.is_liked
                                          ? colors.app.f
                                          : colors.app.d1
                                      }
                                    />
                                  </Pressable>
                                </View>
                                <View className="w-1/2 flex-row">
                                  <Font className="text-xs pr-2 text-app-d1">
                                    {e.replies?.length}
                                  </Font>
                                  <TouchableOpacity
                                    onPress={() =>
                                      setShowCommentId((prev) => ({
                                        ...prev,
                                        id:
                                          prev.id === e.id && prev.showReplies
                                            ? null
                                            : prev.id === e.id &&
                                              prev.showAddComment
                                            ? e.id
                                            : e.id,
                                        showReplies:
                                          prev.id === e.id && prev.showReplies
                                            ? false
                                            : prev.id === e.id &&
                                              !prev.showReplies
                                            ? true
                                            : true,
                                        showAddComment: false,
                                      }))
                                    }
                                  >
                                    <Font className="text-xs pr-2 text-app-d">
                                      Replies
                                    </Font>
                                  </TouchableOpacity>
                                </View>
                              </View>
                              <View className="w-full flex-col ">
                                {/* ---------------------- replies to a comment ------------------------ */}
                                {showCommentId.id === e.id &&
                                  showCommentId.showReplies &&
                                  e.replies?.length > 0 &&
                                  e.replies?.reverse()?.map((ee, i) => (
                                    <View
                                      className="w-full flex-row justify-between items-center p-2 border-b border-app-c"
                                      key={`user_comment_replies${i}`}
                                    >
                                      <View className="flex-row justify-start overflow-hidden rounded-full pb-4 mr-3">
                                        <Image
                                          // resizeMode="contain"
                                          source={require("../../../assets/user1.jpg")}
                                          className="w-10 h-10 rounded-full"
                                        ></Image>
                                      </View>
                                      <View className="w-full flex-1">
                                        <View className="w-full flex-row px-3 pt-1">
                                          <Font className="w-full py-1 font-bold">
                                            {ee.user_name}
                                          </Font>
                                        </View>
                                        <View className="w-full pt-1 px-3">
                                          <Font className="text-xs text-app-d1">
                                            {getFullDateTime(ee.created_at)}
                                          </Font>
                                        </View>
                                        <View className="w-full flex-1">
                                          <View className="w-full flex-row bg-app-c2 p-3 rounded-md">
                                            <Font>{ee.message}</Font>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  ))}
                              </View>
                            </View>
                          </View>
                        ))}
                      </ScrollView>
                      {userComments.length > 5 && (
                        <View className="w-full flex-row justify-center">
                          <TouchableOpacity
                            onPress={() => setToggleUserComments((e) => !e)}
                            className="bg-app-e w-32 py-2 rounded-lg"
                          >
                            <Font className="text-app-a text-center">
                              {toggleUserComments ? "Show more" : "Show less"}
                            </Font>
                          </TouchableOpacity>
                        </View>
                      )}
                    </Card>
                  ) : (
                    <Card title={`Add comment`} className="flex-1 w-full">
                      <View className="w-full flex-col sm:flex-row justify-between items-center px-3">
                        <View className="flex-row items-center justify-center">
                          <Input
                            type="text"
                            value={comment}
                            setValue={(e) => {
                              setComment(e);
                            }}
                            // label="Email"
                            placeholder="Comment..."
                            // validation={true}
                            // isValid={(e) => setIsInputValid({ e, type: "email" })}
                          ></Input>
                          <Pressable
                            className="bg-app-c h-10 px-3 flex-row items-center border border-app-c"
                            onPress={onSendComment}
                          >
                            <RemixIcon name="ri-send-plane-2-line" size={15} />
                          </Pressable>
                        </View>
                      </View>
                    </Card>
                  )}

                  <Card title={`All comment`} className="flex-1 w-full">
                    {comments.length > 0 && !isCommentsLoading ? (
                      <View className="flex-1 w-full rounded-2xl">
                        {comments.map((e, i) => (
                          <View
                            className="w-full flex-row justify-between items-center bg-app-b rounded-lg p-2"
                            key={`comment_${i}`}
                          >
                            <View className="flex-row self-stretch justify-start items-start rounded-full mr-3">
                              <Image
                                // resizeMode="contain"
                                source={
                                  e.user_profile_image_url &&
                                  e.user_profile_image_url !== ""
                                    ? {
                                        uri: `${DEV_API}/media/${e.user_profile_image_url}`,
                                      }
                                    : require("../../../assets/user1.jpg")
                                }
                                className="w-10 h-10 rounded-full"
                              ></Image>
                            </View>
                            <View className="flex-1 flex-col rounded-md bg-app-c2">
                              <View className="w-full flex-row px-3 pt-1">
                                <Font className="py-1 font-bold">
                                  {e.user_name}
                                </Font>
                                {e.user_role === "vendor" && (
                                  <Font className="ml-2 p-1 bg-app-d rounded-md text-app-a text-xs">
                                    {e.user_role}
                                  </Font>
                                )}
                              </View>
                              <View className="w-full pt-1 px-3">
                                <Font className="text-xs text-app-d1">
                                  {getFullDateTime(e.created_at)}
                                </Font>
                              </View>
                              <View className="w-full p-3">
                                <Font>{e.message}</Font>
                              </View>
                              <View className="w-full flex-row items-center px-3 py-1 border-b border-app-c">
                                <View className="w-1/3 flex-row">
                                  <Font className="text-xs pr-2 text-app-d1">
                                    {e.likes ?? 10}
                                  </Font>
                                  <TouchableOpacity
                                    onPress={() =>
                                      onLikeAComment({
                                        id: e.id,
                                        type: e.is_liked ? "unlike" : "like",
                                      })
                                    }
                                    className="flex-row items-center"
                                  >
                                    <RemixIcon
                                      name={
                                        e.is_liked
                                          ? "ri-thumb-up-fill"
                                          : "ri-thumb-up-line"
                                      }
                                      size={11}
                                      color={
                                        e.is_liked
                                          ? colors.app.f
                                          : colors.app.d1
                                      }
                                    />
                                  </TouchableOpacity>
                                </View>
                                <View className="w-1/3 flex-row">
                                  <TouchableOpacity
                                    onPress={() =>
                                      setShowCommentId((prev) => ({
                                        ...prev,
                                        id:
                                          prev.id === e.id &&
                                          prev.showAddComment
                                            ? null
                                            : prev.id === e.id &&
                                              prev.showReplies
                                            ? e.id
                                            : e.id,
                                        showAddComment:
                                          prev.id === e.id &&
                                          prev.showAddComment
                                            ? false
                                            : true,
                                        showReplies: false,
                                      }))
                                    }
                                  >
                                    <Font className="text-xs pr-2 text-app-d">
                                      Reply
                                    </Font>
                                  </TouchableOpacity>
                                </View>
                                <View className="w-1/3 flex-row">
                                  <Font className="text-xs pr-2 text-app-d1">
                                    {e.replies?.length}
                                  </Font>
                                  <TouchableOpacity
                                    onPress={() =>
                                      setShowCommentId((prev) => ({
                                        ...prev,
                                        id:
                                          prev.id === e.id && prev.showReplies
                                            ? null
                                            : prev.id === e.id &&
                                              prev.showAddComment
                                            ? e.id
                                            : e.id,
                                        showReplies:
                                          prev.id === e.id && prev.showReplies
                                            ? false
                                            : prev.id === e.id &&
                                              !prev.showReplies
                                            ? true
                                            : true,
                                        showAddComment: false,
                                      }))
                                    }
                                  >
                                    <Font className="text-xs pr-2 text-app-d">
                                      Replies
                                    </Font>
                                  </TouchableOpacity>
                                </View>
                              </View>
                              <View className="w-full flex-col ">
                                {showCommentId.id === e.id &&
                                  showCommentId.showAddComment && (
                                    <View className="w-full flex-col py-1 px-3 my-3">
                                      <View className="w-full pb-1">
                                        <Font>Reply to this comment</Font>
                                      </View>
                                      <View className="sm:w-full flex-col sm:flex-row justify-between">
                                        <View className="w-full pb-3 sm:pb-0 sm:w-3/4 flex-row items-center justify-center rounded-l-md">
                                          <Input
                                            type="text"
                                            value={replyToAComment}
                                            setValue={(ee) => {
                                              setReplyToAComment(ee);
                                            }}
                                            placeholder="reply to this comment..."
                                          ></Input>
                                          <Pressable
                                            className="bg-app-c h-10 px-3 flex justify-center items-center border border-app-c rounded-r-md"
                                            onPress={() =>
                                              onSendReplyToAComment(e.id)
                                            }
                                          >
                                            <RemixIcon
                                              name="ri-send-plane-2-line"
                                              size={15}
                                            />
                                          </Pressable>
                                        </View>
                                      </View>
                                    </View>
                                  )}

                                {/* ---------------------- replies to a comment ------------------------ */}
                                {showCommentId.id === e.id &&
                                  showCommentId.showReplies &&
                                  e.replies?.length > 0 &&
                                  e.replies?.reverse()?.map((ee, i) => (
                                    <View
                                      className="w-full flex-row justify-between items-center p-2 border-b border-app-c"
                                      key={`comment_replies${i}`}
                                    >
                                      <View className="flex-row justify-start overflow-hidden rounded-full pb-4 mr-3">
                                        <Image
                                          // resizeMode="contain"
                                          source={require("../../../assets/user1.jpg")}
                                          className="w-10 h-10 rounded-full"
                                        ></Image>
                                      </View>
                                      <View className="w-full flex-1">
                                        <View className="w-full flex-row px-3 pt-1">
                                          <Font className="w-full py-1 font-bold">
                                            {ee.user_name}
                                          </Font>
                                          {/* <Font className="ml-2 p-1 bg-app-d rounded-md text-app-a text-[10px]">
                              user_role
                            </Font> */}
                                        </View>
                                        <View className="w-full pt-1 px-3">
                                          <Font className="text-xs text-app-d1">
                                            {getFullDateTime(ee.created_at)}
                                          </Font>
                                        </View>
                                        <View className="w-full flex-1">
                                          <View className="w-full flex-row bg-app-c2 p-3 rounded-md">
                                            <Font>{ee.message}</Font>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  ))}
                              </View>
                            </View>
                          </View>
                        ))}
                        {commentsPagination.total > commentsPerPage &&
                          commentsPagination.to <=
                            commentsPagination.total - commentsPerPage && (
                            <View className="w-full flex-row justify-center">
                              {isCommentsLoading ? (
                                <Pressable className="bg-app-e w-32 py-2 rounded-lg">
                                  <ActivityIndicator
                                    size={"small"}
                                    color={colors.app.a}
                                  ></ActivityIndicator>
                                </Pressable>
                              ) : (
                                <TouchableOpacity
                                  onPress={() => onLoadMore()}
                                  className="bg-app-e w-32 py-2 rounded-lg"
                                >
                                  <Font className="text-app-a text-center">
                                    Load More
                                  </Font>
                                </TouchableOpacity>
                              )}
                            </View>
                          )}
                      </View>
                    ) : comments.length === 0 && !isCommentsLoading ? (
                      <View className="w-full flex-row h-80 justify-center items-center">
                        <Font className="text-app-d1">No comments found.</Font>
                      </View>
                    ) : (
                      <View className="flex-1 w-full justify-center h-40">
                        <ActivityIndicator
                          size={"small"}
                          color={colors.app.e}
                        ></ActivityIndicator>
                      </View>
                    )}
                  </Card>
                </View>
                <View className="hidden lg:flex lg:items-end w-full lg:w-2/6 p-0 lg:pl-4 pt-5 lg:pt-0">
                  <View className="w-4/5">
                    <Widget></Widget>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <Footer></Footer>
        </ScrollView>
      </View>
    </View>
  ) : (
    !user && !isAuthLoading && <Unautorized />
  );
}

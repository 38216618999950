import React, { useState, useEffect } from "react";
import { Pressable, ScrollView, Text, TouchableOpacity, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { FaqItem } from "../../components/faq";

import { DEV_API } from "../../services/api";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import { getPublicData, SharedContext } from "../../services/api";
import axios from "axios";

export const Faq = ({ navigation, route }) => {
  const [faqs, setFaqs] = useState([]);
  const [faqData, setFaqData] = useState([]);
  const [pagination ,setPagination] = useState(0);
  const [hasMoreData , setHasMoreData] = useState(true);

  // useEffect(() => {
  //   if(!faqData){
  //   const getFaqsData = async () => {
  //     await axios
  //       .get(DEV_API + `/content/faqs`)
  //       .then((data) => {
  //         console.log("data", data);
  //         setFaqData(data.data);
  //         console.log("Priyanka ", data.data.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error updating resource:", error);
  //       });
  //   };
  //   getFaqsData();
  // }
  // });
  const limitPerPage = 1;
  useEffect(() => {
    getFaqsData();
  }, []);
  const getFaqsData = async () => {
    try {
      const res = await axios.get(DEV_API + `/content/faqs?limit=${limitPerPage}&offset=${pagination}`);
      if(faqData.length + res.data["data"].length==res.data.total_count){
        setHasMoreData(false);
      }
      setPagination(faqData.length + res.data["data"].length);
      setFaqData([...faqData , ...res.data["data"]]);
      //console.log("setFaqData" ,res.data.data)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="flex-1 p-4 rounded-t-2xl bg-gray-50">
        <ScrollView
        // contentContainerStyle={{
        //   flexGrow: 1,
        //   justifyContent: "center",
        //   alignItems: "center",
        // }}
        >
          <View className=" max-w-screen-lg w-full mx-auto">
            <View className="my-5 hidden lg:flex">
              <Font className="text-lg font-semibold text-app-d">
                Frequently Asked Questions
              </Font>
            </View>
            <View className="flex-col lg:flex-row max-w-screen-lg w-full">
              <View className=" w-full lg:w-4/6 p-0 lg:pr-4 pt-0" style={{height:"fit-content"}}>
                <View className="flex-1">
                  {faqData?.map((info) => (
                    <FaqItem key={info.question} info={info}></FaqItem>
                  ))}
                </View>
                {hasMoreData &&<View>
                  <TouchableOpacity onPress={getFaqsData}>
                  <Text>Show More</Text>
                  </TouchableOpacity>
                
              </View>
              }
              </View>
              
              <View className=" w-full lg:w-2/6 p-0 lg:pl-4 flex-1">
                <Widget></Widget>
              </View>
            </View>
            <Footer></Footer>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

import React from "react";
import { Image, View } from "react-native";
import { Font } from "../../components/font";
import { Switcher } from "../../components/input";
import { Unautorized } from "./unautorized";
import { useUserFeedContext } from "../../context/user-feed-context";
import { pageTitle, parseProfileName } from "../../../utils";
import UserReviews from "./user-reviews";
import UserReviewDetails from "./user-review-details";
import UserFeedTimeline from "./user-feed-timeline";
import UserFeedComments from "./user-feed-comments";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { DEV_API } from "../../services/api";
import { UserContext } from "../../context/userContext";

const Stack = createNativeStackNavigator();

const UserFeed = ({ navigation }) => {
  const { tabStatus, setTabStatus } = useUserFeedContext();
  const { userData } = React.useContext(UserContext);

  if (!userData) {
    <Unautorized />;
  }

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="rounded-t-2xl lg:rounded-none flex-1 bg-gray-50">
        <View className="flex-row gap-4 px-4 lg:px-8 py-8 lg:pb-4">
          <View className="rounded-full w-12 h-12 overflow-hidden">
            <Image
              source={
                userData?.profile_image_url
                  ? { uri: `${DEV_API}/media/` + userData.profile_image_url }
                  : require("../../../assets/userImage.jpg")
              }
              className="w-full h-full"
            ></Image>
          </View>
          <View className="justify-center">
            <Font className="font-bold text-[16px] mb-1 text-app-d">
              {parseProfileName(userData?.first_name, userData?.last_name)}
            </Font>
            <Font className="text-[12px] text-app-d1">
              {userData?.about || "No about here"}
            </Font>
          </View>
        </View>
        {/* <View className="flex lg:hidden">
            <View className="w-full h-10 rounded-lg flex-row overflow-hidden border border-gray-200 divide-x divide-gray-200">
              {options?.map((e, i) => (
                <Pressable
                  className={`h-full flex-1 items-center justify-center ${
                    tabStatus.curr == e.value
                      ? "bg-app-e active:bg-red-600"
                      : "bg-white active:bg-gray-100  "
                  }`}
                  onPress={() => setTab(e)}
                  key={i}
                >
                  <Font
                    weight={600}
                    className={`${
                      tabStatus.curr === e ? "text-app-a" : "text-app-e"
                    }`}
                  >
                    {e.label}
                  </Font>
                </Pressable>
              ))}
            </View>
          </View> */}
        <View className="w-full mx-auto flex-1">
          <View className="flex-col h-full max-w-screen w-full relative">
            <View className="flex-row items-center px-4 lg:px-8">
              <Font className="text-lg font-semibold text-app-d flex-1">
                {tabStatus.curr}
              </Font>
              <View className="w-52">
                <Switcher
                  options={[
                    {
                      label: "Wall",
                      value: "My Feed",
                    },
                    {
                      label: "Reviews",
                      value: "My Reviews",
                    },
                  ]}
                  value={tabStatus.curr}
                  setValue={(e) => {
                    if (e !== tabStatus.curr) {
                      //   getVendorBookings(undefined, undefined, e);
                      navigation.push(
                        e === "My Feed"
                          ? "user-feed"
                          : e === "My Reviews"
                          ? "user-reviews"
                          : ""
                      );
                      setTabStatus({ curr: e, prev: tabStatus.curr });
                    }
                  }}
                ></Switcher>
              </View>
            </View>
            <View className="flex-1 h-full w-full bg-gray-50 py-4">
              <Stack.Navigator
                screenOptions={{ animation: "fade_from_bottom" }}
              >
                <Stack.Screen
                  name="user-feed-timeline"
                  options={{
                    title: pageTitle("User Feed Timeline"),
                    // header: (props) => <Navbar {...props} user={userData}></Navbar>,
                    headerShown: false,
                  }}
                >
                  {(props) => (
                    <UserFeedTimeline
                      {...props}
                      setTabStatus={setTabStatus}
                      tabStatus={tabStatus}
                    />
                  )}
                </Stack.Screen>
                <Stack.Screen
                  name="user-feed-comments"
                  getId={({ params }) => params.slug}
                  options={{
                    title: pageTitle("User Feed Comments"),
                    // header: (props) => <Navbar {...props} user={userData}></Navbar>,
                    headerShown: false,
                  }}
                >
                  {(props) => (
                    <UserFeedComments
                      {...props}
                      setTabStatus={setTabStatus}
                      tabStatus={tabStatus}
                    />
                  )}
                </Stack.Screen>
                <Stack.Screen
                  name="user-reviews"
                  options={{
                    title: pageTitle("User Reviews"),
                    // header: (props) => <Navbar {...props} user={userData}></Navbar>,
                    headerShown: false,
                  }}
                >
                  {(props) => (
                    <UserReviews
                      {...props}
                      setTabStatus={setTabStatus}
                      tabStatus={tabStatus}
                    />
                  )}
                </Stack.Screen>
                <Stack.Screen
                  name="user-review-details"
                  getId={({ params }) => params.slug}
                  options={{
                    title: pageTitle("User Review Details"),
                    // header: (props) => <Navbar {...props} user={userData}></Navbar>,
                    headerShown: false,
                  }}
                >
                  {(props) => (
                    <UserReviewDetails
                      {...props}
                      setTabStatus={setTabStatus}
                      tabStatus={tabStatus}
                    />
                  )}
                </Stack.Screen>
              </Stack.Navigator>
            </View>
          </View>
        </View>
        {/* <Footer></Footer> */}
      </View>
    </View>
  );
};

export default UserFeed;

import { View, Text, Linking } from "react-native";
import React, { useContext } from "react";

import colors from "../../../colors";
// import axiosInstance from "../../constants/axios-config";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { Button } from "../../components/input";
import { DEV_API, getUserData } from "../../services/api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AuthContext } from "../../context/authContext";
// import { PleaseWaitModal } from "../../components/modal";
// GoogleSignin.configure({
//   webClientId: "1049029176568-9m667iv9lb1fokt3h6vb0urnfg9m9r6q.apps.googleusercontent.com",
// });


const GoogleSigninFunction = ({ setUserInfo, onGoogleSignIn , navigation }) => {

  const {setUser, setUserToken, setIsAuthLoading } = useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const googleWebAuthenticationAsync = useGoogleLogin({
    // flow: "auth-code",
    redirect_uri: "https://wisdomtooth.tech/google",
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse, "tokenResponse");
      const res = await GoogleSignInToken(
        "access_token",
        tokenResponse.access_token
        );
        if (res.status == 200) {
          console.log(res, "res?.data?.data");
          setUser(true);
          // setUserToken(res.data.token);
          navigation.replace("app");
        }
      const userInfo = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);

      console.log(userInfo, "userInfo of gs");
      // onSuccess: async (codeResponse) => {
      //   console.log(codeResponse ,"success check" );
      // },
    },
    onError: (errorResponse) => console.log("onError:", errorResponse),
    onNonOAuthError: (errorResponse) =>
      console.log("onNonOAuthError:", errorResponse),
    // ux_mode: "popup",
    // include_granted_scopes: false
  });

 const getUserProfile = async (token_data) => {
    try {
      const token = token_data || (await isSignedIn());
      if (!token) {
        return null;
      }
      const res = await axios.get(DEV_API + "/users/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        return res;
      }
      return res;
    } catch (error) {
      console.log("Get User Profile error : ", error);
      if (error?.response) {
        console.log("error.response.status:", error.response.status);
        if (error.response.status === 401) await AsyncStorage.removeItem("user");
        return error.response;
      } else {
        throw error;
      }
    }
  };
  
 const GoogleSignInToken = async (type, token) => {
    try {
      const res = await axios.post(
        DEV_API + "/auth/google/signin",
        type == "id_token"
          ? {
              role: "vendor",
              id_token: token,
            }
          : {
              role: "vendor",
              access_token: token,
            }
      );
      console.log(res, "GoogleSignInToken response log");
      // navigate after 200 status same as login
      if (res.status === 200) {
        await AsyncStorage.setItem("user", JSON.stringify(res?.data?.token));
        console.log(res?.data?.token,"res?.data?.token");
        // socket.connect();
        setUser(true);
        setUserToken(res.data.token);
        return await getUserProfile(res?.data?.token);
      }
    } catch (error) {
      // setIsLoading(false);
      console.log(error, "GoogleSignInToken error log");
    }
  };


  return (
    <GoogleOAuthProvider
      clientId="1049029176568-8vkue4crnqh3qqontpnqs8jso1majv0u.apps.googleusercontent.com"

      //   redirectUri="http://localhost:19006"
    >
      <View className="w-full my-2">
        {/* {
    isLoading &&  <PleaseWaitModal  />
    } */}
        <Button
          label="Sign in with Google"
          leftIcon={{
            name: "google-fill",
            color: colors.app.d1,
          }}
          onPress={googleWebAuthenticationAsync}
          // onPress={()=>getUserData("nG8fx-B0r61LS-uhQ8itlu7b3GiVKmgVpaX3z7e8mfo")}
        />
      </View>
    </GoogleOAuthProvider>
  );
};

export default GoogleSigninFunction;

import { useReducer, useCallback, useLayoutEffect } from "react";
import { isEqual } from "lodash";

const getInitialInput = ({ defaultValue } = {}) => ({
  defaultValue: defaultValue,
  inputValue: undefined,
  currentValue: defaultValue || "",
  isInputBlured: false,
  hasError: "",
});

const inputStateReducer = (state, action) => {
  if (action.type === "INPUT") {
    return {
      ...state,
      inputValue: action.inputValue,
      currentValue: action.currentValue,
      hasError: action.hasError,
    };
  }
  if (action.type === "BLUR") {
    return {
      ...state,
      inputValue: action.inputValue,
      currentValue: action.currentValue,
      hasError: action.hasError,
      isInputBlured: action.isInputBlured,
    };
  }
  if (action.type === "RESET") {
    return { ...action.payload };
  }
  return state;
};

const useInputHandler = ({ validateFunction, defaultValue, required } = {}) => {
  const [inputState, dispatch] = useReducer(
    inputStateReducer,
    getInitialInput({ defaultValue })
  );

  const onBlurHandler = useCallback(() => {
    const isInputValueDefault = isEqual(defaultValue, inputState.inputValue);
    dispatch({
      type: "BLUR",
      inputValue: isInputValueDefault ? undefined : inputState.inputValue,
      currentValue: isInputValueDefault
        ? defaultValue
        : inputState.currentValue,
      hasError: validateFunction
        ? validateFunction(inputState.inputValue)
        : required
        ? inputState.inputValue
          ? ""
          : "required"
        : "",
      isInputBlured: true,
    });
  }, [
    defaultValue,
    inputState.inputValue,
    inputState.currentValue,
    required,
    validateFunction,
  ]);

  const onValueChangeHandler = useCallback(
    (value) => {
      const isInputValueDefault =
        typeof defaultValue === "number"
          ? value !== "" && defaultValue == value
          : isEqual(defaultValue, value);
      dispatch({
        type: "INPUT",
        inputValue: isInputValueDefault ? undefined : value,
        currentValue: value,
        hasError:
          validateFunction && inputState.isInputBlured
            ? validateFunction(value)
            : "",
      });
    },
    [inputState.isInputBlured, validateFunction, defaultValue]
  );

  const reset = useCallback(() => {
    dispatch({
      type: "RESET",
      payload: getInitialInput({ defaultValue }),
    });
  }, [defaultValue]);

  useLayoutEffect(() => {
    if (
      typeof defaultValue === "number"
        ? inputState.defaultValue !== "" &&
          defaultValue != inputState.defaultValue
        : !isEqual(defaultValue, inputState.defaultValue)
    ) {
      dispatch({
        type: "RESET",
        payload: getInitialInput({ defaultValue }),
      });
    }
  }, [defaultValue, inputState.defaultValue]);

  return {
    currentValue: inputState.currentValue,
    inputValue: inputState.inputValue,
    hasError: inputState.hasError,
    isInputBlured: inputState.isInputBlured,
    onValueChangeHandler,
    onBlurHandler,
    reset,
  };
};

export default useInputHandler;

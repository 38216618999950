import React from "react";
import { View, Pressable } from "react-native";
import * as ImagePicker from "expo-image-picker";
import { Image } from "react-native";
import { Font } from "./font";
import RemixIcon from "react-native-remix-icon";
import { fileURLtoBlob } from "../../utils/encDec";

const MediaUpload = ({
  // mediaTypes = "Images",
  onImagePick = () => null,
  placeholderImage = null,
  allowsEditing = true,
  quality = 0.5,
  reset,
  onReset,
}) => {
  const [image, setImage] = React.useState();

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      // mediaTypes: ImagePicker.MediaTypeOptions[mediaTypes],
      allowsEditing: allowsEditing,
      // aspect: [1, 1],
      quality: quality,
      UIImagePickerPresentationStyle: "fullScreen",
      // base64: true,
    });

    if (!result.canceled) {
      let imageObject = result.assets[0];
      setImage({ uri: result.assets[0].uri });
      fileURLtoBlob(imageObject.uri)
        .then((data) => {
          imageObject.blob = data;
          onImagePick(imageObject);
        })
        .catch((error) => {
          console.log("fileURLtoBlob-error", error);
        });
    }
  };

  React.useEffect(() => {
    if (reset && image) {
      onReset();
      setImage();
    }
  }, [reset, onReset, image]);

  return image ? (
    <View className="flex-1 flex-row">
      <View className="w-48 h-60 flex-1 justify-center items-center rounded-lg border border-gray-200 overflow-hidden bg-app-a">
        <Image
          source={image}
          className="w-full h-full flex-1 object-contain"
          resizeMode="contain"
        />
        <Pressable
          onPress={() => {
            onImagePick();
            setImage();
          }}
          className="w-full justify-center outline-none items-center p-2 border-t border-gray-200"
        >
          <Font weight={500} className="text-app-e rounded-lg">
            Remove
          </Font>
        </Pressable>
      </View>
    </View>
  ) : (
    <View className="flex-1 flex-row">
      <Pressable
        className="w-48 h-60 flex-1 justify-center items-center rounded-lg border border-gray-200 overflow-hidden bg-app-a"
        onPress={pickImage}
      >
        <View className="w-full h-full flex-1 justify-center items-center relative">
          <Image
            source={placeholderImage}
            className="w-full h-full flex-1 object-contain"
            resizeMode="contain"
          />
          <View className="bg-app-d/30 rounded-lg p-2 absolute">
            <RemixIcon name="add-line" size={28} color="#fff" />
          </View>
        </View>
        <Font
          weight={500}
          className={`py-2.5 w-full text-center border-t border-gray-200 ${
            placeholderImage && "text-app-g"
          }`}
        >
          {placeholderImage ? "Replace" : "Upload"}
        </Font>
      </Pressable>
    </View>
  );
};

export default MediaUpload;

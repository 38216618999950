import React, { useContext } from "react";
import { Image, View, Pressable } from "react-native";
import { Font, A } from "../../components/font";
import { Button, Input, Switcher } from "../../components/input";
import colors from "../../../colors";
import { ScrollView } from "react-native-gesture-handler";
import RemixIcon from "react-native-remix-icon";
export const ConfirmBooking = ({ navigation }) => {
  const back = navigation.canGoBack();
  const dismiss = () => {
    navigation.goBack();
  };
  return (
    <View
      className={`flex-1 items-center justify-center p-4  ${
        back ? "bg-app-d3" : "bg-gray-50"
      }`}
    >
      <View className="w-full max-h-screen h-3/4 bg-white rounded-lg max-w-screen-md border border-gray-200 overflow-hidden p-4 flex justify-between">
        <Pressable
          className="w-full mb-5 flex justify-center items-center"
          onPress={() => navigation.replace("app")}
        >
          <Image
            source={require("../../../assets/smbex-lo.png")}
            className="w-36 h-12"
          ></Image>
        </Pressable>
        <View className="items-center justify-center">
          <View className="rounded-full w-10 h-10 bg-app-g items-center justify-center">
            <RemixIcon
              name="check-line"
              color={colors.app.a}
              size="24"
            ></RemixIcon>
          </View>
          <Font className="text-lg my-4">Booked successfully!</Font>
          {/* <View className="w-full my-2">
            <A
              href="My bookings"
              label="My bookings"
              className="font-semibold text-app-e text-sm text-center"
            />
          </View> */}
        </View>
        <View className="my-3 h-1/5 justify-between rounded-lg flex w-full items-center">
          <Button
            type="primary"
            label="My bookings"
            className="w-full"
            onPress={() => {
              dismiss();
              navigation.navigate("booking-details");
            }}
          ></Button>
          <Button
            type="primary"
            label="Dismiss"
            className="w-full"
            onPress={dismiss}
          ></Button>
        </View>
      </View>
    </View>
  );
};

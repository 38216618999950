import React, { useContext, useState } from "react";
import { Image, View, Pressable } from "react-native";
import { Font } from "../../components/font";
import { Button, Input } from "../../components/input";
import { ScrollView } from "react-native-gesture-handler";
import { AuthContext } from "../../context/authContext";
import { postUpdatePassword } from "../../services/api";

export const UpdatePassword = ({ navigation, route }) => {
  const { isAuthLoading, setIsAuthLoading, setAuthApiError } =
    useContext(AuthContext);
  const [changePasswordData, setChangePasswordData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [errorMsg, setErrorMsg] = useState(null);
  const [failureErrorMsg, setFailureErrorMsg] = useState(null);
  const [isValid, setIsValid] = useState({
    newPassword: false,
    formIsValid: false,
  });
  // setChangePasswordData;changePasswordData;
  const setIsInputValid = ({ e, type }) => {
    switch (type) {
      case "newPassword":
        if (e === true) {
          setIsValid({ ...isValid, newPassword: e, formIsValid: true });
        } else {
          setIsValid({ ...isValid, newPassword: e, formIsValid: false });
        }
        break;

      default:
        break;
    }
  };
  const matchPasswords = ({ password, type }) => {
    switch (type) {
      case "newPassword":
        if (changePasswordData.confirmPassword !== "") {
          if (password !== changePasswordData.confirmPassword) {
            setErrorMsg("Passwords doesn't match.");
          } else {
            setErrorMsg(null);
          }
        } else {
          setErrorMsg(null);
        }
        break;
      case "confirmPassword":
        if (changePasswordData.newPassword !== "") {
          if (password !== changePasswordData.newPassword) {
            setErrorMsg("Passwords doesn't match.");
          } else {
            setErrorMsg(null);
          }
        } else {
          setErrorMsg(null);
        }
        break;

      default:
        break;
    }
  };

  const hash = route.params.urlHash;

  const onUpdatePassword = async () => {
    if (changePasswordData.newPassword === changePasswordData.confirmPassword) {
      try {
        setIsAuthLoading(true);
        if (changePasswordData.newPassword !== "" && isValid.formIsValid) {
          const res = await postUpdatePassword({
            hash: hash,
            new_password: changePasswordData.newPassword,
          });
          if (res.status === 200) {
            // console.log("success");
            navigation.navigate("update-password-successful");
          } else {
            // console.log("fail");
            setFailureErrorMsg("Something went wrong! Please try again.");
            setAuthApiError(res.data.message);
          }
        }
      } catch (err) {
        console.log("sign-up, onsignup err : ", err);
      } finally {
        setIsAuthLoading(false);
      }
    }
  };

  return (
    <View className="w-full h-full bg-gray-50 flex-row">
      <View className="h-full w-full lg:w-1/2 items-center justify-center">
        <ScrollView keyboardShouldPersistTaps="always">
          <View
            className={
              "w-80 flex-1 justify-center items-center mt-16 " +
              (Platform.OS === "web"
                ? "py-8 px-10 md:px-0 w-full md:w-80"
                : "basis-0")
            }
          >
            <Pressable onPress={() => navigation.replace("app")}>
              <Image
                source={require("../../../assets/smbex-lo.png")}
                className="w-36 h-12 mb-8"
              ></Image>
            </Pressable>
            <View className="w-full flex-1 my-2">
              <Font className="text-xl text-app-d" weight={500}>
                Set new password
              </Font>
            </View>
            <View className="w-full flex-row mb-2">
              <Input
                type="password"
                label="New Password"
                placeholder="************"
                value={changePasswordData.newPassword}
                setValue={(e) => {
                  setChangePasswordData({
                    ...changePasswordData,
                    newPassword: e,
                  });
                  matchPasswords({ password: e, type: "newPassword" });
                }}
                validation={true}
                isValid={(e) => setIsInputValid({ e, type: "newPassword" })}
              ></Input>
            </View>
            <View className="w-full flex-row mb-4">
              <Input
                type="password"
                label="Confirm Password"
                placeholder="************"
                value={changePasswordData.confirmPassword}
                setValue={(e) => {
                  setChangePasswordData({
                    ...changePasswordData,
                    confirmPassword: e,
                  });
                  matchPasswords({ password: e, type: "confirmPassword" });
                }}
              ></Input>
            </View>
            <View className="w-full flex-row">
              <Font className="text-app-e">{errorMsg}</Font>
            </View>
            <View className="w-full mt-4 my-2">
              <Button
                type={`${
                  changePasswordData.newPassword !== "" &&
                  changePasswordData.newPassword ===
                    changePasswordData.confirmPassword &&
                  isValid.formIsValid
                    ? "primary"
                    : "disabled"
                }`}
                label="Update password"
                onPress={onUpdatePassword}
              ></Button>
            </View>
            <View className="w-full flex-row">
              <Font className="text-app-e">{failureErrorMsg}</Font>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Pressable, ScrollView, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import { MaterialIcons } from "@expo/vector-icons";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { getPublicData } from "../../services/api";
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { ActivityIndicator } from "react-native";

const transactionData = 
  {
    id: 1,
    transactionId: 1,
    transactionNumber: 1,
    transactionDate: "Mon Jan 1 2020",
    transactionType: "Card",
    title: "Listing payment",
    numberOfBookings: 5,
    amount: 500,
    currency: "$",
  };
export const Invoice = ({ navigation, route }) => {
  const { userToken } = useContext(AuthContext);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const getTransactionDetails = async () => {
    setIsLoading(true);
    try {
      const res = await axiosGet({
        Url: "/transactions/${id}",
        token: userToken,
      });
      if (res.status === 200) {
        setTransactionDetails(res.data.data);
      } 
      // else {
      //   console.log("invoice, getTransactionDetails res error : ", res.data);
      //   setIsLoading(false);
      // }
    } catch (error) {
      console.log("invoice, getTransactionDetails error : ", error);
      // setIsLoading(false);
    } finally {
      setRefreshing(false);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTransactionDetails();
  }, []);

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView className="p-4 rounded-t-2xl bg-app-a">
        <View className="flex-1 justify-start max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Invoice
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className="w-full lg:w-4/6 p-0 lg:pr-4">
              {transactionDetails && !isLoading ? (
                <Card title="Invoice">
                  <View className="flex-1 w-full px-4">
                    <View className="w-full flex-row flex-wrap py-4">
                      <View className="w-1/2 mb-2">
                        <Font className="text-app-d" weight={600}>
                          Transaction Id
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          # {transactionData.transactionId}
                        </Font>
                      </View>
                      <View className="w-1/2 mb-2">
                        <Font className="text-app-d" weight={600}>
                          Transaction number
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                        {transactionData.transactionNumber}
                        </Font>
                      </View>
                      <View className="w-1/2 mb-2">
                        <Font className="text-app-d" weight={600}>
                          Paid on
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          {new Date().toDateString()}
                        </Font>
                      </View>
                      <View className="w-1/2 mb-2">
                        <Font className="text-app-d" weight={600}>
                          Number of bookings
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                        {transactionData.numberOfBookings}
                        </Font>
                      </View>
                      <View className="w-1/2 mb-2">
                        <Font className="text-app-d" weight={600}>
                          Type
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                        {transactionData.transactionType}
                        </Font>
                      </View>
                      <View className="w-1/2 mb-2">
                        <Font className="text-app-d" weight={600}>
                          Total Amount
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          {`${transactionData.currency} ${transactionData.amount}`}
                        </Font>
                      </View>
                      <View className="w-1/2 mb-2">
                        <Font className="text-app-d" weight={600}>
                          Customer
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          Smbex 1
                        </Font>
                      </View>
                      <View className="w-1/2 mb-2">
                        <Font className="text-app-d" weight={600}>
                          Vendor
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          Vendor 1
                        </Font>
                      </View>
                    </View>
                  </View>
                </Card>
              ) : !transactionDetails && !isLoading ? (
                <Card className="flex">
                  <View className="w-full flex-row h-80 justify-center items-center">
                    <Font className="text-app-d1">No details found.</Font>
                  </View>
                </Card>
              ) : (
                <Card className="w-full flex-1">
                  <View className="flex-1 w-full justify-center h-40">
                    <ActivityIndicator
                      size={"small"}
                      color={colors.app.e}
                    ></ActivityIndicator>
                  </View>
                </Card>
              )}
            </View>
          </View>
          <Footer></Footer>
        </View>
      </ScrollView>
    </View>
  );
};

import React from "react";
import { View } from "react-native";
import { Font } from "./font";
import colors from "../../colors";
import RemixIcon from "react-native-remix-icon";
import { Modal } from "react-native";
import { TouchableOpacity } from "react-native";
export const ResponseModal = ({
  setVisible = () => null,
  modalVisible,
  primaryButtonLabel,
  onPrimaryButtonPress,
  showSecondaryButton,
  secondaryButtonLabel = null,
  onSecondaryButtonPress = null,
  modalContent,
  showSuccess,
  children,
}) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible || false}
      onRequestClose={() => {
        // Alert.alert("Modal has been closed.");
        setVisible(false);
      }}
    >
      <View className="flex-1 justify-center items-center bg-app-d3">
        <View className=" bg-app-a w-3/4 lg:w-2/5 h-48 max-h-96 items-center p-5 rounded-lg">
          <View className="w-full h-3/4 justify-center border-b border-gray-200 mb-2">
            {showSuccess && (
              <View className="justify-center items-center pb-5">
                <View className="rounded-full w-7 h-7 bg-app-g items-center justify-center">
                  <RemixIcon
                    name="check-line"
                    color={colors.app.a}
                    size="24"
                  ></RemixIcon>
                </View>
              </View>
            )}
            {modalContent && (
              <View className=" w-full justify-center items-center">
                <Font className="font-bold text-app-d text-base">
                  {modalContent}
                </Font>
              </View>
            )}
          </View>
          <View className="w-full items-center flex-row flex-1">
            <View
              className={`items-center ${
                showSecondaryButton ? "w-1/2" : "w-full"
              }`}
            >
              <TouchableOpacity
                className={`w-full  text-center ${
                  showSecondaryButton && "border-r border-gray-200"
                }`}
                onPress={() => onPrimaryButtonPress()}
              >
                <Font className="font-semibold text-gray-500 text-base text-center">
                  {primaryButtonLabel}
                </Font>
              </TouchableOpacity>
              {/* <Button
                className="w-full"
                label={primaryButtonLabel}
                type="primary"
                onPress={() => onPrimaryButtonPress()}
              ></Button> */}
            </View>
            {showSecondaryButton && (
              <View className={`${showSecondaryButton ? "w-1/2" : "w-full"} `}>
                <TouchableOpacity
                  className="w-full text-center"
                  onPress={() => onSecondaryButtonPress()}
                >
                  <Font className="font-semibold text-gray-500 text-base text-center">
                    {secondaryButtonLabel}
                  </Font>
                </TouchableOpacity>
                {/* <Button
                  className="w-full"
                  label={secondaryButtonLabel}
                  type="primary"
                  onPress={() => onSecondaryButtonPress()}
                ></Button> */}
              </View>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};

import React, { useState, useEffect } from "react";
import { Pressable, Text, View } from "react-native";
import RemixIcon from "react-native-remix-icon";

import colors from "../../colors";

import { Font } from "./font";
import axios from "axios";
import { DEV_API } from "../services/api";

export const FaqItem = (props) => {
  let [open, setOpen] = useState(false);
  

  return (
    <View
      className="w-full bg-app-a rounded-lg p-0 border border-gray-200 mb-4"
      {...props}
    >
      <Pressable
        className="justify-center p-4 flex-row"
        onPress={() => setOpen((e) => !e)}
      >
        <Font
          className="text-app-d leading-5 flex-1 font-semibold"
          numberOfLines={3}
        >
         { props.info.question}
        </Font>
        <RemixIcon
          className="flex-shrink-0"
          name={`arrow-${open ? "up" : "down"}-s-fill`}
          size={24}
          color={colors.app.d}
        ></RemixIcon>
      </Pressable>
      {open && (
        <View className="flex-1 p-4 border-t border-gray-200">
          <Font>
           
              <View>
                <Font>{props.info.answer}</Font>
              </View>
            
          </Font>
        </View>
      )}
    </View>
  );
};

import React, { useContext, useEffect, useState } from "react";
import {
  Pressable,
  View,
  Image,
  TouchableWithoutFeedback,
  Modal,
  ActivityIndicator,
  Share,
} from "react-native";
import {
  joinOrRequestGroup,
  previewGroupPosts,
  likeGroupPost,
  ShareAPost,
} from "../../services/api";
import { Font } from "../../components/font";

import { ScrollView } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";

import moment from "moment";
import { ResponseModal } from "../../components/response-modal";
import { AuthContext } from "../../context/authContext";
import * as Linking from "expo-linking";
import GroupPost from "../../components/group-components/GroupPost";
import CreateGroupPost from "./create-group-post";
import GroupMembers from "./group-members";
import { cloneDeep } from "lodash";

const ViewGroup = ({
  route,
  navigation,
  privateGroup,
  groupDescription,
  groupName,
}) => {
  const {
    id,
    name,
    description,
    isPrivate: isPrivateGroupParam,
    groupStatus,
    checkRefreshList,
    members,
    noOfPosts,
    createdOn,
    background_cover_image_url,
    icon_cover_image_url,
  } = route?.params;
  useEffect(() => {
    if (route?.params) {
      setIsPrivate(isPrivateGroupParam);
      setIsGroupName(name);
      setIsGroupDescription(description);
    } else {
      setIsPrivate(privateGroup);
      setIsGroupName(groupName);
      setIsGroupDescription(groupDescription);
    }
    // getActivities();
  }, [route?.params]);

  const { userToken } = useContext(AuthContext);
  const [groupPost, setGroupPost] = useState([]);
  const [isGroupHandlingTab, setIsGroupHandlingTab] = useState("home");
  const [isPrivate, setIsPrivate] = useState();
  const [isGroupName, setIsGroupName] = useState();
  const [isGroupDescription, setIsGroupDescription] = useState();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPostId, setIsPostId] = useState();
  const [isCommentSectionVisible, setIsCommentSectionVisible] = useState(false);
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const timeAgo = (updatedAt) => moment(updatedAt).fromNow();

  const getGroupPostsPreview = async () => {
    try {
      setIsLoading(true);
      const res = await previewGroupPosts({
        token: userToken,
        group_id: id,
        limit: undefined,
        offset: 0,
      });

      if (res?.status === 200) {
        setGroupPost(res.data?.posts || []);
      }
    } catch (error) {
      console.log("error?.code:", error?.code, error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getGroupPostsPreview();
  }, []);

  const joinGroup = async ({ group_id } = {}) => {
    try {
      const res = await joinOrRequestGroup({
        token: userToken,
        group_id,
      });

      if (res.data?.message) {
        setShowResponseModal((prev) => ({
          ...prev,
          visible: true,
          bodyText: res.data.message,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              bodyText: "",
              onRequestClose: () => null,
            });
            if (res?.status === 200 || res?.status === 201)
              navigation.replace("groups");
          },
        }));
      } else if (res?.status === 200 || res?.status === 201)
        navigation.replace("groups");
    } catch (error) {
      console.log("error?.code:", error?.code, error);
      setShowResponseModal((prev) => ({
        ...prev,
        visible: true,
        bodyText: error.message,
      }));
    }
  };
  const acceptRequestOfUser = (groupId, isPrivate) => {
    joinGroup({ group_id: groupId, is_private: isPrivate });
  };
  const likePost = async ({ postId, isLiked }) => {
    try {
      const res = await likeGroupPost({
        group_id: id,
        post_id: postId,
        is_liked: isLiked,
        token: userToken,
      });
      if (res.status === 201) {
        const index = groupPost.findIndex((gp) => gp.id === postId);
        if (index > -1) {
          setGroupPost((posts) => {
            posts[index].is_liked = !isLiked;
            posts[index].likes = posts[index].likes + (isLiked ? -1 : 1);
            return [...posts];
          });
        }
      } else if (res.data?.message) {
        setShowResponseModal((prev) => ({
          ...prev,
          visible: true,
          bodyText: res.data?.message,
        }));
      }
    } catch (error) {
      console.log("error?.code:", error?.code, error);
      setShowResponseModal((prev) => ({
        ...prev,
        visible: true,
        bodyText: error.message,
      }));
    }
  };

  const sharePostApi = async (postId, index) => {
    try {
      const result = await Share.share({
        message: `https://smbexvendor.wisdomtooth.tech/groups/${id}/posts/${postId}`,
      });

      if (result?.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
          try {
            let res = await ShareAPost(id, postId, userToken);

            if (res.status === 201) {
              setGroupPost((data) => {
                data[index].shares++;
                return cloneDeep(data);
              });
              // setGroupPost(res.data.posts);
              // console.log(res.data, "response check success for share posts");
            } else {
              console.log(res.data, "response check failed for share posts");
            }
          } catch (error) {
            console.log("Error Getting share post : ", error);
          }
        }
      } else if (result?.action === Share.dismissedAction) {
        // dismissed
        console.log("Share action was dismissed.");
      }
    } catch (error) {
      alert(error.message);
    }
  };
  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {/* --------------------------mobile-screen Group Navbar--------------------------- */}
      {/* <ConfirmModal
        visible={deleteModal}
        onCancel={closeDeleteModal}
        onConfirm={deletePost}
        confirmText={"Delete"}
        bodyText={"Are you sure you want to delete Post ?"}
        BodyComponent={false}
      ></ConfirmModal>
      {isPleaseWaitModal && <PleaseWaitModal showLoader={true} />}
      <ResponseModal
        visible={responseModal.visible}
        bodyText={responseModal.message}
        onRequestClose={closeResponseModal}
      ></ResponseModal> */}
      <View className="p-4 pt-2 w-full flex lg:hidden bg-app-e">
        <View className="w-full flex-col">
          {/* <View className="w-3 h-3">
            <SVGLoader
            src={leftArrow}
            height={40}
            width={40}
              webHeight={"20em"}
              webWidth={"auto"}
              />
          </View> */}
          {/* <View className="flex-row justify-end items-center">
            {/* <View className="w-5 h-5">
              <SVGLoader
                src={bell}
                height={40}
                width={40}
                webHeight={"20em"}
                webWidth={"auto"}
              />
            </View> *
            <View className="w-5 h-5 mb-2 mr-2">
              <SVGLoader
                src={verticalmenu}
                height={30}
                width={25}
                webHeight={"20em"}
                webWidth={"auto"}
              />
            </View>
          </View> */}
          {/* <EditModal
            navigation={navigation}
            visible={isEditModal}
            onRequestClose={closeEditModal}
            userId={user.id}
            groupId={id}
            name={name}
            description={description}
            activetab={isPrivate == true ? "Private" : "Public"}
            checkRefreshList={checkRefreshList}
          ></EditModal> */}
          <View className="w-full justify-center items-center">
            <View
              className="mb-2 flex-col rounded-lg"
              style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
            >
              <View className="px-6 py-1 flex-col justify-center items-center">
                <Font className="text-lg text-app-a font-bold">
                  {/* Relax Baby sitting */}
                  {isGroupName}
                </Font>
                <Font className="text-sm text-app-a">{isGroupDescription}</Font>
                {/* <Font className="text-sm text-app-a">just relax</Font> */}
              </View>
            </View>
          </View>
        </View>
      </View>

      <ScrollView
        className="rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className="max-w-screen-lg w-full h-full">
          {/* ------------------------------------------------lg-screen header--------------------------------------------- */}
          <View className="max-w-screen-lg w-full flex-col justify-center items-center">
            <View className="flex-row mt-2 mb-2 justify-center item-center">
              <View className="mr-2 w-3 h-5 flex justify-center item-center">
                {/* {isPrivate ? <SVGLoader
              src={profilelock}
              height={20}
              width={20}
              webHeight={"20em"}
              webWidth={"auto"}
            /> : } */}
                <RemixIcon
                  name={isPrivate ? `ri-lock-fill` : `ri-earth-fill`}
                  size={14} // or use Tailwind text-size class
                  color="red"
                />
              </View>
              <Font className="text-xs text-app-a" style={{ color: "red" }}>
                {isPrivate ? "Private" : "Public"} group . {members} members
              </Font>
            </View>
            <View className="-mr-2.5 lg:mr-0 mb-2 flex-row justify-center items-center w-[70%]">
              <Pressable
                onPress={() => acceptRequestOfUser(id, isPrivate)}
                className={`p-1 w-full mr-0 lg:mr-2 flex justify-center items-center border ${
                  isPrivate
                    ? "bg-blue-100 border-blue-600"
                    : "bg-green-100 border-green-600"
                } rounded-md`}
              >
                <Font
                  className={` text-xs px-2 ${
                    isPrivate ? "text-blue-600 " : "text-green-600 "
                  }`}
                  style={{ fontSize: 12 }}
                >
                  {isPrivate ? "Ask To Join" : "Join Group"}
                </Font>
              </Pressable>
            </View>

            {/* ------------------------Group-Tabs----------------------------------- */}
            <View className="w-full flex-col rounded-lg">
              {/* ------------------------------Home Tab Section------------------------------ */}

              {isPrivate ? (
                <>
                  <View className="flex justify-center items-center">
                    <View className="w-[80%] flex  border-b border-b-gray-300">
                      <Font className="pb-2">About</Font>
                    </View>
                  </View>
                  <View className="pb-4 flex justify-center items-center">
                    <View className="w-[80%] flex ">
                      <Font
                        className="pt-2"
                        style={{ fontSize: 10, color: "gray" }}
                      >
                        {description}
                      </Font>
                    </View>
                  </View>
                  <View className="flex justify-center items-center">
                    <View className="w-[80%] flex  border-b border-b-gray-300">
                      <Font className="pb-2">Group activity</Font>
                    </View>
                  </View>
                  <View className="flex justify-center items-center flex-col py-4">
                    <View className="w-[80%] flex-row">
                      <Image
                        source={require("../../../assets/Bookmark.png")}
                        className="w-4 h-5 mb-5"
                        style={{ resizeMode: "contain" }}
                      />
                      <Font className="ml-4 text-app-d">
                        {noOfPosts} + New post
                      </Font>
                    </View>
                    <View className="w-[80%] flex-row">
                      <Image
                        source={require("../../../assets/Profile.png")}
                        className="w-4 h-5 mb-7"
                        style={{ resizeMode: "contain" }}
                      />
                      <View className="">
                        <Font className="ml-4 text-app-d">
                          {members} total members
                        </Font>
                        <Font className="ml-4 text-xs text-app-d">
                          {members} in the last week
                        </Font>
                      </View>
                    </View>
                    <View className="w-[80%] flex-row">
                      <Image
                        source={require("../../../assets/2-User.png")}
                        className="w-4 h-5 "
                        style={{ resizeMode: "contain" }}
                      />
                      <Font className="ml-4 text-app-d">
                        Created about {timeAgo(createdOn) || "few days ago"}
                      </Font>
                    </View>
                  </View>
                </>
              ) : (
                <>
                  {isLoading ? (
                    <ActivityIndicator
                      className="self-center justify-self-center flex-1 w-full h-full"
                      color={colors.app.e}
                      size="small"
                    />
                  ) : !groupPost?.length ? (
                    <View className="py-4 flex-col justify-center items-center">
                      <Font>No posts yet</Font>
                    </View>
                  ) : (
                    groupPost.map((e, i) => (
                      <GroupPost
                        key={e?.id + "" + i}
                        postId={e?.id}
                        groupId={id}
                        postIconURL={icon_cover_image_url}
                        postCoverImageURL={e?.cover_image_url}
                        postTitle={e?.title || name}
                        postTimestamp={e?.created_at}
                        postDescription={e?.description}
                        externalLinkUrl={e?.external_link_url}
                        postLikes={e?.likes}
                        isPostLiked={e?.is_liked}
                        postComments={e?.comments}
                        postShares={e?.shares}
                        onLikeGroupPost={({ postId, isLiked }) =>
                          likePost({
                            postId,
                            isLiked,
                          })
                        }
                        disableGroupPostComment={true}
                        onSharePost={(id) => sharePostApi(id, i)}
                      />
                    ))
                  )}
                </>
              )}
              {/* -----------------------Group settings tab section------------------------------ */}
              {isGroupHandlingTab === "settings" && (
                <GroupSettings
                  navigation={navigation}
                  groupId={id}
                  checkRefreshList={checkRefreshList}
                  userId={user.id}
                  isPrivate={isPrivate}
                  groupStatus={groupStatus}
                />
              )}
            </View>
          </View>
        </View>
      </ScrollView>
      {showResponseModal.visible && (
        <ResponseModal
          modalVisible={showResponseModal.visible}
          onPrimaryButtonPress={() => {
            setShowResponseModal((prev) => ({ ...prev, visible: false }));
            showResponseModal.onRequestClose();
          }}
          modalContent={showResponseModal.bodyText}
          primaryButtonLabel={"Okay"}
          setVisible={() =>
            setShowResponseModal((prev) => ({ ...prev, visible: false }))
          }
        />
      )}
    </View>
  );
};

export default ViewGroup;

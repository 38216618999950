import { View, Text, ActivityIndicator } from "react-native";
import React, { useContext } from "react";
import {
  GoogleSignin,
  GoogleSigninButton,
  statusCodes,
} from "@react-native-google-signin/google-signin";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button } from "../../components/input";
import axios from "axios";
import { DEV_API } from "../../services/api";
import colors from "../../../colors";
import { PleaseWaitModal } from "../../components/modals";
import { AuthContext } from "../../context/authContext";
// import { GoogleSignInToken } from "./googleSignin";
// import { PleaseWaitModal } from "../../components/modal";

const GoogleSigninFunction = ({
  showToast,
  setAuthApiError,
  navigation,
}) => {
  const [isGoogleSigningIn, setIsGoogleSigningIn] = React.useState();
  const [showAPIResponse, setShowAPIResponse] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  //   const { registerForPushNotificationsAsync } = useNotifications();
  const [error, setError] = React.useState();
  // const [userInfo, setUserInfo] = React.useState();
  const {setUser, setUserToken, setIsAuthLoading } = useContext(AuthContext);
 const getUserProfile = async (token_data) => {
    try {
      const token = token_data || (await isSignedIn());
      if (!token) {
        return null;
      }
      const res = await axios.get(DEV_API + "/users/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        return res;
      }
      return res;
    } catch (error) {
      console.log("Get User Profile error : ", error);
      if (error?.response) {
        console.log("error.response.status:", error.response.status);
        if (error.response.status === 401) await AsyncStorage.removeItem("user");
        return error.response;
      } else {
        throw error;
      }
    }
  };
  
  
 const GoogleSignInToken = async (type, token) => {
    try {
      const res = await axios.post(
        DEV_API + "/auth/google/signin",
        type == "id_token"
          ? {
              role: "vendor",
              id_token: token,
            }
          : {
              role: "vendor",
              access_token: token,
            }
      );
      console.log(res, "GoogleSignInToken response log");
      // navigate after 200 status same as login
      if (res.status === 200) {
        await AsyncStorage.setItem("user", JSON.stringify(res?.data?.token));
        console.log(res?.data?.token,"res?.data?.token");
        // socket.connect();
        setUser(true);
        setUserToken(res.data.token);
        return await getUserProfile(res?.data?.token);
      }
    } catch (error) {
      // setIsLoading(false);
      console.log(error, "GoogleSignInToken error log");
    }
  };
  


  const configureGoogleSignIn = () => {
    try {
      GoogleSignin.configure({
        // expoClientId:
        // "1049029176568-9m667iv9lb1fokt3h6vb0urnfg9m9r6q.apps.googleusercontent.com",
        webClientId:
          "1049029176568-8vkue4crnqh3qqontpnqs8jso1majv0u.apps.googleusercontent.com",
        iosClientId:
          "1049029176568-9pnda8i7jikauga0q86g4rng9q4dl7ne.apps.googleusercontent.com",
        // redirectUri:"http://localhost:19007",
        androidClientId:
          "1049029176568-2s2m7aklim211k56f5puhpa87npck496.apps.googleusercontent.com",
      });
    } catch (e) {
      console.error("Google Sign-In configuration error:", e);
    }
  };

  React.useEffect(() => {
    configureGoogleSignIn();
  });

  const signInWithGoogle = async () => {
    console.log("Pressed sign in");
    try {
      setIsAuthLoading(true);
      await GoogleSignin.hasPlayServices();
      const userInfo = await GoogleSignin.signIn();

      setIsLoading(true);
      // AsyncStorage.setItem("userDetail", userInfo);
      // setUser(true);
      // console.log(JSON.stringify(userInfo), "userinfo");
      // console.log(JSON.stringify(userInfo?.idToken), "userinfo idToken");
      // navigation.replace("app");
      // registerForPushNotificationsAsync();
      //   setUserInfo(userInfo);
      // setIsSigningIn(true);
      setError(null);
      console.log(userInfo.idToken,"userInfo.idToken");
      return await GoogleSignInToken("id_token", userInfo?.idToken);
      // let {res} = GoogleSignInToken();
      // console.log(res, "check1 log")
    } catch (e) {
      setIsLoading(false);
      // alert(e);
      console.log(e, "error mssgs");
      setError(e);
    }
    finally{
      setIsAuthLoading(false);
    }
  };


  const googleSignInButtonClick = async () => {
    // setIsLoading(true);
    const res = await signInWithGoogle();
    console.log(res?.data?.data, "res button");
    console.log(res, "res ");
    if (res?.status === 200) {
      showToast("success", "Success", res.data.message);
      setUser(true);
      
      // console.log(res?.data, "res check unique");
      // setUserToken(res.data.token);
      setAuthApiError(null);
      navigation.navigate("app", { screen: "home" });
    }
  };

  return (
    <View className="w-full my-2">
      {isLoading && <PleaseWaitModal showLoader={true} />}
      <Button
        label="Sign in with google"
        leftIcon={{
          name: "google-fill",
          color: colors.app.d1,
        }}
        waitState={isGoogleSigningIn}
        showLoaderWithText
        // // onPress={promptAsync}
        onPress={() => {
          googleSignInButtonClick();
          console.log("gs");
        }}
        // onPress={()=>console.log("google signin")}
      ></Button>
    </View>
  );
};

export default GoogleSigninFunction;

import { Platform } from "react-native";
import * as Linking from "expo-linking";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";

import { DEV_API } from "../src/services/api";
import axios from "axios";

const createDeepLinkUrl = ({ screen, id }) => {
  const baseUrl = "smbexcustomerredirect://";
  const deepLinkUrl = Linking.createURL(`${baseUrl}/${screen}/${id}`);
  console.log(screen, "screen");
  console.log("id : ", id);
  console.log("deepLink : ", deepLinkUrl);
  console.log("----------------------");
  console.log("\n\n\n");

  return deepLinkUrl;
};

export const useNotifications = () => {
  const registerPushNotificationApiCall = async (token, userToken) => {
    if (!token) return false;
    try {
      console.log(userToken);
      const res = await axios.post(
        DEV_API + `/notifications/tokens`,
        {
          user_device_token: token,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const registerForPushNotificationsAsync = async (userToken) => {
    if (Platform.OS === "web") {
      console.log("Push notifications are not supported on the web platform.");
      return null;
    }
    let token;
    if (Device.isDevice) {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        alert("Failed to get push token for push notification!");
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
      console.log("TOKEN", token);
      await registerPushNotificationApiCall(token, userToken);
    } else {
      alert("Must use physical device for Push Notifications");
    }

    if (Platform.OS === "android") {
      Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }

    return token;
  };
  // This listener is fired whenever a notification is received while the app is foreground
  const handleNotification = (notification) => {
    // could be useful if you want to display your own toast
    // could also make a server call to refresh data in other part of the app
    console.log(notification, "notification");
    return {
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: true,
    };
  };

  // This listener is fired whenever a user laps on or interacts with a notification [works when app is foreground, background]

  const handleNotificationResponse = async (response) => {
    const data = response.notification.request.content.data;

    console.log("\n\n\n\n", data, "useNotification data", "\n\n\n\n");
    console.log(response.notification, "response notification");
    if (data?.screen === "chat") {
      // Navigate to the specified screen
      console.log("\n\n\n\n\n", "chat open", "\n\n\n\n");
      if (Platform.OS === "web") {
        window.open(data.screen);
        // return null;
      } else {
        try {
          const deepLinkUrl = createDeepLinkUrl({
            screen: data.screen,
            id: data.channel_details?.customer_id,
          });
          await Linking.openURL(deepLinkUrl);
        } catch (error) {
          console.error("Error opening URL:", error);
        }
      }
    }
    if (data?.screen === "booking_details") {
      // Navigate to the specified screen
      if (Platform.OS === "web") {
        window.open(data.screen);
        // return null;
      } else {
        try {
          const deepLinkUrl = createDeepLinkUrl({
            screen: data.screen,
            id: data.booking_id,
          });
          await Linking.openURL(deepLinkUrl);
        } catch (error) {
          console.error("Error opening URL:", error);
        }
      }
    }
    if (data?.screen === "order_details") {
      if (Platform.OS === "web") {
        window.open(data.screen);
      } else {
        try {
          const deepLinkUrl = createDeepLinkUrl({
            screen: data.screen,
            id: data.product_order_id,
          });
          await Linking.openURL(deepLinkUrl);
        } catch (error) {
          console.error("Error opening URL:", error);
        }
      }
    }
  };

  return {
    registerForPushNotificationsAsync,
    handleNotification,
    handleNotificationResponse,
  };
};

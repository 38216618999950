import React from "react";
import { Pressable, ScrollView, Text, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { FaqItem } from "../../components/faq";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import { getPublicData, SharedContext } from "../../services/api";

export const AboutUs = ({ navigation, route }) => {
    return (
        <View className="flex-1 bg-app-e lg:bg-gray-50">
            <ScrollView
                className="p-4 rounded-t-2xl bg-gray-50"
                contentContainerStyle={{
                    flexGrow: 1,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
            <View className=" max-w-screen-lg w-full mx-auto">
                <View className="my-5 hidden lg:flex">
                    <Font className="text-lg font-semibold text-app-d">
                        About Us
                    </Font>
                </View>
                <View className="flex-col lg:flex-row max-w-screen-lg w-full">
                    <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
                        <Card title="Who we are">
                            <View className="w-full p-4 pt-0">
                                <Font className="text-app-d">
                                    SMBEx is a small business marketplace
                                    especially for conservative business owners.
                                    It brings businesses under one roof where
                                    customers can search deals, make
                                    appointments and book online. SMBEx brings
                                    the demand and supply together for local
                                    kingdom businesses to build a stronger
                                    community to serve local neighborhoods.
                                    SMBEx encourages businesses to adhere to
                                    good stewardship within local communities.
                                    SMBEx is about facilitating a thriving small
                                    business economy in a post-COVID era.
                                </Font>
                            </View>
                        </Card>
                        <Card title="Benefits">
                            <View className="w-full p-4 pt-0">
                                <Font className="text-app-d">
                                    In order to achieve this SMBEx facilitates
                                    an Airbnb-like platform to book
                                    accommodations for hosts registered on the
                                    network. All the business listing owners
                                    adhere to SMBEx policy guidelines to better
                                    serve the communities and guests. Guests
                                    booking through the site also comply to the
                                    terms of use of services offered. Besides
                                    accommodations, SMBEx currently offers 30
                                    different categories of businesses under
                                    which listings can enable clients to book
                                    appropriate services. Some common categories
                                    include listing and booking of events,
                                    transportation services, handyman services
                                    and area attractions among others. Guests
                                    can make searches that are location-based,
                                    date-based or category-based.
                                </Font>
                            </View>
                        </Card>
                        <Card title="Impact">
                            <View className="w-full p-4 pt-0">
                                <Font className="text-app-d">
                                    The SMBEx community members can make a
                                    social and kingdom impact within the local
                                    community through quality services and good
                                    stewardship. The customers enjoy good
                                    customer service and long-term savings
                                    through bonus points and reward system as
                                    well.
                                </Font>
                            </View>
                        </Card>
                    </View>
                    <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
                        <Widget></Widget>
                    </View>
                </View>
                <Footer></Footer>
                </View>
            </ScrollView>
        </View>
    );
};

import React, { useCallback, useContext, useEffect, useState } from "react";
import { Pressable, View, ScrollView } from "react-native";
import { Font } from "../../components/font";
import { Button, Input } from "../../components/input";
import { Footer } from "../../components/footer";
import { Card } from "../../components/card";
// import DateTimePicker from "@react-native-community/datetimepicker";
import { ResponseModal } from "../../components/response-modal";
import { BusinessesContext } from "../../context/businessesContext";
import {
  axiosPost,
  getListingById,
  getServiceCalendar,
  postBooking,
  postRescheduleBooking,
} from "../../services/api";
import moment from "moment/moment";
import { UserContext } from "../../context/userContext";
import { AuthContext } from "../../context/authContext";
import { ActivityIndicator } from "react-native";
import colors from "../../../colors";
import Toast from "react-native-toast-message";
import StripeCheckout from "../../components/stripe-checkout";
import { Platform } from "react-native";

class BookingErrorType {
  static createBookingFailed = "createBookingFailed";
  static paymentConfirmationFailed = "paymentConfirmationFailed";
}
class BookingError extends Error {
  constructor(type, message) {
    this.type = type;
    this.message = message;
  }
}

export function Booking({ route, navigation }) {
  // const { initPaymentSheet, presentPaymentSheet } = useStripe();
  const { userToken } = useContext(AuthContext);
  const { setIsUserLoading, isUserLoading } = useContext(UserContext);
  const { userData } = useContext(UserContext);
  const {
    businessDetails,
    isBusinessLoading,
    setIsBusinessLoading,
    setBusinessApiError,
    setBusinessDetails,
  } = useContext(BusinessesContext);
  const { bookableServiceId, listingId } = route.params;

  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkoutState, setCheckoutState] = useState({
    customerId: undefined,
    ephemeralKey: undefined,
    paymentIntentId: undefined,
    clientSecret: undefined,
    bookingId: undefined,
    visible: false,
    isReloadedPage: false,
  });
  const [allCalendarSlotDates, setAllCalendarSlotDates] = useState(null);
  const [serviceDetails, setServiceDetails] = useState(null);
  const [unitCount, setUnitCount] = useState(1);
  const [amount, setAmount] = useState({
    perProductAmount: 0,
    bookingAmount: 0,
    walletAmount: userData?.wallet_balance?.amount,
    totalAmount: 0,
    appliedWalletAmount: 0,
  });
  const [useWallet, setUseWallet] = useState(false);
  const [coupon, setCoupon] = useState({ code: null, amount: 0 });
  const [couponData, setCouponData] = useState(null);
  const [isCouponCodeValid, setIsCouponCodeValid] = useState({
    isValid: false,
    message: null,
    isValidating: false,
  });
  const [selectedSLot, setSelectedSlot] = useState({
    year: null,
    month: null,
    date: null,
    timeSlot: null,
  });
  const monthNames = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDate = moment().format("DD");
  const currentMonth = moment().format("M");

  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const selectedBookableService = () => {
    const serviceCategories = Object.keys(
      businessDetails?.bookable_services || {}
    );
    setAmount((amount) => {
      let calculations = amount;
      serviceCategories.forEach((e) => {
        businessDetails?.bookable_services[e].filter((ee) => {
          if (ee.id == bookableServiceId) {
            const reservedAmount =
              +(unitCount || 0) * +(amount.perProductAmount || 0);
            let total = reservedAmount,
              appliedWalletAmount = amount.appliedWalletAmount;
            total =
              total -
              (+(coupon.amount || 0) < total ? +(coupon.amount || 0) : total);

            if (reservedAmount < 100) {
              appliedWalletAmount = 0;
              setUseWallet(false);
            } else if (useWallet) {
              appliedWalletAmount =
                +(amount.walletAmount || 0) < total
                  ? +(amount.walletAmount || 0)
                  : total;
              total = total - appliedWalletAmount;
            }
            setServiceDetails(ee);
            calculations.perProductAmount = ee.service_unit?.price;
            calculations.bookingAmount = reservedAmount;
            calculations.appliedWalletAmount = appliedWalletAmount;
            calculations.totalAmount = total;
          }
        });
      });
      return { ...calculations };
    });
  };
  const getCalendar = async () => {
    try {
      setIsBusinessLoading(true);
      const res = await getServiceCalendar(listingId, bookableServiceId);
      if (res.status === 200) {
        if (Object.keys(res.data.data || {}).length > 0) {
          setAllCalendarSlotDates(res.data.data);
        }
      } else {
        console.log("booking, get calendar res error : ", res.data);
      }
    } catch (error) {
      console.log("booking, service calendar error : ", error);
    } finally {
      setIsBusinessLoading(false);
    }
  };
  const getBusinessDetails = async () => {
    try {
      setIsBusinessLoading(true);
      setBusinessApiError(null);
      const res = await getListingById({ id: route.params.listingId });
      if (res.status === 200) {
        await setBusinessDetails(res.data.data);
      } else {
        console.log("booking, get business details res error : ", res.data);
        setBusinessApiError(res.data);
      }
    } catch (error) {
      console.log("booking, get business details error : ", error);
    } finally {
      setIsBusinessLoading(false);
    }
  };
  useEffect(() => {
    if (businessDetails === null) {
      getBusinessDetails();
    }
    getCalendar();
  }, []);
  const selectNextAvailableDate = (year, month) => {
    if (allCalendarSlotDates && allCalendarSlotDates?.[year]?.[month]) {
      const datesAvailableInSelectedMonth = Object.keys(
        allCalendarSlotDates?.[year]?.[month] || {}
      );
      let selectedMonth = monthNames.indexOf(month) + 1;
      let nextAvailableDate;
      let indexOfnextDate;
      if (selectedMonth == currentMonth) {
        if (Number(datesAvailableInSelectedMonth[0]) >= Number(currentDate)) {
          nextAvailableDate = datesAvailableInSelectedMonth[0];
        } else {
          indexOfnextDate = datesAvailableInSelectedMonth.findIndex(
            (e) => Number(e) >= Number(currentDate)
          );
          if (indexOfnextDate > -1) {
            nextAvailableDate = datesAvailableInSelectedMonth[indexOfnextDate];
          }
        }
      } else if (selectedMonth > currentMonth) {
        nextAvailableDate = datesAvailableInSelectedMonth[0];
      }
      return nextAvailableDate;
    }
  };
  const selectNextAvailableTimeSlot = (year, month, date, type) => {
    if (allCalendarSlotDates && allCalendarSlotDates?.[year]?.[month]) {
      const datesAvailableInSelectedMonth = Object.keys(
        allCalendarSlotDates?.[year]?.[month] || {}
      );
      let indexOfnextDate;
      let nextAvailableTimeSlot;
      let selectedMonth = monthNames.indexOf(month) + 1;

      switch (type) {
        case "month-change":
          if (selectedMonth == currentMonth) {
            if (
              Number(datesAvailableInSelectedMonth[0]) >= Number(currentDate)
            ) {
              nextAvailableTimeSlot =
                allCalendarSlotDates?.[year]?.[month]?.[
                  datesAvailableInSelectedMonth?.[0]
                ]?.[0];
            } else {
              indexOfnextDate = datesAvailableInSelectedMonth.findIndex(
                (e) => Number(e) >= Number(currentDate)
              );
              if (indexOfnextDate > -1) {
                nextAvailableTimeSlot =
                  allCalendarSlotDates?.[year]?.[month]?.[
                    datesAvailableInSelectedMonth?.[indexOfnextDate]
                  ]?.[0];
              }
            }
          } else if (selectedMonth > currentMonth) {
            nextAvailableTimeSlot =
              allCalendarSlotDates?.[year]?.[month]?.[
                datesAvailableInSelectedMonth?.[0]
              ]?.[0];
          }
          break;
        case "date-change":
          nextAvailableTimeSlot =
            allCalendarSlotDates?.[year]?.[month]?.[date]?.[0];
          break;

        default:
          break;
      }
      return nextAvailableTimeSlot;
    }
  };
  useEffect(() => {
    if (allCalendarSlotDates) {
      const today = {
        year: new Date().getFullYear().toString(),
        month: monthNames[new Date().getMonth()],
      };
      setSelectedSlot({
        ...selectedSLot,
        year: today.year,
        month: today.month,
        // date: allCalendarSlotDates ? nextAvailableDate : currentDate,
        date: selectNextAvailableDate(today.year, today.month),
        // timeSlot:
        //   allCalendarSlotDates &&
        //   nextAvailableDate &&
        //   allCalendarSlotDates[new Date().getFullYear().toString()][
        //     monthNames[new Date().getMonth()]
        //   ][datesAvailableInCurrentMonth[indexOfnextDate]][0],
        timeSlot: selectNextAvailableTimeSlot(
          today.year,
          today.month,
          null,
          "month-change"
        ),
      });
    }
  }, [allCalendarSlotDates]);
  useEffect(() => {
    if (businessDetails !== null) {
      selectedBookableService();
    }
  }, [businessDetails]);

  // ----------------------------- date picker --------------------
  const [date, setDate] = useState(new Date());
  const [mode, setMode] = useState("date");
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState({
    isVisible: false,
    message: "Booked Successfully",
    showSecondaryButton: true,
    dismiss: true,
  });

  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date;
    setShow(false);
    setDate(currentDate);
  };

  const showMode = (currentMode) => {
    setShow(true);
    // if (Platform.OS === "android") {
    //   setShow(false);
    // }
    setMode(currentMode);
  };

  const showDatepicker = () => {
    showMode("date");
  };

  const showTimepicker = () => {
    showMode("time");
  };

  useEffect(
    Platform.OS === "web"
      ? () => {
          const clientSecret = route.params?.payment_intent_client_secret;

          if (!clientSecret) {
            return;
          }
          setCheckoutState((v) => ({
            ...v,
            clientSecret: clientSecret,
            visible: true,
            isReloadedPage: true,
            bookingId: route.params?.bid,
            customerId: route.params?.cid,
          }));
        }
      : () => {},
    []
  );

  const back = navigation.canGoBack();
  const dismiss = () => {
    navigation.goBack();
  };
  const validateCoupon = async (e) => {
    setIsCouponCodeValid((e) => ({ ...e, isValidating: true }));

    // if (amount.bookingAmount <= 1000) {
    //   setIsCouponCodeValid((ee) => ({
    //     ...ee,
    //     isValid: false,
    //     message: `Applicable above $1000.`,
    //     isValidating: false,
    //   }));
    //   return ;
    // }
    const res = await axiosPost({
      Url: "/coupons/check",
      token: userToken,
      data: {
        code: coupon.code,
        listing_id: listingId,
        check_for: "service_booking",
      },
    })
      .then((res) => {
        showToast("success", "Success", "Coupon applied!");
        setIsCouponCodeValid((ee) => ({
          ...ee,
          isValid: true,
          message: "Coupon applied!",
          isValidating: false,
        }));
        if (amount.bookingAmount <= res.data.data.coupon?.minimum_amount) {
          return setIsCouponCodeValid((ee) => ({
            ...ee,
            isValid: false,
            message: `Applicable above $ ${res.data.data.coupon?.minimum_amount}.`,
            isValidating: false,
          }));
        }
        let couponAmount = 0;
        if (res.data.data.coupon.flat_discount) {
          couponAmount = res.data.data.coupon.flat_discount;
        } else if (res.data.data.coupon.discount_percentage_multiplier) {
          couponAmount =
            amount.bookingAmount *
            res.data.data.coupon.discount_percentage_multiplier;
          if (couponAmount > res.data.data.coupon.max_discount) {
            couponAmount = res.data.data.coupon.max_discount;
          }
        }
        setCoupon({ ...coupon, amount: couponAmount });
        setAmount({
          ...amount,
          totalAmount: useWallet
            ? amount.bookingAmount - couponAmount - amount.walletAmount
            : amount.bookingAmount - couponAmount,
        });
      })
      .catch((error) => {
        if (error.status === 400) {
          console.log("booking, validateCoupon error 1 : ", error.data);
          setIsCouponCodeValid((ee) => ({ ...ee, isValidating: false }));
          setIsCouponCodeValid({
            isValid: false,
            message: error.data.message,
          });
          const couponAmount = 0;
          setCoupon({ ...coupon, amount: couponAmount });
          setAmount({
            ...amount,
            totalAmount: useWallet
              ? amount.bookingAmount - couponAmount - amount.walletAmount
              : amount.bookingAmount - couponAmount,
          });
          showToast("error", "Error", error.data.message);
        } else {
          console.log("booking, validateCoupon error 2 : ", error);
        }
        setIsCouponCodeValid((ee) => ({ ...ee, isValidating: false }));
      })
      .finally(() => {
        setIsCouponCodeValid((ee) => ({ ...ee, isValidating: false }));
      });
  };
  const totalProductAmount = (e) => {
    e = +(e || 0);
    const reservedAmount = e * +(amount.perProductAmount || 0);
    let total = reservedAmount,
      appliedWalletAmount = amount.appliedWalletAmount;

    total =
      total - (+(coupon.amount || 0) < total ? +(coupon.amount || 0) : total);

    if (reservedAmount < 100) {
      appliedWalletAmount = 0;
      setUseWallet(false);
    } else if (useWallet) {
      appliedWalletAmount =
        +(amount.walletAmount || 0) < total
          ? +(amount.walletAmount || 0)
          : total;
      total = total - appliedWalletAmount;
    }
    setAmount((amount) => ({
      ...amount,
      bookingAmount: reservedAmount,
      totalAmount: total,
      appliedWalletAmount,
    }));
  };

  // -------------------------- on book now ---------------------------
  const onBookNow = async () => {
    if (checkoutState.clientSecret) {
      if (Platform.OS === "web" || checkoutState.ephemeralKey) {
        setIsCheckoutLoading(true);
        if (checkoutData.visible) {
          setCheckoutState((v) => ({
            ...v,
            visible: false,
          }));
        }
        setTimeout(() => {
          setCheckoutState((v) => ({
            ...v,
            visible: true,
            isReloadedPage: true,
          }));
        }, 2000);
        return;
      }
    }
    if (selectedSLot.timeSlot !== null) {
      try {
        setIsUserLoading(true);
        if (route.params.reschedule) {
          const res = await postRescheduleBooking({
            token: userToken,
            booking_id: route.params.bookingId,
            session_id: route.params.sessionId,
            calendar_slot_id: selectedSLot.timeSlot.id,
          });
          if (res.status === 200) {
            setModal((e) => ({
              isVisible: true,
              message: res.data.message,
              showSecondaryButton: true,
              dismiss: true,
            }));
          } else {
            setModal((e) => ({
              isVisible: true,
              message: res.data.message,
              showSecondaryButton: false,
              dismiss: false,
            }));
            console.log("booking, booknow reschedule res error: ", res.data);
          }
        } else {
          let data = {
            listing_id: businessDetails?.id,
            service_id: serviceDetails.id,
            service_unit_id: serviceDetails.service_unit.id,
            service_unit_quantity: unitCount,
            calendar_slot_id: selectedSLot.timeSlot.id,
          };
          if (isCouponCodeValid.isValid) {
            const apply_coupon = {
              coupon_id: 0,
              coupon_code: coupon.code,
            };
            data.apply_coupon = apply_coupon;
          }
          if (useWallet) {
            data.redeemed_rewards = userData?.wallet_balance?.amount;
          }
          const res = await postBooking({ token: userToken, data });
          if (res.status === 201) {
            // const initPayment = await initializePaymentSheet({
            //   customer: res.data.data.customer_id,
            //   ephemeralKey: res.data.data.ephemeral_key,
            //   paymentIntent: res.data.data.payment_intent,
            //   bookingId: res.data.data.service_booking.id,
            // });
            const clientSecret =
              res.data.data.payment_intent ||
              res.data.data.subscription.latest_invoice.setup_intent
                .client_secret;
            const paymentIntentId =
              res.data?.data?.subscription?.latest_invoice?.payment_intent?.id;
            setIsCheckoutLoading(true);
            setCheckoutState((v) => ({
              ...v,
              customerId: res.data.data.customer_id,
              ephemeralKey: res.data.data.ephemeral_key,
              paymentIntentId: paymentIntentId,
              clientSecret: clientSecret,
              bookingId: res.data.data.service_booking.id,
              visible: true,
              isReloadedPage: false,
            }));
            if (Platform.OS === "web") {
              navigation.setParams({
                payment_intent: paymentIntentId || "",
                payment_intent_client_secret: clientSecret,
                redirect_status: "initiated",
                bid: res.data.data.service_booking.id,
                cid: res.data.data.customer_id,
              });
            }
          } else {
            setModal((e) => ({
              isVisible: true,
              message: res.data.message,
              showSecondaryButton: false,
              dismiss: false,
            }));
            console.log("booking, post booknow  res error : ", res.data);
          }
        }
      } catch (error) {
        console.log("booking, booknow error--- : ", error);
      } finally {
        setIsUserLoading(false);
      }
    }
  };
  const calculateOpeningHours = ({ startTime, closeTime }) => {
    const startHr = Math.floor(startTime / 60);
    const startMin = Math.floor(startTime % 60);
    const closeHr = Math.floor(closeTime / 60);
    const closeMin = Math.floor(closeTime % 60);

    const opensAt =
      (startHr <= 12 ? startHr : startHr - 12) +
      ":" +
      (startMin < 10 ? `0${startMin}` : startMin) +
      " " +
      (startHr < 12 ? "AM" : "PM");
    const closesAt =
      (closeHr <= 12 ? closeHr : closeHr - 12) +
      ":" +
      (closeMin < 10 ? `0${closeMin}` : closeMin) +
      " " +
      (closeHr < 12 ? "AM" : "PM");
    return opensAt + " - " + closesAt;
  };

  // -------------------------- initialize payment intent ---------------------------

  // const initializePaymentSheet = async ({
  //   paymentIntent,
  //   ephemeralKey,
  //   customer,
  //   bookingId,
  // }) => {
  //   const { error } = await initPaymentSheet({
  //     applePay: true,
  //     googlePay: true,
  //     merchantDisplayName: "Smbex",
  //     customerId: customer,
  //     customerEphemeralKeySecret: ephemeralKey,
  //     paymentIntentClientSecret: paymentIntent,
  //     // Set `allowsDelayedPaymentMethods` to true if your business can handle payment
  //     //methods that complete payment after a delay, like SEPA Debit and Sofort.
  //     allowsDelayedPaymentMethods: false,
  //     defaultBillingDetails: {
  //       name: "Jane Doe",
  //     },
  //   });
  //   if (!error) {
  //     await new Promise((resolve) =>
  //       setTimeout(() => {
  //         resolve();
  //       }, 2000)
  //     );
  //     const checkoutError = await onCheckout();
  //     if (!checkoutError) {
  //       try {
  //         let res = await axiosPost({
  //           Url: `/bookings/service/${bookingId}/payment/confirm`,
  //           token: userToken,
  //         });
  //         setModal((e) => ({
  //           isVisible: true,
  //           message: res.data.message,
  //           showSecondaryButton: true,
  //           dismiss: true,
  //         }));
  //       } catch (error) {
  //         showToast("error", "Error", "Something went wrong.");
  //         setModal((e) => ({
  //           isVisible: true,
  //           message:
  //             "Booking confirmed, payment confirmation failed. Please check my bookings.",
  //           showSecondaryButton: true,
  //           dismiss: true,
  //         }));
  //         console.log("bookings, onCheckout error: ", error);
  //       }
  //     } else {
  //       setIsUserLoading(false);
  //     }
  //     setLoading(true);
  //   } else {
  //     setIsUserLoading(true);
  //     console.log("bookings, initializePaymentSheet error : ", error);
  //   }
  // };

  // -------------------------- present payment sheet ---------------------------
  // const onCheckout = async () => {
  //   const presentSheet = await presentPaymentSheet();
  //   if (presentSheet.error) {
  //     setIsUserLoading(true);
  //     return true;
  //   } else {
  //     setIsUserLoading(true);
  //     return false;
  //   }
  // };

  const onPaymentSuccess = useCallback(async (data) => {
    try {
      // setCart([]);
      if (Platform.OS === "web") {
        navigation.setParams({
          payment_intent: undefined,
          payment_intent_client_secret: undefined,
          redirect_status: undefined,
          bid: undefined,
          cid: undefined,
        });
      }
      setCheckoutState((v) => ({
        ...v,
        customerId: undefined,
        ephemeralKey: undefined,
        paymentIntentId: undefined,
        clientSecret: undefined,
        bookingId: null,
        visible: false,
        isReloadedPage: false,
      }));
      let res = await axiosPost({
        Url: `/bookings/service/${data.bookingId}/payment/confirm`,
        token: userToken,
      });
      setModal((e) => ({
        isVisible: true,
        message: res.data.message || "Booking Confirmed!",
        showSecondaryButton: true,
        dismiss: true,
      }));
    } catch (error) {
      showToast("error", "Error", error);
      setModal((e) => ({
        isVisible: true,
        message:
          "Booking confirmed, payment confirmation failed. Please check my bookings.",
        showSecondaryButton: true,
        dismiss: true,
      }));
      console.log("bookings, onCheckout error: ", error);
    }
  }, []);

  const onPaymentError = useCallback((error, paymentIntent) => {
    if (
      (
        error?.payment_intent?.status ||
        paymentIntent?.status ||
        error?.code ||
        ""
      ).toLowerCase() === "canceled"
    ) {
      setCheckoutState((v) => ({
        ...v,
        ...(Platform.OS === "web"
          ? {
              customerId: undefined,
              ephemeralKey: undefined,
              paymentIntentId: undefined,
              clientSecret: undefined,
              bookingId: null,
            }
          : {}),
        visible: false,
        isReloadedPage: false,
      }));
    }
    showToast(
      "error",
      error.code,
      error?.message === error?.localizedMessage
        ? error.localizedMessage
        : `${error.message}\t\n\n${error.localizedMessage || ""}`
    );
  }, []);

  return (
    <StripeCheckout
      onSuccess={onPaymentSuccess}
      onError={onPaymentError}
      checkoutData={checkoutState}
      isReloadedPage={checkoutState.isReloadedPage}
      visible={checkoutState.visible}
      onRequestClose={() =>
        setCheckoutState((v) => ({
          ...v,
          visible: false,
        }))
      }
      onCheckoutLoad={() => setIsCheckoutLoading(false)}
    >
      <View className="flex-1 bg-app-e lg:bg-app-a lg:items-center">
        <View className="flex-1  w-full lg:w-1/2">
          <View className="flex-1 rounded-t-2xl lg:rounded-none overflow-hidden bg-app-a">
            <ScrollView>
              <View className="flex-1 h-full w-full p-4 items-center">
                <View className="flex-col max-w-screen-lg w-full">
                  {/* ------------------------- new ---------------------- */}
                  {/* <Card title="Date">
                  <View className="flex w-full">
                    <View className="items-center pb-4 pt-0">
                      <View className="w-full pb-5 items-center">
                        <Font weight={500} className="text-base">
                          Selected
                        </Font>
                        <Font
                          weight={700}
                          className="text-[20px] text-gray-800"
                        >
                          {date.toDateString()}
                        </Font>
                      </View>
                      <View className="w-3/4 ">
                        <Button
                          onPress={showDatepicker}
                          label="Pick a date!"
                          type="primary"
                        />
                      </View>
                      {show && (
                        <DateTimePicker
                          testID="dateTimePicker"
                          value={date}
                          mode={mode}
                          is24Hour={true}
                          display="default"
                          minimumDate={new Date()}
                          onChange={onChange}
                        />
                      )}
                    </View>
                  </View>
                </Card> */}

                  <View className="flex-1">
                    <Card title="Choose the slot">
                      {allCalendarSlotDates && !isBusinessLoading ? (
                        <View className="flex-1 w-full">
                          {Object.keys(allCalendarSlotDates || {}).length >
                            1 && (
                            <View className="flex-1 w-full pb-2 px-3">
                              <ScrollView horizontal>
                                <View className="flex-row w-full gap-3">
                                  {allCalendarSlotDates &&
                                    Object.keys(allCalendarSlotDates || {}).map(
                                      (e, i) => (
                                        <Pressable
                                          className={`w-32 h-10 items-center justify-center rounded-lg active:bg-app-c1  ${
                                            selectedSLot?.year === e
                                              ? " border-2 border-app-e"
                                              : "border border-gray-200"
                                          }`}
                                          key={`year_${i}`}
                                          onPress={() =>
                                            setSelectedSlot((ee) => ({
                                              ...ee,
                                              year: e,
                                            }))
                                          }
                                        >
                                          <Font
                                            weight={
                                              selectedSLot?.year === e && 600
                                            }
                                          >
                                            {e}
                                          </Font>
                                        </Pressable>
                                      )
                                    )}
                                </View>
                              </ScrollView>
                            </View>
                          )}
                          {Object.keys(
                            allCalendarSlotDates?.[selectedSLot?.year] || {}
                          ).length > 1 && (
                            <View className="flex w-full pb-2 px-3">
                              <ScrollView horizontal>
                                <View className="flex-row w-full gap-3">
                                  {allCalendarSlotDates &&
                                    Object.keys(
                                      allCalendarSlotDates?.[
                                        selectedSLot?.year
                                      ] || {}
                                    ).map((e, i) => {
                                      if (
                                        monthNames.indexOf(e) + 1 >=
                                        Number(currentMonth)
                                      ) {
                                        return (
                                          <Pressable
                                            className={`w-32 h-10 items-center justify-center rounded-lg active:bg-app-c1 ${
                                              selectedSLot?.month === e
                                                ? " border-2 border-app-e"
                                                : "border border-gray-200"
                                            }`}
                                            key={`month_${i}`}
                                            onPress={() => {
                                              setSelectedSlot((ee) => ({
                                                ...ee,
                                                month: e,
                                                date: selectNextAvailableDate(
                                                  ee.year,
                                                  e
                                                ),
                                                // date: Object.keys(
                                                //   allCalendarSlotDates[ee.year][e]
                                                // )[0],
                                                timeSlot:
                                                  selectNextAvailableTimeSlot(
                                                    ee.year,
                                                    e,
                                                    null,
                                                    "month-change"
                                                  ),
                                              }));
                                              setUnitCount(1);
                                            }}
                                          >
                                            <Font
                                              weight={
                                                selectedSLot?.month === e && 600
                                              }
                                            >
                                              {e}
                                            </Font>
                                          </Pressable>
                                        );
                                      }
                                    })}
                                </View>
                              </ScrollView>
                            </View>
                          )}
                          <View className="flex w-full pb-2 px-3">
                            <ScrollView horizontal>
                              <View className="flex-row w-full gap-3">
                                {allCalendarSlotDates &&
                                  selectedSLot.date &&
                                  Object.keys(
                                    allCalendarSlotDates?.[
                                      selectedSLot?.year
                                    ]?.[selectedSLot?.month] || {}
                                  ).map((e, i) => {
                                    let displayDate =
                                      (monthNames.indexOf(selectedSLot?.month) +
                                        1 ==
                                        currentMonth &&
                                        Number(e) >= Number(currentDate)) ||
                                      monthNames.indexOf(selectedSLot?.month) +
                                        1 >
                                        currentMonth;
                                    if (displayDate) {
                                      return (
                                        <Pressable
                                          className={`w-36 h-14 items-center justify-center rounded-lg active:bg-app-c1 ${
                                            selectedSLot?.date === e
                                              ? " border-2 border-app-e"
                                              : "border border-gray-200"
                                          } ${e < currentDate && "bg-app-b"}`}
                                          key={`date_${i}`}
                                          onPress={() => {
                                            setSelectedSlot((ee) => ({
                                              ...ee,
                                              date: e,
                                              timeSlot:
                                                selectNextAvailableTimeSlot(
                                                  ee.year,
                                                  ee.month,
                                                  e,
                                                  "date-change"
                                                ),
                                            }));
                                            setUnitCount(1);
                                          }}
                                        >
                                          <View className="flex-col w-full items-center">
                                            <Font
                                              weight={
                                                selectedSLot?.date === e && 600
                                              }
                                            >
                                              {`${
                                                dayNames[
                                                  new Date(
                                                    `${selectedSLot.month}-${e}-${selectedSLot.year}`
                                                  ).getDay()
                                                ]
                                              } ${e}`}
                                            </Font>
                                            <Font
                                              className="text-app-d1"
                                              weight={
                                                selectedSLot?.date === e && 600
                                              }
                                            >
                                              {`${
                                                allCalendarSlotDates[
                                                  selectedSLot?.year
                                                ][selectedSLot?.month][e].length
                                              } ${
                                                allCalendarSlotDates[
                                                  selectedSLot?.year
                                                ][selectedSLot?.month][e]
                                                  .length === 1
                                                  ? "slot available."
                                                  : "slots available."
                                              }`}
                                            </Font>
                                          </View>
                                        </Pressable>
                                      );
                                    }
                                  })}
                              </View>
                            </ScrollView>
                            {allCalendarSlotDates &&
                              (!selectedSLot.month ? (
                                <View className="flex-1 w-full items-center justify-center pt-3">
                                  <Font className="text-app-d1">
                                    Please select month.
                                  </Font>
                                </View>
                              ) : (
                                !selectedSLot.date && (
                                  <View className="flex-1 w-full items-center justify-center pt-3">
                                    <Font className="text-app-d1">
                                      No dates available in this month.
                                    </Font>
                                  </View>
                                )
                              ))}
                          </View>
                        </View>
                      ) : allCalendarSlotDates === null &&
                        !isBusinessLoading ? (
                        <View className="flex-1 w-full items-center justify-center">
                          <Font className="text-app-d1">
                            No dates available.
                          </Font>
                        </View>
                      ) : (
                        <View className="flex-1 w-full items-center justify-center">
                          <ActivityIndicator
                            size={"small"}
                            color={colors.app.e}
                          ></ActivityIndicator>
                        </View>
                      )}
                    </Card>
                  </View>
                  <View className="flex-1">
                    <Card title="Slots available">
                      <View className="flex-1 w-full px-2">
                        <View className="flex-row w-full items-center flex-wrap">
                          {allCalendarSlotDates &&
                          !isBusinessLoading &&
                          selectedSLot?.date ? (
                            allCalendarSlotDates[selectedSLot?.year]?.[
                              selectedSLot?.month
                            ]?.[selectedSLot.date]?.map((e, i) => (
                              <Pressable
                                className={`w-36 h-10 rounded-lg m-2 active:bg-app-c1 ${
                                  selectedSLot?.timeSlot?.id === e.id
                                    ? " border-2 border-app-e"
                                    : " border border-gray-200"
                                }`}
                                key={`timeslot_${i}`}
                                onPress={() => {
                                  setSelectedSlot({
                                    ...selectedSLot,
                                    timeSlot: e,
                                  });
                                  // getSlotDetails(e.id);
                                  setUnitCount(1);
                                }}
                              >
                                <View className="h-full flex-row w-full items-center justify-center">
                                  {/* <View className="h-5 absolute w-1 bg-app-e"></View> */}
                                  <View className="h-5">
                                    <Font
                                      weight={
                                        selectedSLot?.timeSlot?.id === e.id &&
                                        600
                                      }
                                    >
                                      {calculateOpeningHours({
                                        startTime: e.start_in_min,
                                        closeTime: e.end_in_min,
                                      })}
                                    </Font>
                                  </View>
                                </View>
                              </Pressable>
                            ))
                          ) : (allCalendarSlotDates &&
                              !isBusinessLoading &&
                              !selectedSLot.date) ||
                            (allCalendarSlotDates === null &&
                              !isBusinessLoading) ? (
                            <View className="flex-1 w-full items-center justify-center">
                              <Font className="text-app-d1">
                                No time slots available.
                              </Font>
                            </View>
                          ) : (
                            <View className="flex-1 w-full items-center justify-center">
                              <ActivityIndicator
                                size={"small"}
                                color={colors.app.e}
                              ></ActivityIndicator>
                            </View>
                          )}
                        </View>
                      </View>
                    </Card>
                  </View>
                  <View className="flex-1">
                    <Card title="Note">
                      {serviceDetails && !isBusinessLoading ? (
                        <View className="flex-1 w-full px-4">
                          <View className="w-full flex-row">
                            <Font className="text-app-d1 flex-1">
                              {serviceDetails.allow_reschedule
                                ? "Rescheduling of the timing slots is available post booking"
                                : "Rescheduling of the timing slots post booking isn't allowed"}
                            </Font>
                          </View>
                          {serviceDetails.cancellation_penalty && (
                            <View className="w-full flex-row pt-3">
                              <Font className="text-app-d1">
                                Cancellation charges will be{" "}
                              </Font>
                              <Font className="text-app-d1" weight={600}>
                                {serviceDetails.cancellation_penalty} %
                              </Font>
                            </View>
                          )}
                        </View>
                      ) : (
                        <View className="flex-1 w-full items-center justify-center">
                          <ActivityIndicator
                            size={"small"}
                            color={colors.app.e}
                          ></ActivityIndicator>
                        </View>
                      )}
                    </Card>
                  </View>
                  {!route.params.reschedule && (
                    <View className="flex-1 w-full">
                      <Card
                        title={
                          serviceDetails !== null &&
                          `$${serviceDetails?.service_unit?.price / 100}/ ${
                            serviceDetails?.service_unit?.name
                          }`
                        }
                      >
                        <View className="w-full px-4">
                          <View className="flex-1 w-full pb-4 pt-0">
                            <Input
                              type="counter"
                              label={`Number of  ${serviceDetails?.service_unit?.name}s`}
                              setValue={(e) => {
                                setUnitCount(e);
                                totalProductAmount(e);
                              }}
                              value={unitCount}
                              isIncrementDisabled={
                                selectedSLot?.timeSlot
                                  ?.service_unit_capacity === 0 ||
                                (serviceDetails?.service_unit
                                  ?.allow_multiple_per_availability_slot
                                  ? selectedSLot?.timeSlot
                                      ?.service_unit_capacity >
                                    serviceDetails?.service_unit
                                      ?.max_per_booking
                                    ? unitCount ===
                                      serviceDetails?.service_unit
                                        ?.max_per_booking
                                    : unitCount ===
                                      selectedSLot?.timeSlot
                                        ?.service_unit_capacity
                                  : unitCount === 1)
                              }
                              isDecrementDisabled={
                                selectedSLot?.timeSlot
                                  ?.service_unit_capacity === 0 ||
                                unitCount === 1 ||
                                serviceDetails?.service_unit
                                  ?.max_per_booking === 1
                              }
                            ></Input>
                          </View>

                          {unitCount !== 1 &&
                          selectedSLot?.timeSlot?.service_unit_capacity !== 0 &&
                          (selectedSLot?.timeSlot?.service_unit_capacity >
                          serviceDetails?.service_unit?.max_per_booking
                            ? unitCount ===
                              serviceDetails?.service_unit?.max_per_booking
                            : unitCount ===
                              selectedSLot?.timeSlot?.service_unit_capacity) ? (
                            <View className="flex-1 w-full pt-1 lg:pt-4">
                              <Font className="text-app-e">
                                Max booking limit reached.
                              </Font>
                            </View>
                          ) : (unitCount === 1 &&
                              serviceDetails?.service_unit?.max_per_booking ===
                                1) ||
                            selectedSLot?.timeSlot?.service_unit_capacity ===
                              1 ? (
                            <View className="flex-1 w-full pt-1 lg:pt-4">
                              <Font className="text-app-e">
                                Only one booking available.
                              </Font>
                            </View>
                          ) : (
                            selectedSLot?.timeSlot?.service_unit_capacity ===
                              0 && (
                              <View className="flex-1 w-full pt-1 lg:pt-4">
                                <Font className="text-app-e">
                                  No units available for booking.
                                </Font>
                              </View>
                            )
                          )}
                        </View>
                      </Card>
                    </View>
                  )}
                  {/* ------------------------ extra services ------------------------ */}
                  {/* <Card title="Extra Services">
                  <View className="flex-1 bg-app-d">
                    <View className="p-4 pt-0 bg-app-i">
                      <ScrollView className="gap-x-4 py-2" horizontal>
                        {extraServices.map((e, i) => (
                          <Pressable
                            className={`w-44 h-20 border rounded-lg ${
                              extraService === e.id
                                ? "bg-app-e border-app-e"
                                : "border-gray-200 bg-app-b"
                            }`}
                            key={i}
                            onPress={() => setExtraService(e.id)}
                          >
                            <View className="h-12 items-center flex-row w-full">
                              <View
                                className={`h-6 absolute w-1 ${
                                  extraService === e.id
                                    ? "bg-app-a"
                                    : "bg-app-e"
                                }`}
                              ></View>
                              <Font
                                className={`ml-4 w-full ${
                                  extraService === e.id &&
                                  "text-app-a font-bold"
                                }`}
                              >
                                {e.name}
                              </Font>
                            </View>
                            <View className="items-center flex-row w-full">
                              <Font
                                className={`ml-4 w-full ${
                                  extraService === e.id
                                    ? "text-app-a font-bold"
                                    : "text-app-h"
                                }`}
                              >{`$ ${e.cost}`}</Font>
                            </View>
                          </Pressable>
                        ))}
                      </ScrollView>
                    </View>
                  </View>
                </Card> */}
                  {!route.params.reschedule && (
                    <View className="flex-1 w-full">
                      <Card title="Have a coupon?">
                        <View className="flex-1 w-full px-4 pt-0">
                          <View className="w-full">
                            <Input
                              type="coupon"
                              // label={"Have a coupon?"}
                              value={coupon.code}
                              setValue={(e) => {
                                if (
                                  coupon.code !== "" ||
                                  e === "" ||
                                  (coupon.code !== null && e !== coupon.code)
                                ) {
                                  setIsCouponCodeValid({
                                    isValid: false,
                                    message: null,
                                  });
                                  setAmount({
                                    ...amount,
                                    totalAmount: useWallet
                                      ? amount.bookingAmount -
                                        amount.walletAmount
                                      : amount.bookingAmount,
                                  });
                                }
                                setCoupon({ ...coupon, code: e });
                              }}
                              action={(e) => {
                                if (
                                  coupon.code !== "" &&
                                  coupon.code !== null
                                ) {
                                  validateCoupon(e);
                                }
                              }}
                              placeholder="Coupon code"
                              submitText="Apply"
                              isSubmitting={isCouponCodeValid.isValidating}
                            ></Input>
                          </View>
                          <View className=" w-full flex-1 pt-2 pl-1">
                            <Font
                              className={`${
                                isCouponCodeValid.isValid
                                  ? "text-app-g"
                                  : "text-app-e"
                              }`}
                            >
                              {isCouponCodeValid.message}
                            </Font>
                          </View>
                        </View>
                      </Card>
                    </View>
                  )}
                  <View className="flex-1 w-full">
                    <Card title="Payment Summary">
                      <View className="flex-1 p-4 pt-0 w-full">
                        <View className="flex-row w-full rounded-lg shadow border border-app-c p-4">
                          <Font className="flex-1 text-app-d">
                            Wallet Balance
                          </Font>
                          <Font className="text-app-d" weight={600}>
                            $ {(amount.walletAmount || 0) / 100}
                          </Font>
                        </View>
                        <View className="flex-col w-full my-2">
                          {!useWallet &&
                            (amount.totalAmount || 0) / 100 < 1 && (
                              <Font className="flex-1 text-app-e text-xs">
                                Note: Total amount should be greater or equal to
                                $&nbsp;1 to use the available wallet balance.
                              </Font>
                            )}
                          <Input
                            type="checkbox"
                            label="Use wallet balance"
                            value={useWallet}
                            setValue={(ee) => {
                              if ((amount.bookingAmount || 0) >= 100) {
                                console.log("ee:", ee);
                                if (ee) {
                                  setAmount((amount) => {
                                    const appliedWalletAmount =
                                      +(amount.walletAmount || 0) <
                                      amount.totalAmount
                                        ? +(amount.walletAmount || 0)
                                        : amount.totalAmount;
                                    return {
                                      ...amount,
                                      totalAmount:
                                        amount.totalAmount -
                                        appliedWalletAmount,
                                      appliedWalletAmount,
                                    };
                                  });
                                } else {
                                  setAmount((amount) => ({
                                    ...amount,
                                    totalAmount:
                                      amount.totalAmount +
                                      amount.appliedWalletAmount,
                                    appliedWalletAmount: 0,
                                  }));
                                }
                                setUseWallet(ee);
                              }
                            }}
                          ></Input>
                        </View>
                        {useWallet && (
                          <View className="flex-row w-full mt-2">
                            <Font className="flex-1 text-app-d">
                              Applied wallet amount
                            </Font>
                            <Font className="text-app-g" weight={600}>
                              $ {amount.appliedWalletAmount / 100}
                            </Font>
                          </View>
                        )}
                        {isCouponCodeValid.isValid && (
                          <>
                            <View className="flex-row w-full mt-2">
                              <Font className="flex-1 text-app-d">Coupon</Font>
                              <Font className="text-app-g" weight={600}>
                                {coupon.code}
                              </Font>
                            </View>
                            <View className="flex-row w-full mt-2">
                              <Font className="flex-1 text-app-d">
                                Coupon discount
                              </Font>
                              <Font className="text-app-g" weight={600}>
                                ${" "}
                                {(+(coupon.amount || 0) < amount.totalAmount
                                  ? +(coupon.amount || 0)
                                  : amount.totalAmount) / 100}
                              </Font>
                            </View>
                          </>
                        )}
                        <View className="flex-row w-full">
                          <Font className="flex-1 text-app-d">
                            Booking amount
                          </Font>
                          <Font className="text-app-d" weight={600}>
                            $ {amount.bookingAmount / 100}
                          </Font>
                        </View>
                        <View className="flex-row w-full">
                          <Font className="flex-1 text-app-d">
                            Total discounts
                          </Font>
                          <Font className="text-app-e" weight={600}>
                            - ${" "}
                            {(+(amount.appliedWalletAmount || 0) +
                              (+(coupon.amount || 0) < amount.totalAmount
                                ? +(coupon.amount || 0)
                                : amount.totalAmount) || 0) / 100}
                          </Font>
                        </View>
                        <View className="mt-4 mb-3 border-b border-app-c" />
                        <View className="flex-row w-full">
                          <Font
                            className="flex-1 leading-4 text-app-d"
                            weight={700}
                          >
                            Total Payment
                          </Font>
                          <Font className="text-app-d" weight={600}>
                            $ {amount.totalAmount / 100}
                          </Font>
                        </View>
                      </View>
                      <View className="w-3/4 my-2">
                        {isUserLoading || isCheckoutLoading ? (
                          <View className="flex-1 bg-app-e h-10 rounded-lg justify-center">
                            <ActivityIndicator
                              size={"small"}
                              color={colors.app.a}
                            ></ActivityIndicator>
                          </View>
                        ) : (
                          <Button
                            type={
                              !selectedSLot.timeSlot ||
                              selectedSLot?.timeSlot?.service_unit_capacity ===
                                0
                                ? "disabled"
                                : "primary"
                            }
                            label={`${
                              route.params.reschedule
                                ? "Reschedule"
                                : "Book Now"
                            }`}
                            onPress={
                              !selectedSLot.timeSlot ||
                              selectedSLot?.timeSlot?.service_unit_capacity ===
                                0
                                ? () => {}
                                : () => onBookNow()
                            }
                          ></Button>
                        )}
                      </View>
                    </Card>
                  </View>
                </View>
              </View>
              <Footer></Footer>
            </ScrollView>

            {modal.isVisible && (
              <View className="flex-1">
                <ResponseModal
                  modalContent={modal.message}
                  modalVisible={modal.isVisible}
                  setVisible={(e) => setModal({ ...modal, isVisible: e })}
                  primaryButtonLabel="Dismiss"
                  onPrimaryButtonPress={
                    modal.dismiss
                      ? () => {
                          setModal({ ...modal, isVisible: false });
                          dismiss();
                        }
                      : () => setModal({ ...modal, isVisible: false })
                  }
                  showSecondaryButton={modal.showSecondaryButton}
                  secondaryButtonLabel={"My bookings"}
                  onSecondaryButtonPress={() => {
                    setModal({ ...modal, isVisible: false });
                    navigation.navigate("bookings");
                  }}
                ></ResponseModal>
              </View>
            )}
          </View>
        </View>
      </View>
    </StripeCheckout>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Image, Pressable, View } from "react-native";
import {
  axiosGet,
  getBookmark,
  getListing,
  getListingCategories,
  getProducts,
  getUserData,
  SharedContext,
} from "../../services/api";
import { Font } from "../../components/font";
import { Card, CardDark } from "../../components/card";
import {
  FeaturedListing,
  FeaturedListing1,
  FeaturedProducts,
  LatestArticles,
  UserReviews,
} from "../../components/listing";
import { Footer } from "../../components/footer";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { RefreshControl, ScrollView } from "react-native-gesture-handler";
import { AuthContext } from "../../context/authContext";
import { UserContext } from "../../context/userContext";
import { BusinessesContext } from "../../context/businessesContext";
import { MaterialIcons } from "@expo/vector-icons";
import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import { ActivityIndicator } from "react-native";
import { ProductsContext } from "../../context/productsContext";
import { MessagesContext } from "../../context/messagesContext";
import { useNotifications } from "../../../utils/useNotifications";
import Toast from "react-native-toast-message";

export const Home = ({ navigation, route }) => {
  const [shared, setShared] = useContext(SharedContext);
  const { userToken } = useContext(AuthContext);
  const { registerForPushNotificationsAsync } = useNotifications();
  const {
    isBusinessLoading,
    businessCategories,
    featuredPlaces,
    reviews,
    setIsBusinessLoading,
    setBusinessCategories,
    setFeaturedPlaces,
    setBusinessApiError,
  } = useContext(BusinessesContext);
  const {
    isProductsLoading,
    featuredProducts,
    setFeaturedProducts,
    setIsProductsLoading,
    setProductsApiError,
  } = useContext(ProductsContext);
  const {
    channels,
    currentChannelMessages,
    currentChatData,
    isTypingData,
    setChannels,
    setCurrentChannelMessages,
    setUpdateChannelUnreadCount,
    resetCurrentChatData,
    setCurrentChatData,
    dispatchMessageEvents,
  } = React.useContext(MessagesContext);
  const [articles, setArticles] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [moreCategory, setMoreCategory] = useState(false);
  const doSearch = () => {
    setShared((e) => ({ ...e, search: null }));
    navigation.navigate("search-modal");
  };
  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const getListingApi = async ({
    limit = null,
    offset = null,
    searchText = "",
    listingCategoryId = null,
    minPrice = null,
    maxPrice = null,
  }) => {
    return await getListing({
      limit,
      offset,
      searchText,
      listingCategoryId,
      minPrice,
      maxPrice,
    });
  };
  // get listing data api call
  const getData = async () => {
    try {
      setRefreshing(true);
      setIsBusinessLoading(true);
      setBusinessApiError(null);
      const categoryRes = await getListingCategories({
        limit: null,
        offset: null,
        searchText: "",
      });
      const listingRes = await getListingApi({
        limit: null,
        offset: null,
        searchText: "",
        listingCategoryId: null,
        minPrice: null,
        maxPrice: null,
      });
      if (categoryRes?.status === 200) {
        setBusinessCategories(categoryRes?.data?.data);
      } else {
        if (categoryRes?.status === 502) {
          showToast(
            "error",
            "Category error",
            "Something went wrong on our side."
          );
        } else {
          showToast("error", "Error", categoryRes?.data?.message);
        }
        console.log(
          "home, getlistingcategories res error : ",
          categoryRes?.data
        );
        setBusinessCategories([]);
        setBusinessApiError(categoryRes?.data);
        setIsBusinessLoading(false);
      }
      if (listingRes?.status === 200) {
        setFeaturedPlaces(listingRes?.data?.data);
      } else {
        if (listingRes?.status === 502) {
          showToast(
            "error",
            `${listingRes.status} Listings error`,
            listingRes?.data?.data?.message ||
              "Something went wrong on our side."
          );
        } else {
          showToast("error", "Error", listingRes?.data?.message);
        }
        console.log("home, getlisting res error : ", listingRes?.data);
        setFeaturedPlaces([]);
        setBusinessApiError(listingRes?.data);
        setIsBusinessLoading(false);
      }
    } catch (error) {
      console.log("home,get data error : ", error);
      setBusinessCategories([]);
      setFeaturedPlaces([]);
      showToast(
        "error",
        "Error:",
        `${error?.status || error?.code} ${error?.message}`
      );
    } finally {
      setRefreshing(false);
      setIsBusinessLoading(false);
    }
  };
  const getProductsData = async () => {
    try {
      setRefreshing(true);
      setIsProductsLoading(true);
      setProductsApiError(null);
      const productsRes = await getProducts({
        limit: null,
        offset: null,
        searchText: "",
        prooductCategoryId: null,
        minPrice: null,
        maxPrice: null,
      });
      if (productsRes.status === 200) {
        setFeaturedProducts(productsRes.data.data);
      } else {
        if (productsRes.status === 502) {
          showToast(
            "error",
            "Products error",
            "Something went wrong on our side."
          );
        }
        console.log("home, getproducts res error : ", productsRes.data);
        setProductsApiError(productsRes.data);
        setFeaturedProducts([]);
        setIsProductsLoading(false);
      }
    } catch (error) {
      console.log("home, getproducts error : ", error);
      setFeaturedProducts([]);
    } finally {
      setRefreshing(false);
      setIsProductsLoading(false);
    }
  };
  useEffect(() => {
    getData();
    getProductsData();
  }, []);
  useEffect(() => {
    if (userToken) {
      registerForPushNotificationsAsync(userToken);
    }
  }, [userToken]);

  const onCategorySelect = async ({ category, title }) => {
    navigation.navigate("search", {
      itemType: "Listing",
      keyword: "",
      category: category,
      minPrice: 0,
      maxPrice: 0,
    });
  };
  const getIcon = (string) => {
    const iconPack = string?.split(".")[0];
    const icon = string?.split(".")[1];
    const color = string?.split(".")[2];
    switch (iconPack) {
      case "MaterialIcons":
        return (
          <MaterialIcons name={icon} size={28} color={color ?? colors.app.h} />
        );
      case "FontAwesome":
        return (
          <FontAwesome name={icon} size={28} color={color ?? colors.app.h} />
        );
      case "FontAwesome5":
        return (
          <FontAwesome5 name={icon} size={28} color={color ?? colors.app.h} />
        );
      case "Ionicons":
        return <Ionicons name={icon} size={28} color={color ?? colors.app.h} />;
      default:
        return <MaterialIcons name="category" size={28} color={colors.app.h} />;
    }
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="p-2 w-full mx-auto flex lg:hidden">
        <Pressable
          className="w-full h-10 bg-app-a flex-row p-2 items-center justify-center rounded active:bg-gray-200"
          onPress={doSearch}
        >
          <RemixIcon name="search-line" color={colors.app.d1}></RemixIcon>
          <Font className="flex-1 px-2">What are you looking for?</Font>
        </Pressable>
      </View>
      <View className="rounded-t-2xl lg:rounded-none overflow-hidden flex-1">
        <ScrollView
          className="rounded-t-2xl lg:rounded-none bg-gray-50 w-full min-h-full"
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                getData();
                // getWishlist();
                // getMe();
              }}
            />
          }
        >
          <View className="flex-1 w-full items-center">
            <Image
              source={require("../../../assets/main.jpg")}
              className="w-full h-full absolute top-0 left-0"
            ></Image>
            <View className="w-full h-full absolute top-0 left-0 bg-[#442222DD]"></View>
            <View className="flex-1 w-full max-w-screen-lg mx-auto py-8 lg:py-40 flex-row ">
              <View className="flex-1  px-4">
                <Font className="text-app-a text-3xl lg:text-5xl lg:-mt-16" weight={600}>
                  SMBEx-A One-stop Marketplace of Community Businesses in a
                  Local Neighborhood.
                </Font>
                <Font className="text-app-a1 text-xl lg:text-2xl mt-4 lg:mt-8 lg:mb-8  ">
                  Search and book in quick and easy steps while earning rewards.
                </Font>
                <Pressable
                  className="h-12 mt-10 w-full max-w-2xl self-center flex-row bg-white rounded p-4 items-center hidden lg:flex lg:mb-px"
                  onPress={doSearch}
                >
                  <RemixIcon
                    name="search-line"
                    color={colors.app.d1}
                  ></RemixIcon>
                  <Font className="ml-4 text-app-d1" weight={600}>
                    What are you looking for?
                  </Font>
                </Pressable>
                <View className="h-16 flex lg:hidden"></View>
              </View>
            </View>
          </View>
          <View className="w-full px-4 items-center -mt-10 lg:-mt-16">
            <View className="flex-1 w-full max-w-screen-lg mx-auto">
              <Card className="" title="Listing Categories">
                <View
                  className={`transition-all mb-4 items-center justify-center flex-row flex-wrap overflow-hidden ${
                    moreCategory ? "" : "max-h-[312px]"
                  }`}
                >
                  {businessCategories?.length > 0 && !isBusinessLoading ? (
                    businessCategories.map((e, i) => (
                      <View
                        className="flex-col items-center"
                        style={{
                          width: 90,
                          marginTop: 4,
                          marginBottom: 16,
                        }}
                        key={`categories_${i}`}
                      >
                        <Pressable
                          className="rounded-full border border-gray-200 items-center justify-center p-4 active:bg-app-h3"
                          style={{ width: 60, height: 60 }}
                          onPress={() =>
                            onCategorySelect({
                              category: { label: e.title, value: e.id },
                              title: e.title,
                            })
                          }
                        >
                          {getIcon(e.icon)}
                        </Pressable>
                        <View className="flex-row justify-center items-center h-6">
                          <Font
                            className="text-center text-app-d1 flex-1 mt-1 text-xs leading-4"
                            weight={600}
                          >
                            {e?.title}
                          </Font>
                        </View>
                      </View>
                    ))
                  ) : businessCategories?.length === 0 && !isBusinessLoading ? (
                    <View>
                      <Font>No categories found</Font>
                    </View>
                  ) : (
                    <View className="flex-1 w-full justify-center">
                      <ActivityIndicator
                        size={"small"}
                        color={colors.app.e}
                      ></ActivityIndicator>
                    </View>
                  )}
                </View>
                {businessCategories?.length > 8 && (
                  <View className="justify-center items-center px-4 w-full">
                    <Pressable
                      className="p-3 border-t border-gray-200 w-full justify-center items-center"
                      onPress={() => setMoreCategory(!moreCategory)}
                    >
                      {moreCategory ? (
                        <Font className="text-app-e">See Less</Font>
                      ) : (
                        <Font className="text-app-e">See More</Font>
                      )}
                    </Pressable>
                  </View>
                )}
              </Card>

              <Card
                className=""
                title="Featured Places"
                seeAll={
                  featuredPlaces?.length > 5
                    ? () =>
                        navigation.navigate("search", {
                          itemType: "Listing",
                          keyword: "",
                          category: "",
                          priceRange: [],
                          location: "",
                          detailLocation: "",
                        })
                    : null
                }
              >
                {featuredPlaces?.length > 0 && !isBusinessLoading ? (
                  <FeaturedListing data={featuredPlaces}></FeaturedListing>
                ) : // <FeaturedListing1 data={featuredPlaces}></FeaturedListing1>
                featuredPlaces?.length === 0 && !isBusinessLoading ? (
                  <View className="py-5 flex-1 items-center">
                    <Font>No features</Font>
                  </View>
                ) : (
                  <View className="flex-1 w-full justify-center">
                    <ActivityIndicator
                      size={"small"}
                      color={colors.app.e}
                    ></ActivityIndicator>
                  </View>
                )}
              </Card>

              <Card
                className=""
                title="Featured Products"
                seeAll={
                  featuredProducts?.length > 5
                    ? () =>
                        navigation.navigate("search", {
                          itemType: "Product",
                          keyword: "",
                          category: "",
                          priceRange: [],
                          location: "",
                          detailLocation: "",
                        })
                    : null
                }
              >
                {featuredProducts?.length > 0 && !isProductsLoading ? (
                  <FeaturedProducts data={featuredProducts}></FeaturedProducts>
                ) : featuredProducts?.length === 0 && !isProductsLoading ? (
                  <View className="py-5 flex-1 items-center">
                    <Font>No features</Font>
                  </View>
                ) : (
                  <View className="flex-1 w-full justify-center">
                    <ActivityIndicator
                      size={"small"}
                      color={colors.app.e}
                    ></ActivityIndicator>
                  </View>
                )}
              </Card>
              <CardDark className="" title="What Our Users Says">
                <View className="px-4">
                  <Font
                    className="pb-4 pt-2 text-app-a text-[15px]"
                    weight={500}
                  >
                    We collect reviews from our users so you can get an honest
                    opinion of what an experience with our website are really
                    like!
                  </Font>
                </View>
                <View className="flex-1 w-full">
                  <UserReviews data={reviews}></UserReviews>
                </View>
              </CardDark>
              <Card title="Plan The Vacation of Your Dreams">
                <View className="px-4">
                  <Font
                    className="pb-4 pt-2 text-app-d1 text-[15px]"
                    weight={500}
                  >
                    Explore some of the best tips from around the world from our
                    partners and friends. Discover some of the most popular
                    listings!
                  </Font>
                </View>
                <View className="w-full flex-col lg:flex-row p-2">
                  <View className="w-full lg:w-1/3 p-2">
                    <View className="w-full h-auto lg:h-72 bg-green-50 rounded-lg border border-gray-200 items-center p-8">
                      <View className="w-24 h-24 rounded-full bg-green-100 items-center justify-center">
                        <RemixIcon
                          name="treasure-map-line"
                          color={colors.app.g}
                          size="60"
                        ></RemixIcon>
                      </View>
                      <View className="pt-4">
                        <Font className="text-app-d text-lg" weight={700}>
                          Find interesting place
                        </Font>
                      </View>
                      <View className="pt-4">
                        <Font
                          className="text-center text-app-d1 text-[15px]"
                          weight={500}
                        >
                          You can search for areas of interest, local events,
                          trendy restaurants or just things to do.
                        </Font>
                      </View>
                    </View>
                  </View>
                  <View className="w-full lg:w-1/3 p-2">
                    <View className="w-full h-auto lg:h-72 bg-orange-50 rounded-lg border border-gray-200 items-center p-8">
                      <View className="w-24 h-24 rounded-full bg-orange-100 items-center justify-center">
                        <RemixIcon
                          name="medal-2-line"
                          color={colors.app.h}
                          size="60"
                        ></RemixIcon>
                      </View>
                      <View className="pt-4">
                        <Font className="text-app-d text-lg" weight={700}>
                          Check Reviews
                        </Font>
                      </View>
                      <View className="pt-4">
                        <Font
                          className="text-center text-app-d1 text-[15px]"
                          weight={500}
                        >
                          Determine the quality of goods and services from local
                          shops and choose the best place.
                        </Font>
                      </View>
                    </View>
                  </View>
                  <View className="w-full lg:w-1/3 p-2">
                    <View className="w-full h-auto lg:h-72 bg-blue-50 rounded-lg border border-gray-200 items-center p-8">
                      <View className="w-24 h-24 rounded-full bg-blue-100 items-center justify-center">
                        <RemixIcon
                          name="calendar-check-line"
                          color={colors.app.f}
                          size="60"
                        ></RemixIcon>
                      </View>
                      <View className="pt-4">
                        <Font className="text-app-d text-lg" weight={700}>
                          Make a Reservation
                        </Font>
                      </View>
                      <View className="pt-4">
                        <Font
                          className="text-center text-app-d1 text-[15px]"
                          weight={500}
                        >
                          Contact listing owner and reserve a table online for
                          lunch or dinner or rent an apartment.
                        </Font>
                      </View>
                    </View>
                  </View>
                </View>
              </Card>
              {/* <Card className="" title="Latest Articles">
                <LatestArticles data={articles}></LatestArticles>
              </Card> */}
              <View className="flex-1 w-full">
                <Footer navigation={navigation}></Footer>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

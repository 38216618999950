import { Platform, View } from "react-native";
import React, { useRef, useEffect } from "react";
import { WebView } from "react-native-webview";
export function Maps(props) {
  const webviewRef = useRef();
  const onLoad = () => {
    function inject(message) {
      return `(()=>document.dispatchEvent(new MessageEvent('message', {data: ${JSON.stringify(
        message
      )}})))();`;
    }
    let data = {
      latitude: props.lat || 51.505,
      longitude: props.lang || -0.09,
      locate: true,
      disabled: props.disabled || true,
      items: props.listingDetails || [
        {
          url: "thisurl",
          image: "https://smbex.com/wp-content/uploads/2022/07/Capture.png",
          title: "Here is the title of the item",
          location: "Locatin is here",
          latitude: 51.505,
          longitude: -0.09,
          rating: 4.8,
          priceMin: 150.0,
          priceMax: 200.0,
        },
      ],
    };
    Platform.OS === "web"
      ? webviewRef.current?.postMessage(data)
      : webviewRef.current?.injectJavaScript(inject(data));
  };
  return (
    <WebView
      style={props.style}
      ref={webviewRef}
      scalesPageToFit={false}
      mixedContentMode="compatibility"
      originWhitelist={["*"]}
      onMessage={(e) => {
        // console.log(e);
        // console.log("onMessage event:", e.nativeEvent);
        // return (
        //   e.nativeEvent.data.type === "locate" &&
        //   props?.onLocate(e.nativeEvent.data)
        // );
      }}
      onLoad={onLoad}
      onError={(...d) => console.log("WebView onError: ", d)}
      useWebKit={true}
      source={{
        html: `<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/> <link rel="stylesheet" href="https://unpkg.com/leaflet@1.8.0/dist/leaflet.css" integrity="sha512-hoalWLoI8r4UszCkZ5kL8vayOGVae1oxXe/2A4AO6J9+580uKHDO3JdHb7NzwwzK5xr/Fs0W40kiNHxM9vyTtQ==" crossorigin=""/> <script src="https://unpkg.com/leaflet@1.8.0/dist/leaflet.js" integrity="sha512-BB3hKbKWOc9Ez/TAwyWxNXeoV9c1v6FIeYiBieIWkpLjauysF18NzgR1MBNBXf8/KABdlkX68nAhlwcDFLGPCQ==" crossorigin=""></script> <style>body{padding: 0; margin: 0;}html, body, #map{height: 100%; width: 100vw;}.leaflet-popup-content-wrapper{overflow:hidden}.leaflet-popup-content{margin:0}p{margin:0 !important;}</style> <div id="map"></div>
                <script src="https://unpkg.com/leaflet.markercluster@1.4.1/dist/leaflet.markercluster.js"></script>
                <link rel="stylesheet" href="https://unpkg.com/leaflet.markercluster@1.4.1/dist/MarkerCluster.Default.css" />
                <script>
                let postMessage = window.ReactNativeWebView !== undefined ? window.ReactNativeWebView : window.parent
                const openItem = (e) => postMessage.postMessage({type:'open',data:e});
                function showMap(data){
                    let latlng = [data.latitude, data.longitude];
                    var map = L.map('map').setView(latlng, 13); L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 19, attribution: '' }).addTo(map);
                    var markers = L.markerClusterGroup({
                        spiderfyShapePositions: function(count, centerPt) {
                                    var distanceFromCenter = 35,
                                        markerDistance = 45,
                                        lineLength = markerDistance * (count - 1),
                                        lineStart = centerPt.y - lineLength / 2,
                                        res = [],
                                        i;
                    
                                    res.length = count;
                    
                                    for (i = count - 1; i >= 0; i--) {
                                        res[i] = new Point(centerPt.x + distanceFromCenter, lineStart + markerDistance * i);
                                    }
                    
                                    return res;
                                }
                    });
                    data.items.forEach((e,i)=>{
                        let marker = L.marker([e.latitude, e.longitude], { draggable: data.locate }).addTo(map)
                            .bindPopup(\`
                                <div style="width:220px;height:240px;cursor:pointer" onclick="openItem('\${e.url}')">
                                <img src="\${e.image}" style="width:220px;height:160px;object-fit:cover">
                                <div style="padding:4px 12px">
                                <div style="font-size:15px;font-weight:700;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">\${e.title}</div>
                                <div style="color:#888888;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">\${e.location}</div>
                                <div style="margin-top:4px;font-weight:600;"><span style="color:orange">&starf;</span> \${e.rating} &nbsp;&#183;&nbsp;  <span style="color:green">$</span> \${e.priceMin} - \${e.priceMax}</div>
                                </div>
                                </div>
                            \`)
                        markers.addLayer(marker);
                        if(data.locate){
                            marker.on('dragend', function (e) {
                                postMessage.postMessage({type:'locate',data:{latitude:marker.getLatLng().lat,longitude:marker.getLatLng().lng}});
                            });
                            if (!data.disabled) {
                              marker.dragging.disable();
                            }
                        }
                    })
                        map.addLayer(markers);
                }
                document.addEventListener("message", message => {
                    let data = message.data;
                    showMap(data)
                });
                window.addEventListener("message", message => {
                    let data = message.data;
                    showMap(data)
                });
                </script>
                `,
      }}
    />
  );
}

import { Platform, Dimensions } from "react-native";

export const parseProfileName = (fn, ln) =>
  (fn || "") + (ln ? (fn ? " " : "") + ln : "") || "Unknown User";

const _dimentions = () => Dimensions.get("screen");

export const pageTitle = (text) =>
  Platform.select({
    web: _dimentions().width > 1023 ? `SMBEx | ${text}` : text,
    default: text,
  });

export const catchAPIErrorText = (e, unknownErrorMessage) =>
  e?.response
    ? e.response?.data?.message
      ? e.response.data.message
      : e?.response?.data?.pathParams?.[0]
      ? `${e.response.data.pathParams[0]?.instancePath} ${e.response.data.pathParams[0]?.message}`
      : e?.response?.data?.body?.[0]
      ? `${e.response.data.body[0]?.keyword} ${e.response.data.body[0]?.instancePath} ${e.response.data.body[0]?.message}`
      : e?.message
    : e?.code === "ERR_NETWORK"
    ? e.message
    : unknownErrorMessage || "Unknown error occured";

export class UserRoles {
  static CUSTOMER = "customer";
  static VENDOR = "vendor";
}

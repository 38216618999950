import { io } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.SOCKET_IO_URI || "wss://smbexapi.wisdomtooth.tech";

export const socket = io(URL, {
  autoConnect: false,
  reconnection: true,
  reconnectionDelayMax: 60000,
  reconnectionAttempts: 50,
});

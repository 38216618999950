import React from "react";

const INITIAL_STATE = {
  groupTabStatus: { curr: "For you", prev: undefined },
  setGroupTabStatus: ({ curr, prev }) => ({ curr: undefined, prev: undefined }),
  memberGroups: null,
  setMemberGroups: () => null,
  adminGroups: null,
  setAdminGroups: () => null,
  groupPosts: null,
  setGroupPosts: () => null,
  groupPostDetails: null,
  setGroupPostDetails: () => null,
  groupPostsPagination: { from: null, to: null, total: null },
  setGroupPostsPagination: () => ({ from: null, to: null, total: null }),
  groupReviews: null,
  setGroupReviews: () => [],
  groupReviewsPagination: { from: null, to: null, total: null },
  setGroupReviewsPagination: () => ({ from: null, to: null, total: null }),
  groupReviewDetails: null,
  setGroupReviewDetails: () => null,
  groupPostComments: null,
  setGroupPostComments: () => null,
  resetGroupsState: () => null,
};

export const groupsContext = React.createContext(INITIAL_STATE);

const groupsReducer = (state, action) => {
  switch (action.type) {
    case "SET_TAB_STATUS":
      return { ...state, groupTabStatus: action.payload };
    case "SET_ADMIN_GROUPS":
      return { ...state, adminGroups: action.payload };
    case "SET_MEMBER_GROUPS":
      return { ...state, memberGroups: action.payload };
    case "SET_REVIEW_DETAILS":
      return { ...state, groupReviewDetails: action.payload };
    case "ADD_REVIEWS":
      return { ...state, groupReviews: action.payload };
    case "SET_REVIEWS_PAGINATION":
      return { ...state, groupReviewsPagination: action.payload };
    case "SET_POST_DETAILS":
      return { ...state, groupPostDetails: action.payload };
    case "SET_FEED_COMMENTS":
      return { ...state, groupPostComments: action.payload };
    case "ADD_POSTS":
      return { ...state, groupPosts: action.payload };
    case "SET_POSTS_PAGINATION":
      return { ...state, groupPostsPagination: action.payload };
    case "RESET_GROUPS_STATE":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default function GroupContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(groupsReducer, INITIAL_STATE);

  return (
    <groupsContext.Provider
      value={{
        groupTabStatus: state.groupTabStatus,
        setGroupTabStatus: ({ curr, prev }) => {
          dispatch({
            type: "SET_TAB_STATUS",
            payload: { curr: curr, prev: prev },
          });
        },
        memberGroups: state.memberGroups,
        setMemberGroups: ({ data, push = false }) => {
          dispatch({
            type: "SET_MEMBER_GROUPS",
            payload: push
              ? [...(state.memberGroups || []), ...(data || [])]
              : data,
          });
        },
        adminGroups: state.adminGroups,
        setAdminGroups: ({ data, push = false }) => {
          dispatch({
            type: "SET_ADMIN_GROUPS",
            payload: push
              ? [...(state.adminGroups || []), ...(data || [])]
              : data,
          });
        },
        groupReviews: state.groupReviews,
        setGroupReviews: ({ data, id, push = false }) => {
          dispatch({
            type: "ADD_REVIEWS",
            payload: {
              ...(state.groupReviews || {}),
              [id]: push
                ? { ...data, ...(state.groupReviews?.[id] || {}) }
                : data,
            },
          });
        },
        groupReviewsPagination: state.groupReviewsPagination,
        setGroupReviewsPagination: ({ id, from, to, total }) => {
          dispatch({
            type: "SET_REVIEWS_PAGINATION",
            payload: {
              ...(state.groupReviewsPagination || {}),
              [id]: { from, to, total },
            },
          });
        },
        groupReviewDetails: state.groupReviewDetails,
        setGroupReviewDetails: ({ data, id }) => {
          dispatch({
            type: "SET_REVIEW_DETAILS",
            payload: { ...state.groupReviewDetails, [id]: data || {} },
          });
        },
        groupPostComments: state.groupPostComments,
        setGroupPostComments: ({ data, id }) => {
          dispatch({
            type: "SET_FEED_COMMENTS",
            payload: { ...state.groupPostComments, [id]: data || {} },
          });
        },
        groupPosts: state.groupPosts,
        setGroupPosts: ({ data, push = false }) => {
          dispatch({
            type: "ADD_POSTS",
            payload: push
              ? [...(data || []), ...(state.groupPosts || [])]
              : data,
          });
        },
        groupPostsPagination: state.groupPostsPagination,
        setGroupPostsPagination: ({ from, to, total }) => {
          dispatch({
            type: "SET_POSTS_PAGINATION",
            payload: { from, to, total },
          });
        },
        groupPostDetails: state.groupPostDetails,
        setGroupPostDetails: ({ data, pid }) => {
          dispatch({
            type: "SET_POST_DETAILS",
            payload: { ...state.groupPostDetails, [pid]: data },
          });
        },
        resetGroupsState: ({ data } = {}) => {
          dispatch({ type: "RESET_GROUPS_STATE", payload: data || [] });
        },
      }}
    >
      {children}
    </groupsContext.Provider>
  );
}

export const useGroupsContext = () => React.useContext(groupsContext);

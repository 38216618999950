import React, { useEffect, useState } from "react";
import { Pressable, ScrollView, Text, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { FaqItem } from "../../components/faq";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import { Button, Input } from "../../components/input";
import { DEV_API, getPublicData, SharedContext } from "../../services/api";
import axios from "axios";

export const TermsCondition = ({ navigation, route }) => {
  const [faqs, setFaqs] = useState([]);
  const [termsConditionData, setTermsConditionData] = useState("");

  useEffect(() => {
    getTermsAndConditionData();
  }, []);

  const getTermsAndConditionData = async () => {
    try {
      const res = await axios.get(DEV_API + `/content/terms-and-conditions`);
      //console.log("setTermsConditionData", res.data);
      setTermsConditionData(res.data);
    } catch (error) {
      console.log("Error in getting terms and condition data");
    }
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Terms And Conditions
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card title="Last Updated : May 24 ,2023 ">
                <View className="w-full p-4 pt-0">
                  
                    <Font className="text-app-d">{termsConditionData.data}</Font>
                
                </View>
                {/* <View className="w-full flex-col lg:flex-row p-2 flex  items-center justify-center space-x-4">
                  <View className="w-24  rounded-full justify-items-center">
                    <Button
                      type="disabled"
                      label="Decline"
                      //weight={700}
                      className="w-1/2 "
                    ></Button>
                  </View>
                  <View className="w-24  rounded-full justify-items-center">
                    <Button
                      type="primary"
                      label="Accept"
                      //weight={500}
                      className="w-1/2"
                    ></Button>
                  </View>
                </View> */}
              </Card>
            </View>

            <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
              <Widget></Widget>
            </View>
          </View>
          <Footer></Footer>
        </View>
      </ScrollView>
    </View>
  );
};

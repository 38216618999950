import { useEffect, useState } from "react";
import { Pressable } from "react-native";
import { ScrollView } from "react-native";
import { View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Button, Input } from "../../components/input";

export const BookingModal = ({ navigation }) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [unitCount, setUnitCount] = useState(1);
  const [selectedTime, setSelectedTime] = useState({
    month: new Date().getMonth() + 1,
    date: new Date().getDate(),
  });
  const [timeSlot, setTimeSlot] = useState(null);
  //   const [extraService, setExtraService] = useState(null);
  const timeSlots = [
    "8 am - 10 am",
    "10 am - 12 pm",
    "12 pm -  2pm",
    "3 pm - 8 pm",
  ];
  const extraServices = [
    { id: 1, name: "service 1 ", cost: 100 },
    { id: 2, name: "service 2 ", cost: 200 },
    { id: 3, name: "service 3 ", cost: 300 },
  ];

  const back = navigation.canGoBack();
  const dismiss = () => {
    navigation.goBack();
  };
  const currentMonth = new Date().getMonth();
  const currentDate = new Date().getDate();

  useEffect(() => {}, []);

  return (
    <View
      className={`flex-1 items-center justify-center p-4  ${
        back ? "bg-app-d3" : "bg-gray-50"
      }`}
    >
      <View className="w-full max-h-screen bg-white rounded-lg max-w-screen-md border border-gray-200 overflow-hidden px-4 my-6">
        {/* <Card title="Booking"> */}
        <View className="py-1 flex-row items-center">
          <Font weight={600} className="text-[15px] flex-1 app-text-d">
            Booking
          </Font>
          <Pressable
            className="p-3 rounded-full active:bg-black active:bg-opacity-10 flex-shrink-0"
            onPress={dismiss}
          >
            <RemixIcon name="close-line" color={colors.app.d1}></RemixIcon>
          </Pressable>
        </View>
        <ScrollView>
          <View className="flex-row items-center w-full mt-2 mb-1">
            <Font className="text-app-d">
              Date :{" "}
              {months[selectedTime.month - 1] +
                " " +
                selectedTime.date +
                " " +
                new Date().getFullYear()}
            </Font>
          </View>
          <View className="mt-1 mb-2">
            <ScrollView className="gap-x-2 py-2 " horizontal>
              {months.map((e, i) => (
                <Pressable
                  className={`w-20 h-10 border  rounded-lg flex justify-center items-center  ${
                    currentMonth > i
                      ? "border-gray-200 bg-app-c"
                      : selectedTime.month === i + 1
                      ? "border-app-e bg-app-e"
                      : "border-gray-300 bg-app-b"
                  }`}
                  onPress={() =>
                    setSelectedTime({ ...selectedTime, month: i + 1 })
                  }
                  key={`month_${i}`}
                  disabled={currentMonth > i}
                >
                  <Font
                    className={`text-center w-full ${
                      currentMonth > i
                        ? "text-gray-400"
                        : selectedTime.month === i + 1 && "text-app-a font-bold"
                    }`}
                  >
                    {e}
                  </Font>
                </Pressable>
              ))}
            </ScrollView>
            <ScrollView className="gap-x-2 py-2" horizontal>
              {[...Array(30).keys()].map((e, i) => (
                <Pressable
                  className={`w-10 h-10 border  rounded-lg flex justify-center items-center ${
                    currentDate > i + 1
                      ? "border-gray-200 bg-app-c"
                      : selectedTime.date === i + 1
                      ? "border-app-e bg-app-e"
                      : "border-gray-300 bg-app-b"
                  }`}
                  onPress={() =>
                    setSelectedTime({ ...selectedTime, date: i + 1 })
                  }
                  disabled={currentDate > i + 1}
                >
                  <Font
                    className={`text-center w-full ${
                      currentDate > i + 1
                        ? "text-gray-400"
                        : selectedTime.date === i + 1 && "text-app-a font-bold"
                    }`}
                  >
                    {i + 1}
                  </Font>
                </Pressable>
              ))}
            </ScrollView>
          </View>
          <View className="flex-1 w-full my-2">
            {/* <AISplitNumber
              minLabel={"Check In"}
              maxLabel={"Check out"}
            ></AISplitNumber> */}

            <Input
              type="counter"
              label={"$30/ reservation"}
              setValue={setUnitCount}
              value={unitCount}
              isIncrementDisabled={unitCount === 5}
                isDecrementDisabled={unitCount === 1}
            ></Input>
            {/* <View className="pt-1">
              <Font className=" text-black text-right">$30/unit</Font>
            </View> */}
          </View>
          <View className="flex-row h-10 items-center w-full mt-2">
            <Font className="text-app-d">Slots available</Font>
          </View>
          <View className="flex flex-row flex-wrap gap-3">
            {/* <ScrollView className="gap-x-4 py-2" horizontal> */}
            {timeSlots.map((e, i) => (
              <Pressable
                className={`w-36 h-12 border  rounded-lg ${
                  timeSlot === e
                    ? "bg-app-e border-app-e"
                    : "border-gray-200 bg-app-b"
                }`}
                key={`timeslot_${i}`}
                onPress={() => setTimeSlot(e)}
              >
                <View className="h-12 items-center flex-row w-full">
                  <View
                    className={`h-6 absolute w-1  ${
                      timeSlot === e ? "bg-app-a" : "bg-app-e"
                    }`}
                  ></View>
                  <Font
                    className={`ml-4 w-full ${
                      timeSlot === e && "text-app-a font-bold"
                    }`}
                  >
                    {e}
                  </Font>
                </View>
                {/* <View className="items-center flex-row w-full">
                    <Font className="ml-4 w-full text-app-h">{e}</Font>
                  </View> */}
              </Pressable>
            ))}
            {/* </ScrollView> */}
          </View>
          {/* ------------------------ extra services ------------------------ */}
          {/* <View className="flex-row h-10 items-center w-full">
            <Font className="text-app-d">Extra Services</Font>
          </View> */}
          {/* <View>
            <ScrollView className="gap-x-4 py-2" horizontal>
              {extraServices.map((e, i) => (
                <Pressable
                  className={`w-44 h-20 border rounded-lg ${
                    extraService === e.id
                      ? "bg-app-e border-app-e"
                      : "border-gray-200 bg-app-b"
                  }`}
                  key={i}
                  onPress={() => setExtraService(e.id)}
                >
                  <View className="h-12 items-center flex-row w-full">
                    <View
                      className={`h-6 absolute w-1 ${
                        extraService === e.id ? "bg-app-a" : "bg-app-e"
                      }`}
                    ></View>
                    <Font
                      className={`ml-4 w-full ${
                        extraService === e.id && "text-app-a font-bold"
                      }`}
                    >
                      {e.name}
                    </Font>
                  </View>
                  <View className="items-center flex-row w-full">
                    <Font
                      className={`ml-4 w-full ${
                        extraService === e.id ? "text-app-a font-bold" : "text-app-h"
                      }`}
                    >{`$ ${e.cost}`}</Font>
                  </View>
                </Pressable>
              ))}
            </ScrollView>
          </View> */}

          <View className="flex-1 w-full my-3 ">
            <Input
              type="coupon"
              label={"Have a coupon?"}
              placeholder="Coupon code"
              submitText="Apply"
            ></Input>
          </View>
          <View className="flex-row w-full mt-4">
            <Font className="flex-1">Total</Font>
            <Font>$ 240.00</Font>
          </View>
          <View className="flex-row w-full my-2">
            <Font className="flex-1">Reward</Font>
            <Font>$ 7.20</Font>
          </View>
          <View className="p-4 rounded-lg flex-row w-full items-center mt-2">
            <Button
              type="primary"
              label="Book Now"
              className="w-full"
              onPress={() => {
                dismiss();
                navigation.navigate("confirm-booking");
              }}
            ></Button>
          </View>
          {/* </Card> */}
        </ScrollView>
      </View>
    </View>
  );
};

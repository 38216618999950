import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Image,
  Pressable,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import { DEV_API, getPublicData, SharedContext } from "../../services/api";
import axios from "axios";
import { useContext } from "react";
import BlogsContext from "../../context/blogsContext";

export const Blog = ({ navigation, route }) => {
  const postsLimit = 10;
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const { blogs, setBlogs } = useContext(BlogsContext);

  const limitPerPage = 10;
  useEffect(() => {
    getBlogs();
  }, []);
  const getBlogs = async () => {
    try {
      const res = await axios.get(
        DEV_API + `/content/blogs?limit=${limitPerPage}&&offset=${pagination}`
      );
      //console.log("setBlogs", res.data["data"]);
      setPagination(blogs.length + res.data["data"].length);
      setBlogs([...blogs, ...res.data["data"]]);
    } catch (error) {
      console.log("error");
    }
  };

  const goToPost = (slug) => {
    console.log("slug", slug);
    navigation.navigate({ name: "post", params: { slug: slug } });
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className="max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">Blog</Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card title="Latest posts ">
                {blogs.map((content) => (
                  <Pressable
                    className="w-full px-4 py-2 border-b border-gray-200 flex-col lg:flex-row"
                    key={content.title}
                    onPress={(e) => goToPost(content.id)}
                  >
                    {console.log("image", content.cover_image_url)}
                    <Image
                      source={{
                        uri: `${DEV_API}/media/${content.cover_image_url}`,
                      }}
                      className="w-full lg:w-60 h-32 rounded-lg"
                    ></Image>
                    <View className="flex-1 mt-4 lg:ml-4">
                      <Font
                        className="font-semibold text-[15px] text-app-d"
                        numberOfLines={3}
                      >
                        {content.title}
                      </Font>
                      <Font className="text-app-d1 my-2" weight={500}>
                        {content.published_on}
                      </Font>
                      <View
                        className="
                                            "
                      >
                        <Font
                          className="text-app-d"
                          weight={500}
                          numberOfLines={3}
                        >
                          {content.description}
                        </Font>
                      </View>
                    </View>
                  </Pressable>
                ))}
                <View className="flex-row w-full">
                  {/* {page > 0 && ( */}
                  <Pressable
                    className="p-4"
                    onPress={() => setPage((e) => e - 1)}
                  >
                    <Font className="text-app-e" weight={600}>
                      &lt; Newer
                    </Font>
                  </Pressable>
                  {/* )} */}
                  <View className="flex-1"></View>
                  {/* {postsLimit <= posts.length && ( */}
                  <Pressable
                    className="p-4"
                    onPress={() => setPage((e) => e + 1)}
                  >
                    <Font className="text-app-e" weight={600}>
                      Older &gt;
                    </Font>
                  </Pressable>
                  {/* )} */}
                </View>
              </Card>
              {hasMoreData && (
                <View>
                  <TouchableOpacity onPress={getBlogs}>
                    <Text>Show More</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
            <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
              <Widget></Widget>
            </View>
          </View>
          <Footer></Footer>
        </View>
      </ScrollView>
    </View>
  );
};

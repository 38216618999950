import React, { useContext, useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import UploadPicture from "../../components/uploadpicture";
import { Button, Input } from "../../components/input";
import { getPublicData, getUserData, patchUserData } from "../../services/api";
import { UserContext } from "../../context/userContext";
import { AuthContext } from "../../context/authContext";
import { ResponseModal } from "../../components/response-modal";
import { ActivityIndicator } from "react-native";
import { Widget } from "../../components/widget";
import Toast from "react-native-toast-message";

export const UpdateProfile = ({ navigation }) => {
  const {
    userData,
    setUserData,
    isUserLoading,
    setIsUserLoading,
    setGetUserLoading,
  } = useContext(UserContext);
  const { user, userToken, setUserToken } = useContext(AuthContext);
  const postsLimit = 10;
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0);
  const [modal, setModal] = useState({
    isVisible: false,
    message: "",
  });
  const [profile, setProfile] = useState({
    first_name: userData?.first_name ?? "",
    last_name: userData?.last_name ?? "",
    company_name: userData?.company_name ?? "",
    email: userData?.email ?? "",
    phone: userData?.phone ?? "",
    profile_image: userData?.profile_image ?? null,
  });
  const [isLocalImageAdded, setIsLocalImageAdded] = useState(false);
  const isButtonEnabled =
    (userData?.first_name !== profile.first_name &&
      profile.first_name !== "") ||
    (userData?.last_name !== profile.last_name && profile.last_name !== "") ||
    (userData?.company_name !== profile.company_name &&
      profile.company_name !== "") ||
    (userData?.email !== profile.email && profile.email !== "") ||
    (userData?.phone !== profile.phone && profile.phone !== "") ||
    (profile.profile_image &&
      userData?.profile_image !== profile.profile_image);
  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  useEffect(() => {
    if (userData) {
      setProfile({
        first_name: userData?.first_name ?? "",
        last_name: userData?.last_name ?? "",
        company_name: userData?.company_name ?? "",
        email: userData?.email ?? "",
        phone: userData?.phone ?? "",
        profile_image: userData?.profile_image ?? null,
      });
    }
  }, [userData]);

  const extractContent = (s, space) => {
    var span = document.createElement("span");
    span.innerHTML = s;
    if (space) {
      var children = span.querySelectorAll("*");
      for (var i = 0; i < children.length; i++) {
        if (children[i].textContent) children[i].textContent += " ";
        else children[i].innerText += " ";
      }
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g, " ");
  };
  const onUpdateProfile = async () => {
    let data = {};
    for (const key in profile) {
      if (profile[key] !== "profile_image" && profile[key] !== userData[key]) {
        data[key] = profile[key];
      }
    }
    // const data = {
    //   first_name: profile.first_name,
    //   last_name: profile.last_name,
    //   company_name: profile.company_name,
    //   email: profile.email,
    //   phone: profile.phone,
    // };
    console.log(
      "update-profile, data  : ",
      userData,
      "-----------------",
      data
    );
    try {
      setIsUserLoading(true);
      const res = await patchUserData({
        token: userToken,
        data,
        id: userData.id,
      });
      // console.log("update-profile, updateprofile res : ", res);
      if (res.status === 200) {
        setUserData((e) => ({ ...e, ...data }));
        setIsLocalImageAdded(false);
        // setModal({ isVisible: true, message: res.data.message });
        showToast("success", "Success", res.data.message);
      } else {
        // setModal({ isVisible: true, message: res.data.message });
        showToast("error", "Error", res.data.message);
        console.log("update-profile, updateprofile res error : ", res.data);
      }
    } catch (error) {
      console.log("update-profile, updateprofile error : ", error);
    } finally {
      setIsUserLoading(false);
    }
  };
  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Update profile
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card title="Update Profile">
                <View className="">
                  <View className="p-4 items-center justify-center">
                    <UploadPicture
                      profileImage={profile.profile_image}
                      prevImage={userData.profile_image ?? null}
                      onImageUpdate={(e) => {
                        setProfile({ ...profile, profile_image: e });
                      }}
                      setIsLocalImageAdded={setIsLocalImageAdded}
                      isLocalImageAdded={isLocalImageAdded}
                    ></UploadPicture>
                  </View>
                  <View className="flex-row flex-wrap mb-4">
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="First Name"
                        value={profile.first_name}
                        setValue={(e) => {
                          setProfile({ ...profile, first_name: e });
                        }}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="Last Name"
                        value={profile.last_name}
                        setValue={(e) => {
                          setProfile({ ...profile, last_name: e });
                        }}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="Company Name"
                        value={profile.company_name}
                        setValue={(e) => {
                          setProfile({ ...profile, company_name: e });
                        }}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="Email"
                        validation={false}
                        value={profile.email}
                        setValue={(e) => {
                          setProfile({ ...profile, email: e });
                        }}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="Phone"
                        value={profile.phone}
                        setValue={(e) => {
                          setProfile({ ...profile, phone: e });
                        }}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1 justify-end mt-5">
                      {isUserLoading ? (
                        <View className="flex-1 w-full  bg-app-e h-10 rounded-lg justify-center">
                          <ActivityIndicator
                            size={"small"}
                            color={colors.app.a}
                          ></ActivityIndicator>
                        </View>
                      ) : (
                        <Button
                          type={`${isButtonEnabled ? "primary" : "disabled"}`}
                          label="Save"
                          onPress={onUpdateProfile}
                        ></Button>
                      )}
                    </View>
                  </View>
                </View>
              </Card>
            </View>
            <View className="hidden lg:flex-1 w-full lg:w-2/6 p-0 lg:pl-4 pt-20">
              <Widget></Widget>
            </View>
          </View>
          {/* <Footer></Footer> */}
        </View>
      </ScrollView>
      {modal.isVisible && (
        <View>
          <ResponseModal
            modalContent={modal.message}
            modalVisible={modal.isVisible}
            setVisible={(e) => setModal({ ...modal, isVisible: e })}
            primaryButtonLabel="Dismiss"
            onPrimaryButtonPress={() => {
              setModal({ isVisible: false, message: null });
            }}
            showSecondaryButton={false}
          ></ResponseModal>
        </View>
      )}
    </View>
  );
};

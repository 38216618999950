import React, { createContext, useState } from "react";

export const UserContext = createContext();

export const UserDataProvider = ({ children }) => {
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [getUserLoading, setGetUserLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userWallet, setUserWallet] = useState({
    amount: 0,
    currency: "USD",
    currency_symbol: "$",
  });
  const [userAddressList, setUserAddressList] = useState([]);
  const [userBillingAddress, setUserBillingAddress] = useState(null);
  const [userApiError, setUserApiError] = useState(null);
  const [userWishlists, setUserWishlists] = useState([]);
  const [userProductsWishlist, setUserProductsWishlist] = useState([]);
  const [userBookings, setUserBookings] = useState([]);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [bookingDetails, setBookingDetails] = useState(null);

  return (
    <UserContext.Provider
      value={{
        isUserLoading,
        userData,
        userApiError,
        userWishlists,
        userBookings,
        bookingDetails,
        selectedBookingId,
        userProductsWishlist,
        userAddressList,
        getUserLoading,
        userBillingAddress,
        userWallet,
        setUserWallet,
        setUserBillingAddress,
        setGetUserLoading,
        setUserAddressList,
        setUserProductsWishlist,
        setUserData,
        setIsUserLoading,
        setUserApiError,
        setUserWishlists,
        setUserBookings,
        setBookingDetails,
        setSelectedBookingId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

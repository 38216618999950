import React, { createContext, useContext, useEffect, useState } from "react";

export const BusinessesContext = createContext();

export const BusinessesProvider = ({ children }) => {
  const [isBusinessLoading, setIsBusinessLoading] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [businessDetails, setBusinessDetails] = useState(null);
  const [businessCategories, setBusinessCategories] = useState([]);
  const [featuredPlaces, setFeaturedPlaces] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [businessApiError, setBusinessApiError] = useState(null);

  return (
    <BusinessesContext.Provider
      value={{
        isBusinessLoading,
        businesses,
        businessCategories,
        featuredPlaces,
        reviews,
        businessApiError,
        businessDetails,
        setIsBusinessLoading,
        setBusinesses,
        setBusinessCategories,
        setFeaturedPlaces,
        setReviews,
        setBusinessApiError,
        setBusinessDetails,
      }}
    >
      {children}
    </BusinessesContext.Provider>
  );
};

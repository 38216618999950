import "react-native-gesture-handler";
import "expo-dev-client";
import Toast from 'react-native-toast-message';
import { StatusBar } from "expo-status-bar";
import "./main.css";
import { SafeAreaProvider } from "react-native-safe-area-context";
import {
  useFonts,
  Quicksand_300Light,
  Quicksand_400Regular,
  Quicksand_500Medium,
  Quicksand_600SemiBold,
  Quicksand_700Bold,
} from "@expo-google-fonts/quicksand";
import { Main } from "./src/main";
import React from "react";
import { NativeWindStyleSheet } from "nativewind";

NativeWindStyleSheet.setOutput({
  default: "native",
});
export default function App() {
  let [fontsLoaded] = useFonts({
    Quicksand_300Light,
    Quicksand_400Regular,
    Quicksand_500Medium,
    Quicksand_600SemiBold,
    Quicksand_700Bold,
  });
  return !fontsLoaded ? null : (
    <SafeAreaProvider>
      <Main />
      <StatusBar style="light"></StatusBar>
      <Toast/>
    </SafeAreaProvider>
  );
}

import React, { useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";

export default function CustomTooltip({
  className,
  children,
  tooltipText,
  align = "default",
  onPress = () => {},
  ...restProps
}) {
  const [isVisible, setIsVisible] = useState(false);

  const handlePressIn = () => {
    setIsVisible(true);
  };

  const handlePressOut = () => {
    setIsVisible(false);
  };

  const tooltipAlignments = {
    "bottom-left": {
      className: "left-0 -bottom-2 rounded-tr-lg rounded-br-lg rounded-bl-lg",
      style: { transform: [{ translateY: 50 }] },
    },
    "bottom-right": {
      className: "right-0 -bottom-2 rounded-tl-lg rounded-bl-lg rounded-br-lg",
      style: { transform: [{ translateY: 50 }] },
    },
    default: {
      className: "right-0 -bottom-2 rounded-tl-lg rounded-bl-lg rounded-br-lg",
      style: { transform: [{ translateY: 50 }] },
    },
  };

  return (
    <View className={`relative ${className}`} {...restProps}>
      <TouchableOpacity
        onPress={onPress}
        onPressIn={handlePressIn}
        onPressOut={handlePressOut}
        onLongPress={handlePressIn}
      >
        {children}
      </TouchableOpacity>
      {isVisible && (
        <View
          className={`p-2 w-40 absolute bg-white border border-gray-300 ${
            tooltipAlignments[align || "default"]?.className
          }`}
          style={tooltipAlignments[align || "default"]?.style}
        >
          <Text className="text text-gray-400" style={{fontSize:12}}>{tooltipText}</Text>
        </View>
      )}
    </View>
  );
}

import {
  View,
  Image,
  Pressable,
  ScrollView,
  ActivityIndicator,
  //onLikeAReview,
} from "react-native";
import { Font } from "../../components/font";
import { Button, Input } from "../../components/input";
import { useContext, useState } from "react";
import { Card } from "../../components/card";
import RemixIcon from "react-native-remix-icon";
import { DEV_API, axiosGet, axiosPost } from "../../services/api";
import { AuthContext } from "../../context/authContext";
import { getFullDate, getFullDateTime } from "../../../utils/date-time-util";
import colors from "../../../colors";
import Toast from "react-native-toast-message";
import { useEffect } from "react";
import { Unautorized } from "./unautorized";
import { Widget } from "../../components/widget";
import { Footer } from "../../components/footer";
import { SafeAreaView } from "react-native-web";

export function ProductReviews({ navigation, route }) {
  console.log("review", route.params.slug);
  const [reviewId, setReviewId] = useState(route?.params?.slug);
  const { user, userToken, isAuthLoading } = useContext(AuthContext);
  const [newReview, setNewReview] = useState({
    message: "",
    rating: 0,
  });

  const myReviewId = 1000;
  const [reviews, setReviews] = useState([]);
  const [isReviewsLoading, setIsReviewsLoading] = useState(false);
  const commentId = 101;
  const [replyToReviewInput, setReplyToReviewInput] = useState(null);
  const [addReviewVisible, setAddReviewVisible] = useState();

  const [showReviewId, setShowReviewId] = useState({
    id: null,
    showReplies: false,
    showAddReplyToReview: false,
  });

  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  // useEffect(() => {
  //   if (route.params.slug) {
  //     GetReviews();
  //   }
  // }, [route.params.slug]);
  // const GetReviews = async () => {
  //   setIsReviewsLoading(true);
  //   await axiosGet({
  //     Url: `/products/${reviewId}/reviews`,
  //     token: userToken,
  //   })
  //     .then((data) => {
  //       console.log("review data", data);

  //       setReviews(data.data.data)
  //         .catch((error) => {
  //           setIsReviewsLoading(false);
  //           showToast("error", "Error :", error);
  //         })
  //         .finally(() => {
  //           setIsReviewsLoading(false);
  //         });
  //     })
  //     .catch((err) => {
  //       console.log("Review, getReview error: ", err);
  //     });
  // };
  // const getCommentsForAPost = async () => {
  //   await axiosGet({
  //     Url: `/vendors/${route?.params?.vendor_id}/posts/${route?.params?.post_id}/comments`,
  //     token: userToken,
  //   })
  //     .then((data) => {
  //       setComments([...data.data.comments].reverse());
  //       // setShowComments(post_id);
  //     })
  //     .catch((err) => {
  //       console.log("vendor, getCommentsForAPost error: ", err);
  //     })
  //     .finally(() => {});
  // };
  useEffect(() => {
    // getCommentsForAPost();
  }, []);

  // const onLikeAComment = async({ id, type }) => {
  //   await axiosPost({
  //     Url: `/vendors/${props.vendorId}/posts/${props.postId}/comments/${id}/${type}`,
  //     token: userToken,
  //     data: {},
  //   })
  //     .then((data) => {
  //       console.log("like data : ", data);
  //       const index =comment?.findIndex((e) => e.id === id);
  //       if (index > -1) {
  //         let post = [...comment];
  //         post[index].is_liked = type === "like" ? true : false;
  //         post[index].likes =
  //           type === "like" ? post[index].likes + 1 : post[index].likes - 1;
  //           setComment(post);
  //       }
  //     })
  //     .catch((err) => {
  //       showToast("error", "Error", "Something went wrong.");
  //       console.log("comments, onLikeAComment error: ", err);
  //     })
  //     .finally(() => {});
  // };
  const getRatingStars = ({ rating }) => {
    const rating1 = [...Array(Math.floor(rating)).keys()];
    const ishalfStar = rating1 % 1 === 0.5;
    return (
      <>
        {rating1.map((e, i) => (
          <RemixIcon
            name="star-fill"
            size={16}
            color={colors.app.i}
            className="flex-shrink-0"
            key={`icon_${i}`}
          ></RemixIcon>
        ))}
        {ishalfStar && (
          <RemixIcon
            name="star-half-fill"
            size={16}
            color={colors.app.i}
            className="flex-shrink-0"
          ></RemixIcon>
        )}
      </>
    );
  };
  const submitReviewHandler = async () => {
    setAddReviewVisible(true);
    if (newReview.review !== "") {
      await axiosPost({
        Url: `/products/${reviewId}/reviews/`,

        token: userToken,
        data: newReview,
      })
        .then((data) => {
          setNewReview({
            message: "",
            rating: 0,
          });
          const array = [...reviews];
          const reviewData = {
            ...data.data.review,
          };
          array.unshift(reviewData);
          setReviews((e) => ({ ...e, reviews: array }));
          showToast("success", "Success", "Successful.");
          setAddReviewVisible(false);
        })
        .catch((error) => {
          console.log("product review, submitReviewHandler error  : ", error);
        })
        .finally(() => {});
    }
  };

  return user ? (
    <View className="flex-1 bg-app-e lg:bg-gray-50 h-full">
      <View className="flex-1">
        <View className="rounded-t-2xl lg:rounded-none overflow-hidden flex-1 bg-gray-50">
          <ScrollView
            className="flex-1 p-4 rounded-t-2xl h-4"
            contentContainerStyle={{
              flexGrow: 1,
              alignItems: "center",
            }}
            overScrollMode="never"
            // refreshControl={
            //   <RefreshControl
            //     refreshing={refreshing}
            //     onRefresh={() => {
            //       getWishlist();
            //     }}
            //   />
            // }
          >
            <View className="flex-1 w-full justify-center items-center ">
              <View className="flex-col w-full lg:w-11/12 lg:px-4">
                <View className="my-5 hidden lg:flex">
                  <Font className="text-xl font-semibold text-app-d">
                    Reviews
                  </Font>
                </View>
                <View className="w-full flex-col lg:flex-row">
                  <View className="w-full flex-1">
                    {/* ------------------ add your review ------------- */}
                    {!addReviewVisible && (
                      <Card title="Add a review" className="mt-1">
                        <View className="w-full flex-col sm:flex-row justify-between items-center p-2 ">
                          {/* <View className="flex-row justify-start overflow-hidden rounded-full pb-4 mr-3">
                              <Image
                                // resizeMode="contain"
                                source={require("../../../assets/user1.jpg")}
                                className="w-10 h-10 rounded-full"
                              ></Image>
                            </View> */}
                          <View className="w-full">
                            <View className="w-full flex-row items-center justify-start py-3">
                              {[...Array(5).keys()].map((e, i) => (
                                <Pressable
                                  onPress={() =>
                                    setNewReview((prev) => ({
                                      ...prev,
                                      rating: i + 1,
                                    }))
                                  }
                                  key={`starts_${i}`}
                                >
                                  <RemixIcon
                                    name={
                                      newReview.rating === 0
                                        ? "ri-star-line"
                                        : i + 1 <= newReview.rating
                                        ? "ri-star-fill"
                                        : "ri-star-line"
                                    }
                                    size={20}
                                    color={colors.app.i}
                                    className="flex-shrink-0"
                                    key={`icon_${i}`}
                                  ></RemixIcon>
                                </Pressable>
                              ))}
                            </View>
                            <View className="flex-row items-start justify-center pb-3">
                              <Input
                                type="longtext"
                                value={newReview.message}
                                setValue={(e) => {
                                  setNewReview((ee) => ({ ...ee, message: e }));
                                }}
                                // label="Email"
                                placeholder="Review"
                                // validation={true}
                                // isValid={(e) => setIsInputValid({ e, type: "email" })}
                              ></Input>
                            </View>
                            <View className="w-full flex-row items-center justify-center">
                              <Button
                                type="primary"
                                label="Post review"
                                onPress={submitReviewHandler}
                              />
                            </View>
                          </View>
                        </View>
                      </Card>
                    )}
                    <Card title={`Your review`} className="flex-1 w-full mt-8">
                      <ScrollView className="flex-1 w-full p-4 py-0">
                        {/* {console.log("Object 222", reviews[0]?.user_name)} */}

                        {reviews?.map((e, i) => (
                          <View
                            className="w-full flex-row justify-between items-center bg-app-b rounded-lg "
                            key={`reviews_${i}`}
                          >
                            <View className="flex-row self-stretch justify-start items-start rounded-full mr-3">
                              <Image
                                // resizeMode="contain"
                                source={
                                  // e.user_profile_image_url &&
                                  // e.user_profile_image_url !== ""
                                  //   ? {
                                  //       uri: `${DEV_API}/media/${e.user_profile_image_url}`,
                                  //     }
                                  //   :
                                  require("../../../assets/user1.jpg")
                                }
                                className="w-10 h-10 rounded-full"
                              ></Image>
                            </View>
                            <View className="flex-1 flex-col rounded-md bg-app-c2">
                              <View className="w-full flex-row px-3 pt-1">
                                <Font className="py-1 font-bold">
                                  {e.user_name}
                                </Font>
                              </View>
                              <View className="w-full pt-1 px-3">
                                <Font className="text-xs text-app-d1">
                                  {getFullDateTime(e.created_at)}
                                </Font>
                              </View>

                              <View className="w-full flex-row py-2 px-3">
                                {getRatingStars({
                                  rating: e.rating ?? 5,
                                })}
                                {/* {getRatingStars({
                                    rating: 5,
                                  })} */}
                              </View>
                              <View className="w-full px-3 pb-3">
                                <Font>{e.message}</Font>
                              </View>
                              <View className="w-full flex-row items-center px-3 py-1">
                                <View className="w-1/3 flex-row">
                                  <Font className="text-xs pr-2 text-app-d1">
                                    {e.likes ?? 100}
                                    {/* 100 */}
                                  </Font>
                                  <Pressable
                                    onPress={() =>
                                      onLikeAReview({
                                        id: e.id,
                                        type: e.is_liked ? "unlike" : "like",
                                      })
                                    }
                                    className="flex-row items-center"
                                  >
                                    <RemixIcon
                                      name={
                                        "ri-thumb-up-line"
                                        // e.is_liked
                                        //   ? "ri-thumb-up-fill"
                                        //   : "ri-thumb-up-line"
                                      }
                                      size={11}
                                      color={
                                        colors.app.d1
                                          ? // e.is_liked
                                            colors.app.f
                                          : colors.app.d1
                                      }
                                    />
                                  </Pressable>
                                </View>
                                <View className="w-1/3 flex-row">
                                  <Pressable
                                  // onPress={() =>
                                  //   setShowReviewId((prev) => ({
                                  //     ...prev,
                                  //     id:
                                  //       prev.id === e.id &&
                                  //       prev.showAddReplyToReview
                                  //         ? null
                                  //         : prev.id === e.id &&
                                  //           prev.showReplies
                                  //         ? e.id
                                  //         : e.id,
                                  //     showAddReplyToReview:
                                  //       prev.id === e.id &&
                                  //       prev.showAddReplyToReview
                                  //         ? false
                                  //         : true,
                                  //     showReplies: false,
                                  //   }))
                                  // }
                                  >
                                    <Font className="text-xs pr-2 text-app-d">
                                      Reply
                                    </Font>
                                  </Pressable>
                                </View>
                                <View className="w-1/3 flex-row">
                                  <Font className="text-xs pr-2 text-app-d1">
                                    {e.replies ?? 100}
                                    {/* 100 */}
                                  </Font>
                                  <Pressable
                                  // onPress={() =>
                                  //   setShowReviewId((prev) => ({
                                  //     ...prev,
                                  //     id:
                                  //       prev.id === e.id && prev.showReplies
                                  //         ? null
                                  //         : prev.id === e.id &&
                                  //           prev.showAddReplyToReview
                                  //         ? e.id
                                  //         : e.id,
                                  //     showReplies:
                                  //       prev.id === e.id && prev.showReplies
                                  //         ? false
                                  //         : prev.id === e.id &&
                                  //           !prev.showReplies
                                  //         ? true
                                  //         : true,
                                  //     showAddReplyToReview: false,
                                  //   }))
                                  // }
                                  >
                                    <Font className="text-xs pr-2 text-app-d">
                                      All replies
                                    </Font>
                                  </Pressable>
                                </View>
                              </View>
                              <View className="w-full flex-col ">
                                {showReviewId.id === myReviewId &&
                                  showReviewId.showAddReplyToReview && (
                                    <View className="w-full flex-col py-1 px-3 my-3">
                                      <View className="w-full pb-1">
                                        <Font>Reply to this review</Font>
                                      </View>
                                      <View className="sm:w-full flex-col sm:flex-row justify-between">
                                        <View className="w-full pb-3 sm:pb-0 sm:w-3/4 flex-row items-center justify-center rounded-l-md">
                                          <Input
                                            type="text"
                                            value={replyToReviewInput}
                                            setValue={(e) => {
                                              setReplyToReviewInput(e);
                                            }}
                                            placeholder="reply to this comment..."
                                          ></Input>
                                          <Pressable className="bg-app-c h-10 px-3 flex justify-center items-center border border-app-c rounded-r-md">
                                            <RemixIcon
                                              name="ri-send-plane-2-line"
                                              size={15}
                                            />
                                          </Pressable>
                                        </View>
                                      </View>
                                    </View>
                                  )}
                                {/* ---------------------- replies to a comment ------------------------ */}
                                {showReviewId.id === myReviewId &&
                                  showReviewId.showReplies &&
                                  [...Array(3).keys()].map((e, i) => (
                                    <View
                                      className="w-full flex-row justify-between items-center p-2 border-b border-app-c"
                                      key={`comment_replies${i}`}
                                    >
                                      <View className="flex-row justify-start overflow-hidden rounded-full pb-4 mr-3">
                                        <Image
                                          // resizeMode="contain"
                                          source={require("../../../assets/user1.jpg")}
                                          className="w-10 h-10 rounded-full"
                                        ></Image>
                                      </View>
                                      <View className="w-full flex-1">
                                        <View className="w-full flex-row px-3 pt-1">
                                          <Font className="w-full py-1 font-bold">
                                            user_name
                                          </Font>
                                        </View>
                                        <View className="w-full pt-1 px-3">
                                          <Font className="text-xs text-app-d1">
                                            {e.created_at ?? "1-2-23"}
                                          </Font>
                                        </View>
                                        <View className="w-full flex-1">
                                          <View className="w-full flex-row bg-app-c2 p-3 rounded-md">
                                            <Font>
                                              this is reply to other comment
                                            </Font>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  ))}
                              </View>
                            </View>
                          </View>
                        ))}
                      </ScrollView>
                    </Card>
                    {console.log("reviews.length", reviews)}
                    {reviews.length > 0 && !isReviewsLoading ? (
                      <Card title={`All reviews`} className="flex-1 w-full">
                        <ScrollView className="flex-1 w-full p-4 pt-0">
                          {reviews?.map((e, i) => (
                            <View
                              className="w-full flex-row justify-between items-center bg-app-b rounded-lg p-2"
                              key={`reviews${i}`}
                            >
                              <View className="flex-row self-stretch justify-start items-start rounded-full mr-3">
                                <Image
                                  // resizeMode="contain"
                                  source={
                                    e.user_profile_image_url &&
                                    e.user_profile_image_url !== ""
                                      ? {
                                          uri: `${DEV_API}/media/${e.user_profile_image_url}`,
                                        }
                                      : require("../../../assets/user1.jpg")
                                  }
                                  className="w-10 h-10 rounded-full"
                                ></Image>
                              </View>
                              <View className="flex-1 flex-col rounded-md bg-app-c2">
                                <View className="w-full flex-row px-3 pt-1">
                                  <Font className="py-1 font-bold">
                                    {e.user_name}
                                  </Font>
                                  {e.user_role === "vendor" && (
                                    <Font className="ml-2 p-1 bg-app-e rounded-md text-app-a text-xs">
                                      {e.user_role}
                                    </Font>
                                  )}
                                </View>
                                <View className="w-full pt-1 px-3">
                                  <Font className="text-xs text-app-d1">
                                    {getFullDateTime(e.created_at) ?? ""}
                                  </Font>
                                </View>

                                <View className="w-full flex-row py-2 px-3">
                                  {getRatingStars({
                                    rating: e.rating ?? 5,
                                  })}
                                </View>
                                <View className="w-full px-3 pb-3">
                                  <Font>{e.message}</Font>
                                </View>
                                <View className="w-full flex-row items-center px-3 py-1">
                                  <View className="w-1/3 flex-row">
                                    <Font className="text-xs pr-2 text-app-d1">
                                      {e.likes ?? 100}
                                    </Font>
                                    <Pressable
                                      onPress={() =>
                                        onLikeAReview({
                                          id: e.id,
                                          type: e.is_liked ? "unlike" : "like",
                                        })
                                      }
                                      className="flex-row items-center"
                                    >
                                      <RemixIcon
                                        name={
                                          e.is_liked
                                            ? "ri-thumb-up-fill"
                                            : "ri-thumb-up-line"
                                        }
                                        size={11}
                                        color={
                                          e.is_liked
                                            ? colors.app.f
                                            : colors.app.d1
                                        }
                                      />
                                    </Pressable>
                                  </View>
                                  <View className="w-1/3 flex-row">
                                    <Pressable
                                      onPress={() =>
                                        setShowReviewId((prev) => ({
                                          ...prev,
                                          id:
                                            prev.id === e.id &&
                                            prev.showAddReplyToReview
                                              ? null
                                              : prev.id === e.id &&
                                                prev.showReplies
                                              ? e.id
                                              : e.id,
                                          showAddReplyToReview:
                                            prev.id === e.id &&
                                            prev.showAddReplyToReview
                                              ? false
                                              : true,
                                          showReplies: false,
                                        }))
                                      }
                                    >
                                      <Font className="text-xs pr-2 text-app-d">
                                        Reply
                                      </Font>
                                    </Pressable>
                                  </View>
                                  <View className="w-1/3 flex-row">
                                    <Font className="text-xs pr-2 text-app-d1">
                                      {e.replies ?? 100}
                                    </Font>
                                    <Pressable
                                      onPress={() =>
                                        setShowReviewId((prev) => ({
                                          ...prev,
                                          id:
                                            prev.id === e.id && prev.showReplies
                                              ? null
                                              : prev.id === e.id &&
                                                prev.showAddReplyToReview
                                              ? e.id
                                              : e.id,
                                          showReplies:
                                            prev.id === e.id && prev.showReplies
                                              ? false
                                              : prev.id === e.id &&
                                                !prev.showReplies
                                              ? true
                                              : true,
                                          showAddReplyToReview: false,
                                        }))
                                      }
                                    >
                                      <Font className="text-xs pr-2 text-app-d">
                                        All replies
                                      </Font>
                                    </Pressable>
                                  </View>
                                </View>
                                <View className="w-full flex-col ">
                                  {showReviewId.id === e.id &&
                                    showReviewId.showAddReplyToReview && (
                                      <View className="w-full flex-col py-1 px-3 my-3">
                                        <View className="w-full pb-1">
                                          <Font>Reply to this review</Font>
                                        </View>
                                        <View className="sm:w-full flex-col sm:flex-row justify-between">
                                          <View className="w-full pb-3 sm:pb-0 sm:w-3/4 flex-row items-center justify-center rounded-l-md">
                                            <Input
                                              type="text"
                                              value={replyToReviewInput}
                                              setValue={(e) => {
                                                setReplyToReviewInput(e);
                                              }}
                                              placeholder="reply to this comment..."
                                            ></Input>
                                            <Pressable className="bg-app-c h-10 px-3 flex justify-center items-center border border-app-c rounded-r-md">
                                              <RemixIcon
                                                name="ri-send-plane-2-line"
                                                size={15}
                                              />
                                            </Pressable>
                                          </View>
                                        </View>
                                      </View>
                                    )}
                                  {/* ---------------------- replies to a comment ------------------------ */}
                                  {showReviewId.id === e.id &&
                                    showReviewId.showReplies &&
                                    [...Array(3).keys()].map((e, i) => (
                                      <View
                                        className="w-full flex-row justify-between items-center p-2 border-b border-app-c"
                                        key={`comment_replies${i}`}
                                      >
                                        <View className="flex-row justify-start overflow-hidden rounded-full pb-4 mr-3">
                                          <Image
                                            // resizeMode="contain"
                                            source={require("../../../assets/user1.jpg")}
                                            className="w-10 h-10 rounded-full"
                                          ></Image>
                                        </View>
                                        <View className="w-full flex-1">
                                          <View className="w-full flex-row px-3 pt-1">
                                            <Font className="w-full py-1 font-bold">
                                              user_name
                                            </Font>
                                          </View>
                                          <View className="w-full pt-1 px-3">
                                            <Font className="text-xs text-app-d1">
                                              {e.created_at ?? "1-2-23"}
                                            </Font>
                                          </View>
                                          <View className="w-full flex-1">
                                            <View className="w-full flex-row bg-app-c2 p-3 rounded-md">
                                              <Font>
                                                this is reply to other comment
                                              </Font>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                    ))}
                                </View>
                              </View>
                            </View>
                          ))}
                        </ScrollView>
                      </Card>
                    ) : reviews.length === 0 && !isReviewsLoading ? (
                      <Card
                        // title={`Reviews`}
                        className="flex"
                      >
                        <View className="w-full flex-row h-80 justify-center items-center">
                          <Font className="text-app-d1">No reviews found.</Font>
                        </View>
                      </Card>
                    ) : (
                      <View className="flex-1 w-full justify-center h-40">
                        <ActivityIndicator
                          size={"small"}
                          color={colors.app.e}
                        ></ActivityIndicator>
                      </View>
                    )}
                  </View>
                  <View className="hidden lg:flex lg:items-end w-full lg:w-2/6 p-0 lg:pl-4 pt-5 lg:pt-0">
                    <View className="w-4/5">
                      <Widget></Widget>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <Footer></Footer>
          </ScrollView>
        </View>
      </View>
    </View>
  ) : (
    !user && !isAuthLoading && <Unautorized />
  );
}

import { View, Text, Modal, ActivityIndicator, TouchableOpacity } from "react-native";
import React from "react";
import colors from "../../colors";
import RemixIcon from "react-native-remix-icon";
import { Font } from "./font";

export const PleaseWaitModal = ({
  message = "Please Wait !!!",
  isButton1,
  setVisible,
  showSuccess,
  modalVisible,
  showSecondaryButton,
  primaryButtonLabel,
  secondaryButtonLabel,
  onPrimaryButtonPress,
  showLoader
}) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        // Alert.alert("Modal has been closed.");
        setVisible(false);
      }}
    >
      <View className="flex-1 justify-center items-center bg-black/50">
        <View className="w-80 bg-app-a border border-app-c min-h-[160px] max-h-full rounded-lg justify-center items-center -mt-12">
          <View className="grow justify-center items-center  px-4">
            {showSuccess ? (
              <View className="justify-center items-center ">
                <View className="rounded-full w-7 h-7 bg-app-g items-center justify-center">
                  <RemixIcon
                    name="check-line"
                    color={colors.app.a}
                    size="24"
                  ></RemixIcon>
                </View>
              </View>
            ) : (showLoader &&
              <ActivityIndicator
                className="self-center justify-self-center absolute flex-1 w-full h-full -top-6"
                color={colors.app.e}
                size="large"
              />
            )}
            <Text className="pt-8">{message}</Text>
          </View>
          {isButton1 && (
            <View className="w-full items-center flex-row flex-1">
              <View
                className={`items-center ${
                  showSecondaryButton ? "w-1/2" : "w-full"
                }`}
              >
                <TouchableOpacity
                  className={`w-full  text-center ${
                    showSecondaryButton && "border-r border-gray-200"
                  }`}
                  onPress={() => onPrimaryButtonPress()}
                >
                  <Font className="font-semibold text-gray-500 text-base text-center">
                    {primaryButtonLabel}
                  </Font>
                </TouchableOpacity>
                {/* <Button
                className="w-full"
                label={primaryButtonLabel}
                type="primary"
                onPress={() => onPrimaryButtonPress()}
              ></Button> */}
              </View>
              {showSecondaryButton && (
                <View
                  className={`${showSecondaryButton ? "w-1/2" : "w-full"} `}
                >
                  <TouchableOpacity
                    className="w-full text-center"
                    onPress={() => onSecondaryButtonPress()}
                  >
                    <Font className="font-semibold text-gray-500 text-base text-center">
                      {secondaryButtonLabel}
                    </Font>
                  </TouchableOpacity>
                  {/* <Button
                  className="w-full"
                  label={secondaryButtonLabel}
                  type="primary"
                  onPress={() => onSecondaryButtonPress()}
                ></Button> */}
                </View>
              )}
            </View>
          )}
        </View>
      </View>
    </Modal>
  );
};

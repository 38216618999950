import React, { useState } from "react";
const BlogsContext = React.createContext({
  blogs: [],
  setBlogs: () => [],
});

const BlogsContextProvider = (props) => {
  const [blogs, setBlogs] = useState([]);
  return (
    <BlogsContext.Provider
      value={{
        blogs: blogs,
        setBlogs: setBlogs,

      }}
    >
      {props.children}
    </BlogsContext.Provider>
  );
};
export { BlogsContextProvider };
export default BlogsContext;


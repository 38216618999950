import React, { useContext, useEffect, useState } from "react";
import {
  Pressable,
  ScrollView,
  View,
  Text,
  TouchableOpacity,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import { AntDesign } from "@expo/vector-icons";
import colors from "../../../colors";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Switcher } from "../../components/input";
import { Widget } from "../../components/widget";
import { Unautorized } from "./unautorized";
import { AuthContext } from "../../context/authContext";
import { getOrders, postCancelOrder } from "../../services/api";
import { OrdersContext } from "../../context/ordersContext";
import { ActivityIndicator } from "react-native";
import { Card } from "../../components/card";
export const Orders = ({ navigation }) => {
  const { user, userToken } = useContext(AuthContext);
  const [refreshing, setRefreshing] = useState(false);
  const [pagination, setPagination] = useState(0);
  const [loadMoreData, setLoadMoreData] = useState(true);
  const [filter, setFilter] = useState("all");
  const {
    isOrdersLoading,
    orders,
    ordersApiError,
    setIsOrdersLoading,
    setOrders,
    setOrdersApiError,
  } = useContext(OrdersContext);
 // const [page, setPage] = useState(1);

  const onChangeFilter = (e) => {
    setFilter(e);
  };
  const limitPerPage = 2;
  const getUserOrders = async (token) => {
    console.log("token" ,token)
    try {
      
       setRefreshing(true);
      setIsOrdersLoading(true);

      const res = await getOrders({
        token,
        filter,
        offset: pagination,
        limit: limitPerPage,
      });
      console.log("pagination", pagination , limitPerPage ,filter);
      //console.log("New Data", res.data);
      console.log("orders res" ,res.data)
      if (res.status === 200) {
        if (
          orders?.length + res.data?.data?.length ==
          res.data.data.total_count
        ) {
          setLoadMoreData(false);
        }
         console.log("res.data.data", res.data.data);
         setPagination(orders?.length + res.data?.data?.length);
         setOrders([...orders, ...res.data.data]);
        //setUserBookings([...orders, ...res.data.data]);

        //setOrders(res.data.data);
      } else {
        setOrders([]);
        console.log("orders, getUserOrders res error : ", res.data);
      }
    } catch (error) {
      setOrders([]);
      console.log("orders, getUserOrders error : ", error);
    } finally {
       setRefreshing(false);
      setIsOrdersLoading(false);
    }
  };
  useEffect(() => {
    if (user && userToken !== null) {
      getUserOrders(userToken);
      {console.log("userToken 4444"  ,userToken)}
      // setFilter("all");
    }
  }, [user, userToken, filter]);
  const onCancelOrder = async (id) => {
    try {
      // setRefreshing(true);
      setIsOrdersLoading(true);
      const res = await postCancelOrder({ token: userToken, orderId: id });
      if (res.status === 200) {
        // setOrders(res.data.data);
      } else {
        console.log("orders, onCancelOrder res error : ", res.data);
      }
    } catch (error) {
      console.log("orders, onCancelOrder error : ", error);
    } finally {
      // setRefreshing(false);
      setIsOrdersLoading(false);
    }
  };
  const getOrderDetails = async ({ id }) => {
    navigation.navigate("order-details", { id });
  };
  return user ? (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="rounded-t-2xl lg:rounded-none overflow-hidden flex-1 bg-gray-50">
        <ScrollView className="p-4 rounded-t-2xl ">
          <View className="flex-1 w-full justify-center items-center">
            <View className="flex-col w-full lg:w-11/12 lg:px-4">
              <View className="my-5 hidden lg:flex flex-row">
                <Font className="text-lg font-semibold text-app-d flex-1">
                  Orders
                </Font>
              </View>
              <View className="w-full flex-col lg:flex-row">
                <View className=" w-full flex-1">
                  <View className="mb-5">
                    <Switcher
                      options={[
                        { label: "All", value: "all" },
                        // { label: "Pending", value: "created" },
                        { label: "Delivered", value: "delivered" },
                        { label: "Cancelled", value: "cancelled" },
                      ]}
                      value={filter}
                      setValue={(e) => onChangeFilter(e)}
                    ></Switcher>
                  </View>

                  {orders.length !== 0 && !isOrdersLoading ? (
                    orders.map((e, i) => (
                      <Pressable
                        className="flex pb-4 rounded-2xl bg-app-a active:bg-app-c1 border  overflow-hidden border-gray-200 mb-4"
                        key={`orders_${i}`}
                        onPress={() => getOrderDetails({ id: e.id })}
                      >
                        <View className="w-full p-4 flex-1">
                          <View
                            className="flex-row w-full justify-between items-center pb-2"
                            style={{
                              marginVertical: 2,
                            }}
                          >
                            <View className="flex-row items-center justify-start">
                              <View
                                className="flex-row items-center px-2 py-1 rounded bg-app-c"
                                style={{
                                  marginVertical: 2,
                                }}
                              >
                                <Font
                                  className="text-xs text-app-d"
                                  weight={600}
                                  numberOfLines={1}
                                >
                                  #{e.id}
                                </Font>
                              </View>
                              <Font className="text-app-d1"> | </Font>
                              <Font
                                className=" text-app-d1"
                                weight={500}
                                numberOfLines={1}
                              >
                                {new Date(e.created_at).toDateString()}
                              </Font>
                            </View>
                            {/* <Pressable
                                className="flex-row items-center px-2 py-1 rounded bg-app-e3 "
                                style={{
                                  marginVertical: 2,
                                }}
                                onPress={() => onCancelOrder(e.id)}
                              >
                                <Font
                                  className="text-xs text-app-e pr-2"
                                  weight={600}
                                  numberOfLines={1}
                                >
                                  cancel order
                                </Font>
                                <AntDesign
                                  name="delete"
                                  size={15}
                                  color={colors.app.e}
                                />
                              </Pressable> */}
                          </View>
                          {/* <Font
                            className="text-md lg:text-[15px] text-app-d leading-4 lg:leading-6 my-2"
                            weight={600}
                            numberOfLines={2}
                          >
                            #{e.id}
                          </Font> */}
                          {e.product_orders.map((e, i) => (
                            <View
                              className="flex-row w-full border border-gray-200 p-4 bg-app-b rounded-lg mb-4"
                              key={`product_orders_${i}`}
                            >
                              <View className="flex-row flex-wrap w-full">
                                <View
                                  className="flex-row w-1/2 items-center"
                                  style={{
                                    marginVertical: 2,
                                  }}
                                >
                                  <RemixIcon
                                    name="store-fill"
                                    size={16}
                                    color={colors.app.f1}
                                    className="mr-1 flex-shrink-0"
                                  ></RemixIcon>
                                  <Font
                                    className=" text-app-d1"
                                    weight={500}
                                    numberOfLines={1}
                                  >
                                    Vendor id : {e.vendor_id}
                                  </Font>
                                </View>
                                <View
                                  className="flex-row w-1/2 items-center justify-end"
                                  style={{
                                    marginVertical: 2,
                                  }}
                                >
                                  <View
                                    className={`flex-row rounded-full px-4 py-1 ${
                                      e.status === "cancelled_by_vendor" ||
                                      e.status === "cancelled_by_customer"
                                        ? "bg-app-e2"
                                        : e.status === "delivered"
                                        ? "bg-app-g2"
                                        : " bg-app-h2"
                                    }`}
                                  >
                                    {/* <RemixIcon
                                      name="store-fill"
                                      size={16}
                                      color={colors.app.f1}
                                      className="mr-1 flex-shrink-0"
                                    ></RemixIcon> */}
                                    <Font
                                      className={`${
                                        e.status === "cancelled_by_vendor" ||
                                        e.status === "cancelled_by_customer"
                                          ? "text-app-e"
                                          : e.status === "delivered"
                                          ? "text-app-g"
                                          : " text-app-h"
                                      }`}
                                      weight={500}
                                      numberOfLines={1}
                                    >
                                      {e.status === "cancelled_by_vendor"
                                        ? "cancelled by vendor"
                                        : e.status === "cancelled_by_customer"
                                        ? "cancelled by customer"
                                        : e.status === "out for delivery"
                                        ? "out for delivery"
                                        : e.status === "read_for_pickup"
                                        ? "ready for pickup"
                                        : e.status}
                                    </Font>
                                  </View>
                                </View>
                                <View
                                  className="flex-row w-1/2 items-center"
                                  style={{
                                    marginVertical: 2,
                                  }}
                                >
                                  <RemixIcon
                                    name="group-fill"
                                    size={16}
                                    color={colors.app.f1}
                                    className="mr-1 flex-shrink-0"
                                  ></RemixIcon>
                                  <Font
                                    className=" text-app-d1"
                                    weight={500}
                                    numberOfLines={1}
                                  >
                                    {e.product_order_items.length}{" "}
                                    {`${
                                      e.product_order_items.length > 1
                                        ? "Items"
                                        : "Items"
                                    }`}
                                  </Font>
                                  <View className="w-4"></View>
                                </View>
                              </View>
                            </View>
                          ))}
                        </View>

                        {/* <View className="flex-row w-full">
                    <Pressable
                      className="p-4"
                      onPress={() => setPage((e) => e - 1)}
                    >
                      <Font className="text-app-e" weight={600}>
                        &lt; Newer
                      </Font>
                    </Pressable>
                    <View className="flex-1"></View>
                    <Pressable
                      className="p-4"
                      onPress={() => setPage((e) => e + 1)}
                    >
                      <Font className="text-app-e" weight={600}>
                        Older &gt;
                      </Font>
                    </Pressable>
                  </View> */}
                      </Pressable>
                    ))
                  ) : orders.length === 0 && !isOrdersLoading ? (
                    <Card
                      // title={`Reviews`}
                      className="flex"
                    >
                      <View className="w-full flex-row h-80 justify-center items-center">
                        <Font className="text-app-d1">No orders found.</Font>
                      </View>
                    </Card>
                  ) : (
                    <Card className="w-full flex-1">
                      <View className="flex-1 w-full justify-center h-40">
                        <ActivityIndicator
                          size={"small"}
                          color={colors.app.e}
                        ></ActivityIndicator>
                      </View>
                    </Card>
                  )}
                </View>
                {loadMoreData && (
                  <View className="text-center">
                    {console.log("userToken"  ,userToken)}
                    <TouchableOpacity onPress={() => getUserOrders(userToken)}>
                      <Text className="text-center text-app-a bg-app-e w-32 py-2 rounded-lg">
                        Load More
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
                <View className="hidden lg:flex lg:items-end w-full lg:w-2/6 p-0 lg:pl-4 pt-5 lg:pt-0">
                  <View className="w-4/5">
                    <Widget></Widget>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <Footer></Footer>
        </ScrollView>
      </View>
    </View>
  ) : (
    <Unautorized />
  );
};

import React, { useContext, useEffect, useState } from "react";
import {
  View,
  TouchableOpacity,
  Pressable,
  ActivityIndicator,
} from "react-native";

import { ScrollView } from "react-native";
import RemixIcon from "react-native-remix-icon";
import { Card } from "../../components/card";
import colors from "../../../colors";
import { Font } from "../../components/font";
import {
  AcceptRequest,
  GetGroupJoinRequest,
  RejectRequest,
} from "../../services/api";
import { AuthContext } from "../../context/authContext";

export default function GroupJoinRequests({ navigation, route }) {
  const [isLoading, setIsLoading] = useState();
  const [memberRequests, setMemberRequests] = useState();
  const { userToken } = useContext(AuthContext);
  const { groupId } = route?.params || {};

  const GetRequests = async () => {
    try {
      setIsLoading(true);
      const res = await GetGroupJoinRequest(groupId, userToken);
      if (res.status == 200) {
        setMemberRequests(res.data?.data || []);
      } else {
        console.log("GetGroupJoinRequest Response:", res.status, res.data);
      }
    } catch (error) {
      console.log("GetRequests error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    GetRequests();
  }, []);

  const RejectRequestOfUser = async (request_id) => {
    try {
      const res = await RejectRequest(groupId, request_id, userToken);
      if (res.status == 200) {
        setMemberRequests((prevList) =>
          prevList.filter((member) => member.id !== request_id)
        );
      } else {
        console.log("RejectRequest Response:", res.status, res.data);
      }
    } catch (error) {
      console.log("RejectRequestOfUser error:", error);
    }
  };

  const AcceptRequestOfUser = async (request_id) => {
    try {
      const res = await AcceptRequest(groupId, request_id, userToken);
      if (res.status == 200) {
        setMemberRequests((prevList) =>
          prevList.filter((member) => member.id !== request_id)
        );
      } else {
        console.log("AcceptRequest Response:", res.status, res.data);
      }
    } catch (error) {
      console.log("AcceptRequestOfUser error:", error);
    }
  };

  return (
    <ScrollView
      className="p-4 rounded-t-2xl bg-gray-50"
      contentContainerStyle={{
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View className="max-w-screen-lg w-full h-full">
        <View className="max-w-screen-lg w-full flex-col justify-center items-center">
          {/* ------------------------Join requests - section----------------------------------- */}
          <>
            <View className="w-full flex-col item-center">
              {isLoading ? (
                <ActivityIndicator
                  className="self-center justify-self-center flex-1 w-full h-full"
                  color={colors.app.e}
                  size="small"
                />
              ) : !memberRequests?.length ? (
                <View className="py-4 flex-col justify-center items-center">
                  <Font>No requests yet</Font>
                </View>
              ) : (
                memberRequests?.map((e, i) => (
                  <Card className="mb-6 lg:mb-0 lg:mr-2 lg:flex-1" key={i}>
                    <TouchableOpacity
                      className="px-4 py-2 flex-row justify-between w-full"
                      // onPress={() => navigation.navigate("add-member")}
                    >
                      <View className="flex-row justify-start items-center">
                        <View className="flex justify-center items-center">
                          <RemixIcon
                            name="ri-user-fill"
                            size={16}
                            color={colors.app.g4}
                            className="mr-1 lg:mr-4 -ml-2.5 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                          />
                        </View>
                        <Font className="font-medium w-[190]">
                          {/* James (james123@gmail.com) */}
                          {e?.requester.first_name +
                            " " +
                            e?.requester.last_name}
                          <Font className="font-medium">
                            {/* James (james123@gmail.com) */}
                            {" " + e?.requester.email + " "}
                          </Font>
                        </Font>
                      </View>
                      <View className="-mr-2.5 lg:mr-0 flex-row justify-center items-center">
                        <Pressable
                          onPress={() => AcceptRequestOfUser(e?.id)}
                          className="p-1 mr-1 lg:mr-2 flex justify-center items-center bg-green-200 border border-green-600 rounded-lg"
                        >
                          <Font className="text-xs text-green-600">Accept</Font>
                        </Pressable>
                        <Pressable
                          onPress={() => RejectRequestOfUser(e?.id)}
                          className="p-1 flex justify-center items-center bg-red-200 border border-app-e rounded-lg"
                        >
                          <Font className="text-xs text-app-e">Decline</Font>
                        </Pressable>
                      </View>
                    </TouchableOpacity>
                  </Card>
                ))
              )}
            </View>
          </>
        </View>
        {/* <Footer navigation={navigation} /> */}
      </View>
    </ScrollView>
  );
}

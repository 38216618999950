import { ScrollView, View, Image, Pressable, TouchableOpacity } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../colors";
import { DEV_API, navigate } from "../services/api";
import { Font } from "./font";
import { useNavigation } from "@react-navigation/native";
import { useContext, useState } from "react";
import { AuthContext } from "../context/authContext";
import { LinearGradient } from "expo-linear-gradient";
import { Button } from "./input";

// MVP
export function LatestArticles({ data }) {
  return (
    <View className="w-full px-3">
      <ScrollView
        horizontal
        // showsHorizontalScrollIndicator={false}
      >
        <View className="flex-row gap-4">
          {[...Array(5).keys()].map((e, i) => (
            <Pressable
              className="w-80 h-88 rounded-lg overflow-hidden border border-gray-200"
              key={i}
              onPress={() =>
                navigate({ name: "post", params: { slug: "sample" } })
              }
            >
              <View className="h-32 w-full">
                <Image
                  source={require("../../assets/banner.jpg")}
                  className="w-full h-full"
                ></Image>
              </View>
              <View className="px-2 py-4">
                <View
                  className="flex-row items-center  mb-1 "
                  style={{ marginVertical: 2 }}
                >
                  <Font
                    className="text-sm text-app-d1 leading-4"
                    numberOfLines={2}
                  >
                    Tips and trick
                  </Font>
                </View>
                <Font
                  className="text-md text-app-d mb-1 leading-5"
                  weight={500}
                  numberOfLines={2}
                >
                  Ancol Attractions FAQ: Find answers to your questions about
                  Ancol attractions tickets
                </Font>
              </View>
            </Pressable>
          ))}
        </View>
      </ScrollView>
    </View>
  );
}
export function FeaturedProducts(props) {
  const { userToken } = useContext(AuthContext);
  const navigation = useNavigation();
  const getProductDetails = async ({ id }) => {
    navigation.navigate("product", { id });
  };
  return (
    <View className="w-full px-3">
      <ScrollView horizontal>
        <View className="flex-row w-full p-2 pb-4 pt-0 gap-4">
          {props.data.map((e, i) => (
            <TouchableOpacity
              className="relative w-60 h-80 rounded-lg overflow-hidden"
              key={i}
              onPress={() => getProductDetails({ id: e.id })}
            >
              <View className="h-80 w-full">
                <Image
                  source={
                    e.cover_image_url
                      ? { uri: `${DEV_API}/media/${e.cover_image_url}` }
                      : require("../../assets/water.jpg")
                  }
                  className="w-full h-full"
                ></Image>
              </View>
              <View className="h-80 w-full absolute inset-0 bg-gradient-to-t from-gray-900"></View>
              <LinearGradient
                // Background Linear Gradient
                colors={["transparent", "rgba(0,0,0,1)"]}
                className="h-80 w-full absolute inset-0"
              />
              {/* <View className="h-80 w-full absolute inset-0 bg-black opacity-70"></View> */}
              <View className="w-full px-2 py-4 absolute bottom-0 left-0">
                <Font
                  className="text-lg text-app-a"
                  weight={700}
                  numberOfLines={1}
                >
                  {e.title}
                </Font>

                <View
                  className="flex-row items-center"
                  style={{ marginVertical: 2 }}
                >
                  <Font
                    className="text-base text-app-a"
                    weight={700}
                    numberOfLines={1}
                  >
                    {" "}
                    $ {e.sale_price / 100 || e.price / 100}{" "}
                  </Font>
                  <Font
                    className="text-base text-app-e line-through ml-2"
                    weight={700}
                    numberOfLines={1}
                  >
                    {" "}
                    $ {e.price / 100 || e.sale_price / 100}
                  </Font>
                </View>
                {e.product_details.total_reviews > 0 && (
                  <View
                    className="flex-row items-center"
                    style={{ marginVertical: 2 }}
                  >
                    <RemixIcon
                      name="star-fill"
                      size={16}
                      color={colors.app.i}
                      className="mr-1 flex-shrink-0"
                    ></RemixIcon>
                    <Font
                      className="text-base text-app-a"
                      numberOfLines={1}
                      weight={700}
                    >
                      {" "}
                      {e.product_details.average_rating ?? 0} |{" "}
                      {e.product_details.total_reviews ?? 0} reviews
                    </Font>
                  </View>
                )}
              </View>
            </TouchableOpacity>
          ))}
        </View>
      </ScrollView>
    </View>
  );
}
export function FeaturedListing1(props) {
  const { userToken } = useContext(AuthContext);
  const navigation = useNavigation();

  const getBusinessDetails = async ({ id }) => {
    navigation.navigate("listing", { id });
  };
  return (
    <View className="w-full px-3">
      <ScrollView horizontal>
        <View className="p-2 gap-4 flex-row">
          {props.data.map((e, i) => (
            <Pressable
              className="relative flex-1 w-60 h-80 rounded-lg overflow-hidden border border-gray-200 active:bg-app-c1"
              key={`feature_listing_${i}`}
              onPress={() => getBusinessDetails({ id: e.id })}
            >
              <View className="h-80 w-full">
                <View className="w-full h-full bg-app-c1">
                  <Image
                    source={
                      e.cover_image_url
                        ? { uri: `${DEV_API}/media/${e.cover_image_url}` }
                        : require("../../assets/bg.jpg")
                    }
                    defaultSource={require("../../assets/bg.jpg")}
                    className="w-full h-full "
                  ></Image>
                </View>
                <View className="py-1 px-4 w-min items-center rounded-full bg-app-d absolute top-2 left-2 z-10">
                  <Font className="text-app-a text-sm" weight={500}>
                    {e.listing_category.title}
                  </Font>
                </View>
              </View>
              <View className="h-80 w-full absolute inset-0 bg-black opacity-50"></View>
              <View className="w-full py-4 absolute bottom-0 left-0">
                <View className="w-full flex-row pb-4 items-center">
                  <View className="bg-app-e w-1 h-6 rounded-sm"></View>
                  <Font
                    className="w-full text-lg text-app-a px-2"
                    weight={700}
                    numberOfLines={1}
                  >
                    {e.title}
                  </Font>
                </View>
                <View className="w-full flex-row items-center pb-1 px-2">
                  <RemixIcon
                    name="map-pin-fill"
                    color={colors.app.a}
                    size={18}
                    className="mr-2"
                  ></RemixIcon>

                  {e.city !== null && e.state !== null && e.country !== null ? (
                    <Font
                      className="flex-1 text-base text-app-a"
                      weight={700}
                      numberOfLines={1}
                    >
                      {e.city}
                      {`, ${e.state}`}
                      {`, ${e.country}`}
                    </Font>
                  ) : (
                    <Font
                      className="text-base text-app-a"
                      weight={700}
                      numberOfLines={2}
                    >
                      city, state, country
                    </Font>
                  )}
                </View>
                {e.total_reviews > 0 && (
                  <View className="w-full flex-row items-center px-2 pb-1">
                    <RemixIcon
                      name="star-fill"
                      size={16}
                      color={colors.app.i}
                      className="mr-2 flex-shrink-0"
                    ></RemixIcon>
                    <Font
                      className="text-base text-app-a"
                      weight={700}
                      numberOfLines={1}
                    >
                      {" "}
                      {`${e.average_rating} / 5`} ({e.total_reviews}){" "}
                      {`${e.total_reviews === 1 ? "review" : "reviews"}`}
                    </Font>
                  </View>
                )}
                <View className="w-full flex-row justify-start px-2 ">
                  <Font
                    className="text-base text-app-a mx-1"
                    weight={700}
                    numberOfLines={1}
                  >
                    $
                  </Font>
                  <Font
                    className="text-base text-app-a pl-2"
                    weight={700}
                    numberOfLines={1}
                  >
                    {e.min_price ? Number(e.min_price) / 100 : 0}
                    {" -  "}$ {e.max_price ? Number(e.max_price) / 100 : 0}{" "}
                  </Font>
                </View>
              </View>
            </Pressable>
          ))}
        </View>
      </ScrollView>
    </View>
  );
}
export function FeaturedListing(props) {
  const { userToken } = useContext(AuthContext);
  const navigation = useNavigation();

  const getBusinessDetails = async ({ id }) => {
    navigation.navigate("listing", { id });
  };
  return (
    <View className="w-full px-3">
      <ScrollView horizontal>
        <View className="p-2 gap-4 flex-row">
          {props.data.map((e, i) => (
            <Pressable
              className="flex-1 w-60 h-[370px] rounded-lg overflow-hidden border border-gray-200 active:bg-app-c1"
              key={`feature_listing_${i}`}
              onPress={() => getBusinessDetails({ id: e.id })}
            >
              <View className="h-56 w-full">
                <View className="w-full h-full bg-app-c1">
                  <Image
                    source={
                      e.cover_image_url
                        ? { uri: `${DEV_API}/media/${e.cover_image_url}` }
                        : require("../../assets/bg.jpg")
                    }
                    defaultSource={require("../../assets/bg.jpg")}
                    className="w-full h-full "
                  ></Image>
                </View>
                <View className="p-1 px-4 w-min rounded-full bg-app-f items-center justify-center self-start absolute top-2 left-2">
                  <Font className="text-app-a text-xs">
                    {e.listing_category.title}
                  </Font>
                </View>
              </View>
              <View className="py-4">
                <View className="flex-row pb-4 items-center">
                  <View className="bg-app-e w-1 h-6 rounded-sm"></View>
                  <Font
                    className="text-base text-app-d leading-5 pl-2"
                    weight={700}
                    numberOfLines={2}
                  >
                    {e.title}
                  </Font>
                </View>
                <View className="flex-row items-center pb-1 px-2">
                  <RemixIcon
                    name="map-pin-fill"
                    color={colors.app.d1}
                    size={20}
                    className="mr-2"
                  ></RemixIcon>

                  {e.city !== null && e.state !== null && e.country !== null ? (
                    <Font
                      className="text-sm text-app-d1"
                      weight={600}
                      numberOfLines={2}
                    >
                      {e.city + ", " + e.state + ", " + e.country}
                    </Font>
                  ) : (
                    <Font
                      className="text-sm text-app-d1 leading-4"
                      numberOfLines={2}
                      weight={600}
                    >
                      city, state, country
                    </Font>
                  )}
                </View>
                {e.total_reviews > 0 && (
                  <View className="flex-row items-center px-2 pb-1">
                    <RemixIcon
                      name="star-fill"
                      size={16}
                      color={colors.app.i}
                      className="mr-2 flex-shrink-0"
                    ></RemixIcon>
                    <Font
                      className="text-md text-app-d1"
                      numberOfLines={1}
                      weight={600}
                    >
                      {" "}
                      {`${e.average_rating} / 5`} ({e.total_reviews}){" "}
                      {`${e.total_reviews === 1 ? "review" : "reviews"}`}
                    </Font>
                  </View>
                )}
                <View className="flex-row justify-start px-2 ">
                  <Font
                    className="mx-1 text-app-d1"
                    weight={600}
                    numberOfLines={1}
                  >
                    $
                  </Font>
                  <Font
                    className=" text-app-d1 pl-2"
                    weight={600}
                    numberOfLines={1}
                  >
                    {e.min_price ? Number(e.min_price) / 100 : 0}
                    {" -  "}$ {e.max_price ? Number(e.max_price) / 100 : 0}{" "}
                  </Font>
                </View>
              </View>
            </Pressable>
          ))}
        </View>
      </ScrollView>
    </View>
  );
}
export function UserReviews(props) {
  return (
    <ScrollView horizontal className="flex-1 w-full p-2">
      {props.data.map((e, i) => (
        <View className="p-2" key={i}>
          <Pressable
            className="w-56 rounded-lg overflow-hidden border border-gray-200 p-4"
            key={i}
            onPress={() => navigate("listing")}
          >
            <View className="flex-row">
              <Image
                source={require("../../assets/user1.jpg")}
                className="w-10 h-10 rounded-full"
              ></Image>
              <View className="px-2 justify-center">
                <Font weight={500} className="text-app-d">
                  {e.name}
                </Font>
                <Font weight={500} className="text-app-d1 text-xs">
                  {e.createdAt}
                </Font>
              </View>
            </View>
            <View className="flex-row py-2">
              <RemixIcon
                name="star-fill"
                size={18}
                color={colors.app.h}
              ></RemixIcon>
              <RemixIcon
                name="star-fill"
                size={18}
                color={colors.app.h}
              ></RemixIcon>
              <RemixIcon
                name="star-fill"
                size={18}
                color={colors.app.h}
              ></RemixIcon>
              <RemixIcon
                name="star-fill"
                size={18}
                color={colors.app.h}
              ></RemixIcon>
              <RemixIcon
                name="star-fill"
                size={18}
                color={colors.app.d3}
              ></RemixIcon>
            </View>
            <View>
              <Font className="text-app-d">{e.description} </Font>
            </View>
          </Pressable>
        </View>
      ))}
    </ScrollView>
  );
}
